import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService, { PostCreateTicketParams } from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useNavigate } from '@tanstack/react-router';

type ActionType = 'createTicket';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useCreateTicketMutation = (): {
  createTicket: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  createTicketMutation: UseMutationResult<any, unknown, MutationAction, unknown>;
  isUpdatingCreateTicket: Record<string, boolean>;
  setIsUpdatingCreateTicket: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdatingCreateTicket, setIsUpdatingCreateTicket] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const ticketService = TicketService();

    const action: Record<ActionType, (payload: any) => Promise<any>> = {
      createTicket: (params) => ticketService.postCreateTicket(params.payload),
      // addAttachment: ticketService.postNewAttachment
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const mutation = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType, ...newAttachment }) => {
      setIsUpdatingCreateTicket({ ...isUpdatingCreateTicket, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      console.log('Create Ticket data:', data);
      navigate({ to: `/ticket/${data}`, search: { drawer: undefined } });
    },
    onError: (error: any, variables: any) => {
      console.error('Error during mutation:', error);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return {
    createTicket: mutation.mutate,
    createTicketMutation: mutation,
    isUpdatingCreateTicket,
    setIsUpdatingCreateTicket
  };
};

export default useCreateTicketMutation;
