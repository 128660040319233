import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import TicketService from '@/services/ticket.service';
import { AxiosResponse } from 'axios';
import useTicketMutationsTicketListMap from './useTicketMutationsTicketListMap.service';
import useTicketMutationsBaseFields from './useTicketMutationsBaseFields.service';
import useTicketMutationsCustomFields from './useTicketMutationsCustomFields.service';
import useTicketMutationsNotes from './useTicketMutationsNotes.service';
import useTicketMutationsAttachments from './useTicketMutationsAttachments';
import useTicketDelete from './useTicketDelete.service';
import useCreateTicketMutation from './useCreateTicketMutation';
import useAttachmentDelete from './useAttachmentDelete.service';

interface IUseTicket {
  allTickets: UseQueryResult<any>;
  ticketById: UseQueryResult<any>;
  ticketViewers: UseQueryResult<any>;
  ticketTypesByCustomer: UseQueryResult<any>;
  priorities: UseQueryResult<any>;
  duplicateAddress: UseQueryResult<any>;
  customFields: UseQueryResult<any>;
  customFieldGroups: UseQueryResult<any>;
  customers: UseQueryResult<any>;
  baseFields: UseQueryResult<any>;
  statuses: UseQueryResult<any>;
  statusesByCustomer: UseQueryResult<any>;
  // ticketFillerDataById: UseQueryResult<any>;
  // agents: UseQueryResult<any>;
  agentsByCustomer: UseQueryResult<any>;
  attachmentsById: UseQueryResult<any>;
  attachmentTypesByCustomer: UseQueryResult<any>;
  notesById: UseQueryResult<any>;
  ticketHistoryById: UseQueryResult<any>;
  customFieldsByCustomer: UseQueryResult<any>;
  updateTicketListMapTickets: any;
  isUpdatingTicketListMapTickets: any;
  setIsUpdatingTicketListMapTickets: any;
  updateTicketBaseFields: any;
  isUpdatingTicketBaseFields: any;
  setIsUpdatingTicketBaseFields: any;
  updateTicketCustomFields: any;
  isUpdatingTicketCustomFields: any;
  setIsUpdatingTicketCustomFields: any;
  updateTicketNotes: any;
  isUpdatingTicketNotes: any;
  setIsUpdatingTicketNotes: any;
  updateTicketAttachments: any;
  isUpdatingTicketAttachments: any;
  setIsUpdatingTicketAttachments: any;
  updateTicketDelete: any;
  isUpdatingTicketDelete: any;
  setIsUpdatingTicketDelete: any;
  createTicket: any;
  createTicketMutation: any;
  isUpdatingCreateTicket: any;
  setIsUpdatingCreateTicket: any;
  updateAttachmentDelete: any;
  isUpdatingAttachmentDelete: any;
  setIsUpdatingAttachmentDelete: any;
}

const useTicket = (ticketId?: number, customerId?: number, category?: string, address?: string): IUseTicket => {
  const { updateTicketListMapTickets, isUpdatingTicketListMapTickets, setIsUpdatingTicketListMapTickets } = useTicketMutationsTicketListMap();
  const { updateTicketBaseFields, isUpdatingTicketBaseFields, setIsUpdatingTicketBaseFields } = useTicketMutationsBaseFields();
  const { updateTicketCustomFields, isUpdatingTicketCustomFields, setIsUpdatingTicketCustomFields } = useTicketMutationsCustomFields();
  const { updateTicketNotes, isUpdatingTicketNotes, setIsUpdatingTicketNotes } = useTicketMutationsNotes();
  const { updateTicketAttachments, isUpdatingTicketAttachments, setIsUpdatingTicketAttachments } = useTicketMutationsAttachments();
  const { updateTicketDelete, isUpdatingTicketDelete, setIsUpdatingTicketDelete } = useTicketDelete();
  const { createTicket, createTicketMutation, isUpdatingCreateTicket, setIsUpdatingCreateTicket } = useCreateTicketMutation()
  const { updateAttachmentDelete, isUpdatingAttachmentDelete, setIsUpdatingAttachmentDelete } = useAttachmentDelete();


  const ticketService = TicketService();

  const allTickets = useQuery<any>({
    queryKey: ['all-tickets', customerId, category],
    queryFn: async () => {
      if (!customerId || !category) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTickets({
            customerId: customerId,
            customFields: false,
            category: category,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId && !!category,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const ticketById = useQuery<any>({
    queryKey: ['ticket-by-id', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const ticketViewers = useQuery<any>({
    queryKey: ['ticket-viewers', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketViewers({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const ticketTypesByCustomer = useQuery<any>({
    queryKey: ['ticket-types-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketTypesByCustomer({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  const priorities = useQuery<any>({
    queryKey: ['priorities'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketPriorities({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const duplicateAddress = useQuery<any>({
    queryKey: ['duplicate-address-by-id', address, ticketId],
    queryFn: async () => {
      if (!ticketId || !address) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        const encodedAddress = encodeURIComponent(address);
        await ticketService
          .getTicketDuplicateAddress({
            address: encodedAddress,
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: !!ticketId && !!address,
  });

  const customFields = useQuery<any>({
    queryKey: ['custom-fields', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketCustomFieldsById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const customFieldGroups = useQuery<any>({
    queryKey: ['custom-field-groups', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketCustomFieldGroupsById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const customers = useQuery<any>({
    queryKey: ['customers'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketService
          .getCustomers({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const baseFields = useQuery<any>({
    queryKey: ['base-fields'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketService
          .getBaseFields({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const statuses = useQuery<any>({
    queryKey: ['statuses'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketService
          .getStatuses({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const statusesByCustomer = useQuery<any>({
    queryKey: ['statuses-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getStatusesByCustomer({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  // const ticketFillerDataById = useQuery<any>({
  //     queryKey: ['ticket-filler-data-by-id', ticketId],
  //     queryFn: async () => {
  //         if (!ticketId) return new Promise((resolve) => resolve(null));
  //         return new Promise(async (resolve) => {
  //             await ticketService.getTicketFillerDataById({
  //                 ticketId,
  //             }).then((res) => {
  //                 resolve(res?.data);
  //             });
  //         });
  //     },
  //     staleTime: 1000 * 60 * 5, // 5 minutes
  //     refetchInterval: 1000 * 60, // 1 Minute
  //     enabled: !!ticketId,
  //     refetchOnWindowFocus: false,
  // });

  // const agents = useQuery<any>({
  //     queryKey: ['agents'],
  //     queryFn: async () => {
  //         return new Promise(async (resolve) => {
  //             await ticketService.getAgents({
  //                 onSuccess: (res: AxiosResponse) => {
  //                     resolve(res.data);
  //                 }
  //             }).then((res) => {
  //                 resolve(res?.data);
  //             });
  //         });
  //     },
  //     staleTime: 1000 * 60 * 5, // 5 minutes
  //     refetchOnWindowFocus: false,
  // });

  const agentsByCustomer = useQuery<any>({
    queryKey: ['agents-by-customer', customerId, ticketId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getAgentsByCustomer({
            customerId: customerId,
            ticketId: ticketId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  const attachmentsById = useQuery<any>({
    queryKey: ['attachments-by-id', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getAttachmentsById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const attachmentTypesByCustomer = useQuery<any>({
    queryKey: ['attachment-types-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getAttachmentTypesByCustomer({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  const notesById = useQuery<any>({
    queryKey: ['notes-by-id', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getNotesById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const ticketHistoryById = useQuery<any>({
    queryKey: ['ticket-history-by-id', ticketId],
    queryFn: async () => {
      if (!ticketId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketHistoryById({
            ticketId,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const customFieldsByCustomer = useQuery<any>({
    queryKey: ['custom-fields-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getCustomFieldsByCustomer({
            customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60, // 1 Minute
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  return {
    allTickets,
    ticketById,
    ticketViewers,
    ticketTypesByCustomer,
    priorities,
    duplicateAddress,
    customFields,
    customFieldGroups,
    customers,
    baseFields,
    statuses,
    statusesByCustomer,
    // ticketFillerDataById,
    // agents,
    agentsByCustomer,
    attachmentsById,
    attachmentTypesByCustomer,
    notesById,
    ticketHistoryById,
    customFieldsByCustomer,
    updateTicketListMapTickets,
    isUpdatingTicketListMapTickets,
    setIsUpdatingTicketListMapTickets,
    updateTicketBaseFields,
    isUpdatingTicketBaseFields,
    setIsUpdatingTicketBaseFields,
    updateTicketCustomFields,
    isUpdatingTicketCustomFields,
    setIsUpdatingTicketCustomFields,
    updateTicketNotes,
    isUpdatingTicketNotes,
    setIsUpdatingTicketNotes,
    updateTicketAttachments,
    isUpdatingTicketAttachments,
    setIsUpdatingTicketAttachments,
    updateTicketDelete,
    isUpdatingTicketDelete,
    setIsUpdatingTicketDelete,
    createTicket,
    createTicketMutation,
    isUpdatingCreateTicket,
    setIsUpdatingCreateTicket,
    updateAttachmentDelete,
    isUpdatingAttachmentDelete,
    setIsUpdatingAttachmentDelete
  };
};

export default useTicket;
