import { queryClient } from "@/lib/queryClient";

export const WorkflowRuleTableColumns = [
  {
    header: 'ID',
    accessorKey: 'SmartRuleId',
    accessorFn: (row: any) => row.SmartRuleId,
    size: 'auto',
    filterFn: 'contains',
  },
  {
    header: 'Customer',
    accessorKey: 'Customer',
    accessorFn: (row: any) => {
      const customerId = row.Customer
      const customers = queryClient.getQueryData(['customers']) as Array<{ CustomerEntId: string; EntName: string }> | undefined
      return customers?.find((customer: any) => customer.CustomerEntId === customerId)?.EntName ?? 'Unknown'
    },
    Cell: (props: any) => {
      const customerId = props.row.original?.Customer
      const customers = queryClient.getQueryData(['customers']) as Array<{ CustomerEntId: string; EntName: string }> | undefined
      return customers?.find((customer: any) => customer.CustomerEntId === customerId)?.EntName ?? 'Unknown'
    },
    size: 'auto',
    filterFn: 'equalsString',
  },
  {
    header: 'Description',
    accessorKey: 'Description',
    accessorFn: (row: any) => row.Description,
    size: 'auto',
    filterFn: 'contains',
  },
  {
    header: 'Actions',
    accessorKey: 'Actions',
    accessorFn: (row: any) => row.Actions,
    Cell: (props: any) => props.row.original.Actions ? props.row.original.Actions.length : '---',
    size: 'auto',
    filterFn: 'contains',
  },
  {
    header: 'Run Count',
    accessorKey: 'ExecuteCount',
    accessorFn: (row: any) => row?.ExecCount,
    Cell: (props: any) => props.row.original?.ExecCount ? props.row.original?.ExecCount : '---',
    size: 'auto',
    filterFn: 'contains',
  },
  // {
  //   header: 'Last Updated',
  //   accessorKey: 'createdAt',
  //   accessorFn: (row: any) => (row.createdAt !== 0 && row.createdAt !== null ? row.createdAt : row.updatedAt),
  //   Cell: (props: any) =>
  //     unixToDateTime(
  //       props.row.original.updatedAt !== 0 && props.row.original.updatedAt !== null
  //         ? props.row.original.updatedAt
  //         : props.row.original.createdAt,
  //     ),
  //   size: 150,
  // },
];
