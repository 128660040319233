import { Accordion, AccordionSummary, Box, Button, Paper, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { BaseInformationSchema2, defaultValues, schema } from '@/interfaces/schemas/baseInformation.schema';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import TicketDetailMap from '@/components/Organisms/TicketDetailMap.component';
import { AccordionDetails, useMediaQuery, useTheme, Grid } from '@mui/material';
import useTicketTableStore from '../TicketList/useTicketTableStore';
import CustomFields, { CFTypeIdDetail } from './CustomFields.page';
import CustomCard from '@/components/Atoms/Card/Card.component';
import { useNavigate, useParams } from '@tanstack/react-router';
import useTicketList from '@/contexts/stores/ticketList.store';
import useTemplate from '@/hooks/fetches/useTemplate.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useTicket from '@/hooks/fetches/useTicket.service';
import PrintNoticeDialog from './PrintNoticeDialog.page';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseInformation from './BaseInformation.page';
import Permissions from '@/permissions/Permissions';
import LoadingButton from '@mui/lab/LoadingButton';
import TicketHistory from './TicketHistory.page';
import Attachments from './Attachments.page';
import { enqueueSnackbar } from 'notistack';
import Notes from './Notes.page';
import { useState } from 'react';
import dayjs from 'dayjs';

export interface TicketData {
  description?: string;
  type?: { id: number; label: string } | null;
  priority?: { id: number; label: string } | null;
  status?: { StatusId: number; Name: string; Color?: any } | null;
  dueDate?: dayjs.Dayjs | null;
  assignee?: { id: number; label: string } | null;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  clientAddress?: string;
}

interface CustomFieldValue {
  TicketId: number;
  Cid: number;
  TypeId: number;
  V: {
    MyInt?: number;
    SelectedOpt?: string | number;
    Str?: string;
    Checked?: boolean;
    TimeVal?: string | null;
  };
}

interface Result {
  baseFields: {
    TId: number;
    Title?: string;
    TypeId?: number;
    Priority?: string;
    StatusId?: number;
    Assignee?: number;
    DueDate?: string;
    ClientName?: string;
    ClientEmail?: string;
    ClientPhone?: string;
    ClientAddress?: string;
  };
  customFields: CustomFieldValue[];
}

const TicketDetail = () => {
  const navigate = useNavigate();
  const methods = useForm<BaseInformationSchema2>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { isDirty } = methods.formState;
  const { ticketListState, updateTicketList } = useTicketList();
  const { ticketTableState } = useTicketTableStore()
  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  const ticketId2 = Number(ticketId)
  const {
    ticketById,
    ticketViewers,
    allTickets,
    customFields,
    updateTicketDelete,
    statusesByCustomer,
    ticketTypesByCustomer,
    priorities,
    agentsByCustomer
  } = useTicket(
    ticketId2,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters?.category?.label
  );

  const { duplicateAddress, updateTicketBaseFields, updateTicketCustomFields } = useTicket(ticketId2, undefined, undefined, ticketById.data?.Ticket?.ClientAddress); //todo switch this over into its own hook and not the useTicket Hook
  const { mergeTemplatePermission } = useTemplate(); //todo remove this

  // console.log('ticketListState" :', ticketListState);

  const ticketNumber = () => {
    const index = allTickets?.data?.findIndex((ticket: any) => ticket.TicketExt.Ticket.TId === ticketListState?.selectedTicket?.id);
    if (index === undefined) return 0
    if (index !== -1) return allTickets?.data?.length - index;
  }

  const handleBackToTicketList = () => {
    if (isDirty && Object.keys(methods.formState.dirtyFields).length > 0) {
      const confirmLeave = confirm("You have unsaved changes, are you sure you want to leave?");
      if (confirmLeave) navigate({ to: '/', search: { drawer: undefined } });
    } else {
      navigate({ to: '/', search: { drawer: undefined } });
    }
  };

  const prepareTicketData = (data: TicketData): Result => {
    const result: Result = {
      baseFields: {
        TId: ticketId2,
      },
      customFields: []
    };

    Object.keys(data).forEach((key) => {
      const value = data[key as keyof TicketData];

      if (key === 'description') {
        result.baseFields['Title'] = value as string;

      } else if (key === 'type' && (value as { id: number }).id !== undefined) {
        result.baseFields['TypeId'] = (value as { id: number }).id;

      } else if (key === 'priority' && (value as { label: string }).label) {
        result.baseFields['Priority'] = (value as { label: string }).label.toLowerCase();

      } else if (key === 'status' && (value as { StatusId: number }).StatusId !== undefined) {
        result.baseFields['StatusId'] = (value as { StatusId: number }).StatusId;

      } else if (key === 'assignee' && (value as { id: number }).id !== undefined) {
        result.baseFields['Assignee'] = (value as { id: number }).id;

      } else if (key === 'dueDate' && dayjs.isDayjs(value)) {
        result.baseFields['DueDate'] = value.format('YYYY-MM-DDTHH:mm:ss[Z]');

      } else if (key === 'clientName') {
        result.baseFields['ClientName'] = value as string;

      } else if (key === 'clientEmail') {
        result.baseFields['ClientEmail'] = value as string;

      } else if (key === 'clientPhone') {
        result.baseFields['ClientPhone'] = value as string;

      } else if (key === 'clientAddress') {
        result.baseFields['ClientAddress'] = value as string;

      } else {
        const customField = customFields?.data.find(
          (field: any) => field.ValueMeta.C.Meta.Cid.toString() === key
        );

        if (customField) {
          const customFieldObj = {
            TicketId: ticketId2,
            Cid: customField.ValueMeta.C.Meta.Cid,
            TypeId: customField.ValueMeta.C.TypeId,
            V: {}
          };

          if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTInteger') {
            customFieldObj.V = { MyInt: Number(value) };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDropdown') {
            if (value && typeof value !== 'string' && 'label' in value) {
              customFieldObj.V = { SelectedOpt: value.label };
            }

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTString') {
            customFieldObj.V = { Str: value };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTToggle') {
            customFieldObj.V = { Checked: value };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDate') {
            const dateValue = typeof value === 'string' || dayjs.isDayjs(value) ? value : null;

            customFieldObj.V = {
              TimeVal: dateValue ? dayjs(dateValue).format('YYYY-MM-DDTHH:mm') : null
            };
          }

          result.customFields.push(customFieldObj);
        }
      }
    });

    return result;
  };

  const onSubmit = async (data: any) => {
    const methodValues = methods.getValues()

    const dirtyFields = Object.keys(methods.formState.dirtyFields);
    const dirtyValues = dirtyFields.reduce((acc, fieldName) => {
      (acc as { [key: string]: any })[fieldName] = methodValues[fieldName as keyof BaseInformationSchema2];
      return acc;
    }, {} as Partial<BaseInformationSchema2>);

    const preparedData = prepareTicketData(dirtyValues);
    if (dirtyFields.length > 0) {
      try {
        setIsSaving(true);
        const promises = [];

        if (Object.keys(preparedData.baseFields).length > 1) {
          promises.push(
            updateTicketBaseFields({
              actionType: 'updateTicketBaseFields',
              data: preparedData.baseFields,
            })
          );
        }

        if (preparedData.customFields.length > 0) {
          promises.push(
            updateTicketCustomFields({
              actionType: 'updateTicketCustomFields',
              data: preparedData.customFields,
            })
          );
        }

        const results = await Promise.allSettled(promises);

        const hasError = results.some(result => result.status === "rejected");

        if (!hasError) {
          enqueueSnackbar('Ticket has been updated', { variant: 'success' });
          methods.reset(methodValues);
        } else {
          enqueueSnackbar('Some parts of the ticket update failed', { variant: 'warning' });
        }
      } catch (error) {
        enqueueSnackbar('Ticket update failed', { variant: 'error' });
      } finally {
        setIsSaving(false);
      }

    }
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const confirmLeave = confirm("Are you sure you want to delete this ticket permanently?");
      if (confirmLeave) {
        const ticketDelete = await updateTicketDelete({ actionType: 'deleteTicket', ticketid: ticketId2 });
        if (typeof ticketDelete?.data === 'string' && ticketDelete?.data?.includes('deleted ticketID')) {
          navigate({ to: '/', search: { drawer: undefined } });
        }
      }
    } finally {
      setIsDeleting(false);
    }
  }

  const findPreviousAndNextTickets = (
    tickets: number[],
    currentTicket: number
  ): {
    previousId: number | undefined;
    nextId: number | undefined,
    currentIndex: number | undefined,
    totalTickets: number | undefined
  } => {
    const currentIndex = tickets.indexOf(currentTicket);

    // If ticket not found in array
    if (currentIndex === -1) {
      return {
        previousId: undefined,
        nextId: undefined,
        currentIndex: -1,
        totalTickets: tickets.length
      };
    }

    const previousId = currentIndex > 0 ? tickets[currentIndex - 1] : undefined;
    const nextId = currentIndex < tickets.length - 1 ? tickets[currentIndex + 1] : undefined;

    return {
      previousId,
      nextId,
      currentIndex: currentIndex + 1,
      totalTickets: tickets.length
    };
  }

  const { previousId, nextId, currentIndex, totalTickets } = findPreviousAndNextTickets(ticketTableState?.ticketIds, ticketId2);

  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
        <Grid xs={12} sx={{ marginBottom: "16px" }}>
          <CustomCard width="100%" children={
            <Grid container spacing={2}>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" component="h5" sx={{ marginTop: 2 }}>
                  {ticketById?.data?.Ticket?.ClientAddress || ""}
                </Typography>
                <Typography variant="body1" component="p" display="flex" alignItems="center">
                  {currentIndex !== -1 && (
                    <>
                      <Button
                        id="prev-ticket"
                        onClick={() => {
                          navigate({
                            to: `/ticket/${previousId}`,
                            params: { ticketId: previousId },
                          })
                        }}
                        disabled={previousId === undefined}
                      >
                        Previous
                      </Button>
                      <Typography sx={{ padding: '8px' }}>{currentIndex} of {totalTickets ?? 0}</Typography>
                      <Button
                        id="next-ticket"
                        onClick={() => {
                          navigate({
                            to: `/ticket/${nextId}`,
                            params: { ticketId: nextId },
                          })
                        }}
                        disabled={nextId === undefined}
                      >
                        Next
                      </Button>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid xs={12} container justifyContent="space-between" alignItems="center" sx={{ paddingLeft: '8px' }}>
                <Typography variant="body1" component="p">
                  {ticketById.data?.Ticket?.TId && `Ticket: #${ticketById.data?.Ticket?.TId}`} {ticketById.data?.CustomerEntity?.EntName && `- ${ticketById.data?.CustomerEntity?.EntName}`}
                </Typography>
                {!isSmallScreen &&
                  <Typography variant="body1" component="p" align="right" sx={{ paddingRight: '16px' }}>
                    {ticketViewers?.data?.length} Viewing: ({ticketViewers?.data?.map((agent: any) => agent.Name).join(', ')})
                  </Typography>}
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" flexDirection={isSmallScreen ? "column" : "row"} alignItems={isSmallScreen ? "flex-end" : "center"}>
                <Typography variant="body1" component="p">
                  {ticketById.data?.Ticket?.LastUpdated && `Last Updated ${dayjs(ticketById.data?.Ticket?.LastUpdated).format('MM/DD/YY')}`} {ticketById.data?.Ticket?.LastUpdatedByName && `by ${ticketById.data?.Ticket?.LastUpdatedByName}`} {ticketById.data?.Ticket?.CreateTime && `(Created on ${dayjs(ticketById.data?.Ticket?.CreateTime).format('MM/DD/YY')})`}
                </Typography>
                <Typography>
                  <Permissions target={'ticketDetailDelete'}>
                    <LoadingButton
                      size={isSmallScreen ? "small" : "medium"}
                      sx={{
                        marginRight: '8px',
                        minWidth: isSmallScreen ? '58px' : '64px',
                      }}
                      id="delete-ticket"
                      color="error"
                      loading={isDeleting} onClick={onDelete}
                    >
                      {isSmallScreen ? "Delete" : "Delete Ticket"}
                    </LoadingButton>
                  </Permissions>
                  <Permissions target={'Templates'}>
                    <Button
                      size={isSmallScreen ? "small" : "medium"}
                      sx={{
                        marginRight: '8px',
                        minWidth: isSmallScreen ? '58px' : '64px',
                      }}
                      variant='outlined'
                      id="print-ticket"
                      onClick={() => updateTicketList({ ticketPrintOpen: true })}
                    >
                      Print
                    </Button>
                  </Permissions>
                  <Button
                    size={isSmallScreen ? "small" : "medium"}
                    sx={{
                      marginRight: '8px',
                      minWidth: isSmallScreen ? '58px' : '64px',
                    }}
                    variant='outlined'
                    id="map-ticket"
                    onClick={() => updateTicketList({ ticketDetailMapOpen: true })}
                  >
                    {/* //todo Finish the map. I think the data will be best to be pulled from the DOM rather than using state */}
                    Map
                  </Button>
                  <Button
                    size={isSmallScreen ? "small" : "medium"}
                    sx={{
                      marginRight: '8px',
                      minWidth: isSmallScreen ? '58px' : '64px',
                    }}
                    variant='outlined'
                    id="return-home"
                    onClick={handleBackToTicketList}
                  >
                    {isSmallScreen ? "Back" : "Back To Ticket List"}
                  </Button>
                  <Permissions target={'ticketDetailSave'} disableMode>
                    <LoadingButton
                      variant='contained'
                      size={isSmallScreen ? "small" : "medium"}
                      sx={{
                        minWidth: isSmallScreen ? '58px' : '64px',
                      }}
                      id="save-ticket"
                      type='submit'
                      loading={isSaving} onClick={methods.handleSubmit(onSubmit)}
                    >
                      Save
                    </LoadingButton>
                  </Permissions>
                </Typography>
              </Grid>
            </Grid>
          } />
        </Grid>
        {duplicateAddress.data && (
          <Grid
            item
            xs={12}
            width="100%"
            id="duplicate-address-warning"
            style={{
              borderColor: 'gold',
              color: 'black',
              backgroundColor: 'yellow',
              maxHeight: '100px',
              minHeight: '52px',
              overflowY: 'auto',
              borderStyle: 'solid',
              borderRadius: '6px',
              marginBottom: '16px',
            }}
          >
            <Box sx={{ padding: '16px' }}>
              Warning - Tickets with a similar client address:{" "}
              {duplicateAddress?.data?.map((ticket: any, index: number) => (
                <Grid item key={ticket.TicketID} component="span" style={{ display: 'inline' }}>
                  <a href={`${ticket.TicketID}`} style={{ color: 'blue' }}>{ticket.Address}</a>
                  {index < duplicateAddress?.data?.length - 1 ? ', ' : ''}
                </Grid>
              ))}
            </Box>
          </Grid>
        )}
        <Grid
          display={"flex"}
          flexDirection={{
            xs: 'column-reverse',
            md: 'row',
          }}
          maxWidth={"100%"}
        >
          <Grid
            item
            xs={ticketListState.ticketHistoryOpen ? 12 : 12}
            md={ticketListState.ticketHistoryOpen ? 8 : 11.5}
            className="baseInfo"
            display="flex"
            flexDirection="column"
            sx={{
              marginRight: {
                xs: 0,
                md: 4,
              },
              maxHeight: isSmallScreen ? 'calc(100vh - 262px)' : 'calc(100vh - 250px)',
              overflowX: 'auto',
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Accordion
                    id="base-information"
                    onChange={(e, expanded) =>
                      updateTicketList({
                        accordionsOpen: {
                          ...ticketListState.accordionsOpen,
                          baseFields: expanded,
                        },
                      })
                    }
                    expanded={ticketListState.accordionsOpen.baseFields}
                    sx={{
                      marginBottom: 4,
                      marginTop: 0.5,
                      boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                      width: "99%",
                      borderRadius: 1.5,
                      '&.Mui-expanded:first-of-type': { marginTop: 0.5 }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }}>Ticket Detail</AccordionSummary>
                    <AccordionDetails sx={{ padding: 4 }}>
                      <BaseInformation />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    id="custom-fields"
                    onChange={(e, expanded) =>
                      updateTicketList({
                        accordionsOpen: {
                          ...ticketListState.accordionsOpen,
                          customFields: expanded,
                        },
                      })
                    }
                    expanded={ticketListState.accordionsOpen.customFields}
                    sx={{
                      marginBottom: 4,
                      '&.Mui-expanded': { marginTop: '0px', },
                      '&::before': { display: 'none' },
                      boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                      width: "99%",
                      borderRadius: 1.5,
                      '&.Mui-expanded:last-of-type': { marginBottom: 4 }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ paddingX: 4 }}
                    //? totalVisibleCustomFields - 1 to remove the custom field search input
                    >
                      Custom Fields ({ticketListState.totalVisibleCustomFields === 0 ? 0 : ticketListState.totalVisibleCustomFields - 1})
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 4 }}>
                      <CustomFields />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {ticketListState.ticketPrintOpen && <PrintNoticeDialog />}
              </form>
            </FormProvider>
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Accordion
                id="attachments"
                onChange={(e, expanded) =>
                  updateTicketList({
                    accordionsOpen: {
                      ...ticketListState.accordionsOpen,
                      attachments: expanded,
                    },
                  })
                }
                expanded={ticketListState.accordionsOpen.attachments}
                sx={{
                  marginBottom: 4,
                  '&.Mui-expanded': { marginTop: '0px' },
                  boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                  width: "99%",
                  borderRadius: 1.5
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }} >Attachments</AccordionSummary>
                <AccordionDetails sx={{ padding: 4 }}>
                  <Attachments />
                </AccordionDetails>
              </Accordion>
              <Accordion
                id="notes"
                onChange={(e, expanded) =>
                  updateTicketList({
                    accordionsOpen: {
                      ...ticketListState.accordionsOpen,
                      notes: expanded,
                    },
                  })
                }
                expanded={ticketListState.accordionsOpen.notes}
                sx={{
                  marginBottom: 4,
                  '&.Mui-expanded': { marginTop: '0px' },
                  '&::before': { display: 'none' },
                  boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                  width: "99%",
                  borderRadius: 1.5,
                  '&.Mui-expanded:last-of-type': { marginBottom: 0.5 }
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }} >Notes</AccordionSummary>
                <AccordionDetails sx={{ padding: 4 }}>
                  <Notes />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {ticketListState.ticketHistoryOpen &&
            <Grid item xs={12} md={4}
              sx={{
                overflowY: 'auto',
                borderRadius: 1.5,
                boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                marginBottom: {
                  xs: 4,
                  md: 0
                }
              }}
            >
              <Paper>
                <Typography variant="body1" component="p">
                  <TicketHistory />
                </Typography>
              </Paper>
            </Grid>
          }
          {!ticketListState.ticketHistoryOpen &&
            <Grid
              sx={{
                marginBottom: {
                  xs: 4,
                  md: 0
                },
                display: {
                  xs: 'flex',
                  md: 'block'
                },
                justifyContent: 'flex-end'
              }}
            >
              <Button
                id='expand-btn'
                variant='outlined'
                onClick={() => updateTicketList({ ticketHistoryOpen: true })}
              >
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Grid>
          }
        </Grid>
      </Grid >

      {ticketListState.ticketDetailMapOpen && <TicketDetailMap />}
    </>
  )
}

export default TicketDetail