import NotFound from '@/app/pages/NotFound/NotFound.page'
import TicketList from '@/app/pages/TicketList/TicketList.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/')({
  // validateSearch: (search) => {
  //   return z
  //     .object({
  //       category: z
  //         .enum(['Active', 'Closed', 'All', 'Assigned (Gateway)'])
  //         .optional(),
  //       categoryId: z.number().min(0).max(5).optional(),
  //       customerId: z.number().min(0).max(20).optional(), // Based on your steps array length
  //       customerName: z.string().optional(),
  //     })
  //     .parse(search)
  // },
  component: HomeLayout,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      drawer: search.drawer as 'create' | undefined
    }
  },
  notFoundComponent: NotFound,
})

function HomeLayout() {
  return (
    <DashboardLayout>
      <TicketList />
      <Outlet />
    </DashboardLayout>
  )
}
