import * as React from 'react'
import { Outlet, createRootRouteWithContext, Link, ScrollRestoration } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { CircularProgress } from '@mui/material';
import { Suspense, useContext, useEffect, useRef } from 'react';
import ErrorBoundary from '@/components/Organisms/ErrorBoundary/ErrorBoundary.component';
import { QueryClient } from '@tanstack/react-query';
// import NotFound from '@/app/pages/NotFound/NotFound.page';
import { NotFound } from '@/app/pages/NotFound/404Page';
import { setupInterceptors } from '@/services/api.service';
import { AuthContext } from '@/lib/auth';
import { env } from '../config/env';
import { handleLegacyUrl, LegacyUrlMapping } from '@/App';

export interface RouteHandle {
  title?: string; // Displayed in the dashboard layout as a Header
  isNavItem?: boolean;
  name?: string; // Display name for navItems
  hasDividerAfter?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  index?: number;
}

export type IRoutes = {
  auth: AuthContext
  handles?: RouteHandle;
  queryClient: QueryClient
};

interface MyRouterContext {
  sideMenuState: {
    [key: string]: boolean
  }
}

// const NotFoundComponent = () => (
//   <div>
//     <p>This is the notFoundComponent configured on root route</p>
//     <Link to="/">Start Over</Link>
//   </div>
// )

export const Route = createRootRouteWithContext<{ handles: RouteHandle, queryClient: QueryClient, auth: AuthContext, legacyUrlMappings: Record<string, LegacyUrlMapping> }>()({
  beforeLoad: ({ location, context }) => {
    const result = handleLegacyUrl(
      location.pathname,
      location.search as Record<string, string>,
      context
    )
    if (result) throw result
  },
  notFoundComponent: () => <NotFound />,
  // notFoundComponent: NotFound,
  component: () => {

    // const targetOrigin = env.TARGET_APP_URL
    // // const iframeRef = useRef<HTMLIFrameElement>(null)

    // useEffect(() => {
    //   console.log("react outside", env)
    //   console.log('react outside', event)
    //   console.log('react outside', targetOrigin)

    //   const receiveMessage = (event: MessageEvent) => {
    //     console.log("react", env)
    //     console.log('react', event)
    //     console.log('react', targetOrigin)
    //     // Verify origin
    //     if (event.origin !== targetOrigin) return;

    //     console.log('REACT: Received message from OLD:', event.data);

    //     if (event.data.type === 'AUTH_DATA') {
    //       try {
    //         // Store the session data
    //         localStorage.setItem('jwt', event.data.jwt);
    //         localStorage.setItem('public_key', event.data.publicKey);

    //         setupInterceptors()

    //         // Notify first app of success
    //         window.parent.postMessage(
    //           {
    //             type: 'AUTH_COMPLETE',
    //             success: true
    //           },
    //           targetOrigin
    //         );
    //       } catch (error) {
    //         // Notify first app of failure
    //         window.parent.postMessage(
    //           {
    //             type: 'AUTH_COMPLETE',
    //             success: false,
    //             error: 'Failed to store session'
    //           },
    //           targetOrigin
    //         );
    //       }
    //     }
    //   };

    //   window.addEventListener('message', receiveMessage);

    //   // Signal ready to receive auth data
    //   window.parent.postMessage(
    //     {
    //       type: 'READY_FOR_AUTH'
    //     },
    //     targetOrigin
    //   );

    //   return () => {
    //     window.removeEventListener('message', receiveMessage);
    //   };
    // }, []);

    return (
      <Suspense
        fallback={
          <div className="flex size-full items-center justify-center">
            <CircularProgress />
          </div>
        }
      >
        <ErrorBoundary key={""}>
          <ScrollRestoration />
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    )
  },
});
