import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import Status from '@/app/pages/Status/Status.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/status-editor')({
  component: () => <IFramePage url='status-crud.html' />,

})

const StatusLayout = () => {
  return (
    <DashboardLayout title="Status Editor">
      <Status />
      <Outlet />
    </DashboardLayout>
  )
}
