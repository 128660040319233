export interface ICases {
  name: string;
  value: string ;
}

export const casesArray: ICases[] = [
  { name: "Is Equal", value: "isequal" },
  { name: "Is Not Equal", value: "isnotequal" },
  { name: "Greater Than", value: "greaterthan" },
  { name: "Less Than", value: "lessthan" },
  { name: "Is Between", value: "isbetween" },
  { name: "Is Set", value: "isset" },
  { name: "Is Not Set", value: "isnotset" },
  { name: "today", value: "today" },
  { name: "On Or Before", value: "onorbefore" },
  { name: "On Or After", value: "onorafter" },
  { name: "Within The Last", value: "withinthelast" },
  { name: "Within The Next", value: "withinthenext" },
  { name: "Any Value", value: "anyvalue" },
  { name: "Contains", value: "contains" },
];

export const IcfTDropdownCasesArray
  // : IcfTDropdownCases[] 
  = [
    { name: "Is Equal", value: "isequal" },
    { name: "Is Not Equal", value: "isnotequal" },
    { name: "Is Set", value: "isset" },
    { name: "Is Not Set", value: "isnotset" },
  ];

export const IcfTDateCasesArray
  // : IcfTDateCases[] 
  = [
    { name: "Is Equal", value: "isequal" },
    { name: "Is Not Equal", value: "isnotequal" },
    { name: "Is Between", value: "isbetween" },
    { name: "Is Set", value: "isset" },
    { name: "Is Not Set", value: "isnotset" },
    { name: "Today", value: "today" },
    { name: "On Or Before", value: "onorbefore" },
    { name: "On Or After", value: "onorafter" },
    { name: "Within The Last", value: "withinthelast" },
    { name: "Within The Next", value: "withinthenext" },
  ];

export const IcfTStringCasesArray
  // : IcfTStringCases[] 
  = [
    { name: "Is Equal", value: "isequal" },
    { name: "Is Not Equal", value: "isnotequal" },
    { name: "Contains", value: "contains" },
    { name: "Is Set", value: "isset" },
    { name: "Is Not Set", value: "isnotset" },
  ];

export const IcfTIntegerCasesArray
  // : IcfTIntegerCases[] 
  = [
    { name: "Is Equal", value: "isequal" },
    { name: "Is Not Equal", value: "isnotequal" },
    { name: "Greater Than", value: "greaterthan" },
    { name: "Less Than", value: "lessthan" },
    { name: "Is Between", value: "isbetween" },
    { name: "Is Set", value: "isset" },
    { name: "Is Not Set", value: "isnotset" },
  ];

export const IcfTToggleCasesArray
  // : IcfTToggleCases[] 
  = [
    { name: "Is Equal", value: "isequal" },
    { name: "Is Not Equal", value: "isnotequal" },
    { name: "Is Set", value: "isset" },
    { name: "Is Not Set", value: "isnotset" },
  ];

export const turnEqualityValueToNumber = (equalityValue: string) => {
  //sanatize equalityValue to trim and be lowercase
  equalityValue = equalityValue.trim().toLowerCase().replace(/\s+/g, "");
  console.log(equalityValue)
  switch (equalityValue) {
    case "isequal":
      console.log("isEqual");
      return 0;

    case "isnotequal":
      console.log("isNotEqual");
      return 1;

    case "greaterthan":
      console.log("greaterThan");
      return 2;

    case "lessthan":
      console.log("lessThan");
      return 3;

    case "isbetween":
      console.log("isBetween");
      return 4;

    case "isset":
      console.log("isSet");
      return 5;

    case "isnotset":
      console.log("isNotSet");
      return 6;

    case "today":
      console.log("today");
      return 7;

    case "onorbefore":
      console.log("onOrBefore");
      return 8;

    case "onorafter":
      console.log("onOrAfter");
      return 9;

    case "withinthelast":
      console.log("withinTheLast");
      return 10;

    case "withinthenext":
      console.log("withinTheNext");
      return 11;

    case "anyvalue":
      console.log("anyValue");
      return 12;

    case "contains":
      console.log("contains");
      return 13;

    default:
      console.log("default");
      return 0;
  }
}

export const turnNumberToEqualityValue = (numberValue: number): string => {
  const equalityValues = [
    "isEqual",
    "isNotEqual",
    "greaterThan",
    "lessThan",
    "isBetween",
    "isSet",
    "isNotSet",
    "today",
    "onOrBefore",
    "onOrAfter",
    "withinTheLast",
    "withinTheNext",
    "anyValue",
    "contains"
  ];
  console.log(numberValue)
  const newValue: any = equalityValues[numberValue]
  console.log(equalityValues[numberValue - 1])

  // Ensure the number is within the valid range of indices
  if (numberValue >= 0 && numberValue < equalityValues.length) {
    console.log(equalityValues[numberValue - 1]);
    return equalityValues[numberValue - 1];
  } else {
    console.log("default");
    return "isequal"; // Default case if the number is out of range
  }
}


export const turnOldNumberToEqualityValue = (numberValue: number): string => {
  if (numberValue === 0) {
    return "isNotEqual"
  } else if (numberValue === 1) {
    return "isEqual"
  }
  return ''
}

export const turnEqualityValueToDisplayValue = (equalityValue: string) => {
  //sanatize equalityValue to trim and be lowercase
  // equalityValue = equalityValue.trim().toLowerCase().replace(/\s+/g, "");
  console.log(equalityValue)
  switch (equalityValue) {
    case "isequal":
      console.log("isEqual");
      return "Is Equal";

    case "isnotequal":
      console.log("isNotEqual");
      return "Is Not Equal";

    case "greaterthan":
      console.log("greaterThan");
      return "Greater Than";

    case "lessthan":
      console.log("lessThan");
      return "Less Than";

    case "isbetween":
      console.log("isBetween");
      return "Is Between";

    case "isset":
      console.log("isSet");
      return "Is Set";

    case "isnotset":
      console.log("isNotSet");
      return "Is Not Set";

    case "today":
      console.log("today");
      return "Today";

    case "onorbefore":
      console.log("onOrBefore");
      return "On Or Before";

    case "onorafter":
      console.log("onOrAfter");
      return "On Or After";

    case "withinthelast":
      console.log("withinTheLast");
      return "Within The Last";

    case "withinthenext":
      console.log("withinTheNext");
      return "Within The Next";

    case "anyvalue":
      console.log("anyValue");
      return "Any Value";

    case "contains":
      console.log("contains");
      return "Contains";

    // default:
    //   console.log("default");
    //   return 'Is Equal';
  }
}