import React, { ReactNode } from 'react'
import { Drawer, IDrawerProps as DrawerProps, Box, Paper, Typography, Divider } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import { Fab, List, ListItemButton, ListItemText, SwipeableDrawer, Theme, Tooltip, styled, useMediaQuery, Link as MuiLink, LinkProps as MuiLinkProps, SxProps, AppBar, Toolbar, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors'
import { Link as TanStackLink, createLink, useMatches, LinkProps as TanStackLinkProps } from '@tanstack/react-router';
import { Menu, Bell, User } from 'lucide-react';
import Permissions from '@/permissions/Permissions';

export interface IDefaultMenuItem {
    title: string;
    index: number;
    selected?: boolean;
    hrefString?: string;
    hasDividerAfter?: boolean;
    disabled?: boolean;
    defaultModule?: boolean;
    callback?: () => void;
    isCallback: boolean;
    tooltip?: string
}

export interface IDefaultSideMenu extends DrawerProps {
    defaultMenuItems?: IDefaultMenuItem[]
    defaultMenuItemsBottom?: IDefaultMenuItem[]
    navBarWidth?: number
    open?: boolean
    closeCallback?: () => void
    mobileView?: boolean
    openTooltip?: string
    closeTooltip?: string
    drawerWidth?: number
    activeModule?: string
    onActiveModuleClick?: () => void
    selectedIndexOnLoad?: number
}

export const DefaultSideMenu = (props: IDefaultSideMenu) => {
    const { defaultMenuItems, defaultMenuItemsBottom, navBarWidth, closeCallback, drawerWidth, activeModule, onActiveModuleClick, selectedIndexOnLoad, ...rest } = props;

    const [selectedIndex, setSelectedIndex] = React.useState(selectedIndexOnLoad ? selectedIndexOnLoad : 0);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        hrefLink: string
    ) => {
        setSelectedIndex(index);
        handleDrawerToggle && handleDrawerToggle();
        // window.location.href = hrefLink;
    };

    const DefaultMenuItem = (props: IDefaultMenuItem) => {
        const {
            title,
            selected,
            index,
            disabled,
            hrefString,
            callback,
            isCallback,
            tooltip
        } = props;

        const commonLinkContent = (isActive?: boolean) => (
            <MuiLink>
                <ListItemButton
                    selected={isActive ?? selected}
                    disabled={disabled}
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#3e618e',
                            '&:hover': {
                                backgroundColor: '#213a52',
                            },
                        },
                        p: 2,
                    }}
                    id={`side-menu-item-${index}`}
                >
                    <Typography
                        variant="subtitle2"
                        color="white"
                        sx={{
                            paddingLeft: 2,
                        }}
                    >
                        {title}
                    </Typography>
                </ListItemButton>
            </MuiLink>
        );

        const handleItemClick = (event: React.MouseEvent) => {
            event.preventDefault();
            // console.log(`MenuItem ${title} clicked`);
            // console.log(typeof callback);
            // console.log(callback);
            if (isCallback && typeof callback === 'function') {
                // console.log(`Executing callback for ${title}`);
                callback();
            }
        };

        const callbackLinkContent = (isActive?: boolean) => (
            <MuiLink >
                <Tooltip title={tooltip ? tooltip : ''} arrow placement='right'>
                    <ListItemButton
                        selected={isActive ?? selected}
                        disabled={disabled}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: '#3e618e',
                                '&:hover': {
                                    backgroundColor: '#213a52',
                                },
                            },
                            p: 2,
                        }}
                        id={`side-menu-item-${index}`}
                        onClick={handleItemClick}
                    >
                        <Typography
                            variant="subtitle2"
                            color="white"
                            sx={{
                                paddingLeft: 2,
                            }}
                        >
                            {title}
                        </Typography>
                    </ListItemButton>
                </Tooltip>
            </MuiLink>
        );

        // if (!hrefString) return null;

        const isExternalLink = hrefString?.startsWith('http://') || hrefString?.startsWith('https://');

        if (isExternalLink) {
            return (
                <a
                    href={hrefString}
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    {commonLinkContent()}
                </a>
            );
        }
        return (
            <>
                {(isCallback) ? callbackLinkContent() : (

                    <TanStackLink
                        to={`/${hrefString?.startsWith('/') ? hrefString?.slice(1) : hrefString}`}
                        style={{ textDecoration: 'none' }}
                    >
                        {({ isActive }) => commonLinkContent(isActive)}
                    </TanStackLink>
                )}
            </>
        );
    };

    const DefaultMenuDivider = () => {
        return (
            <Box p={4} display="flex" justifyContent="center">
                <Divider
                    color='#939598'
                    light
                    sx={{
                        width: "90%",
                    }}
                />
            </Box>
        )
    }

    const handleDrawerToggle = () => {
        setOpenDrawer(!openDrawer);
    }

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));


    const DrawerComponent = () => {

        const handleOnActiveModuleClick = () => {
            onActiveModuleClick && onActiveModuleClick();
        }

        return (
            <Drawer
                style={{ backgroundColor: "#1b2430 !important", color: "#ffffff" }}
                open={isSmallScreen ? openDrawer : true}
                sx={{
                    width: drawerWidth ? drawerWidth : 260,
                    height: "100%",
                    flexShrink: 0,
                    zIndex: isSmallScreen ? 6 : 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth ? drawerWidth : 260,
                        boxSizing: "border-box",
                        borderRight: "none",
                        zIndex: isSmallScreen ? 6 : 0,
                        overflowY: 'auto',
                    },
                    // display: "flex",
                    // alignContent: 'space-between'
                }}
                variant={isSmallScreen ? undefined : "permanent"}
                anchor="left"
                id="defaultSideMenu"
            >

                <Paper square sx={{
                    height: "100%",
                    width: "100%",
                    marginTop: navBarWidth ? navBarWidth + "px" : "64px",
                    backgroundColor: "#1b2430",
                    display: "flex",
                    alignContent: 'space-between',
                    flexDirection: 'column',
                    // justifyContent: 'space-between',
                    zIndex: 0,
                }}
                >
                    <Paper
                        square sx={{
                            height: "64px",
                            width: "100%",
                            backgroundColor: "#232f3e",
                            zIndex: 0,
                            display: "flex",
                            alignItems: 'center',
                            ms: 2,
                        }}
                        onClick={handleOnActiveModuleClick}
                        id='activeModule'
                    >
                        <Typography
                            variant="h4"
                            color="white"
                            sx={{
                                ml: 4
                            }}
                        >
                            {activeModule ? activeModule : "Module"}
                        </Typography>
                    </Paper>
                    <Box>

                        <List disablePadding sx={{
                            mt: 2,
                            overflow: "auto",
                        }}>
                            {defaultMenuItems && defaultMenuItems.map((item: IDefaultMenuItem, index: number) => (
                                <Permissions target={item.title}>
                                    <>
                                        <DefaultMenuItem
                                            key={`item-${index}`}
                                            index={index}
                                            title={item.title}
                                            disabled={item.disabled}
                                            hrefString={item.hrefString}
                                            isCallback={item.isCallback}
                                            callback={item.callback}
                                            tooltip={item.tooltip}
                                        />
                                        {item.hasDividerAfter && <DefaultMenuDivider key={`divider-${index}`} />}
                                    </>
                                </Permissions>
                            ))}
                        </List>
                    </Box>
                    <Box>

                        <List disablePadding sx={{
                            bottom: 0,
                            mb: 2
                        }}>
                            {defaultMenuItemsBottom && defaultMenuItemsBottom.map((item: IDefaultMenuItem, index: number) => (
                                <>
                                    <DefaultMenuItem
                                        key={`item-${index + 20}`}
                                        index={index + 20}
                                        title={item.title}
                                        disabled={item.disabled}
                                        hrefString={item.hrefString}
                                        isCallback={item.isCallback}
                                        callback={item.callback}
                                    />
                                    {item.hasDividerAfter && <DefaultMenuDivider key={`divider-${index}`} />}
                                </>
                            ))}
                            {/* <DefaultMenuItem index={12} title={"User Profile"} />
                <DefaultMenuItem index={13} title={"Release Notes"} />
                <DefaultMenuItem index={14} title={"Sign Out"} /> */}
                        </List>
                    </Box>

                </Paper>
                <Paper
                    square sx={{
                        height: "64px",
                        width: "100%",
                        backgroundColor: "#232f3e",
                        zIndex: 0,
                    }}
                >

                </Paper>


            </Drawer>
        )
    }

    const Puller = styled('div')(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
        color: '#213a52',
        "& .MuiPaper-root": {
            backgroundColor: "#1b2430",
            color: '#1b2430'
        }

    }));

    return (
        <>
            {isSmallScreen ? (
                <>
                    {/* <AppBar position="fixed" sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}>
                        <Toolbar sx={{ justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <IconButton edge="start" color="inherit">
                                    <Menu size={24} onClick={handleDrawerToggle} />
                                </IconButton>
                                <Typography variant="h6" noWrap component="div">
                                    Dashboard
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <IconButton color="inherit">
                                    <Bell size={24} />
                                </IconButton>
                                <IconButton color="inherit">
                                    <User size={24} />
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar> */}
                    <Tooltip title={!openDrawer ? props.openTooltip ? props.openTooltip : "Open Menu" : props.closeTooltip ? props.closeTooltip : "Close Menu"} arrow>
                        <Fab
                            color="primary"
                            aria-label="toggle drawer"
                            onClick={handleDrawerToggle}
                            sx={{
                                position: 'fixed', // Fixed position
                                bottom: (theme: Theme) => theme.spacing(4), // 16px from the bottom
                                right: (theme: Theme) => theme.spacing(2), // 16px from the left
                                zIndex: (theme: Theme) => theme.zIndex.drawer + 2, // Higher than AppBar and Drawer
                            }}
                        >
                            <MenuIcon />
                        </Fab>
                    </Tooltip>
                    <SwipeableDrawer
                        onClose={handleDrawerToggle}
                        onOpen={handleDrawerToggle}
                        open={isSmallScreen ? openDrawer : true}
                        sx={{
                            width: drawerWidth ? drawerWidth : 260,
                            height: "100%",
                            flexShrink: 0,
                            zIndex: isSmallScreen ? 6 : 0,
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                borderRight: "none",
                                zIndex: isSmallScreen ? 6 : 0,
                                overflowY: 'auto',
                                backgroundColor: "#1b2430 !important",
                            },
                        }}
                        anchor="bottom"
                        id="defaultSideMenu"
                    >
                        <Paper square sx={{
                            height: "100%",
                            minHeight: "200px",
                            width: "100%",
                            marginTop: navBarWidth ? navBarWidth + "px" : "64px",
                            backgroundColor: "#1b2430",
                            display: "flex",
                            alignContent: 'space-between',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            zIndex: 0,
                        }}
                        >
                            <Box>
                                <Puller />
                                <List disablePadding sx={{
                                    mt: 8,
                                    overflow: "auto",
                                }}>
                                    {defaultMenuItems && defaultMenuItems.map((item: IDefaultMenuItem, index: number) => (
                                        <>
                                            <DefaultMenuItem
                                                key={`item-${index}`}
                                                index={index}
                                                title={item.title}
                                                disabled={item.disabled}
                                                hrefString={item.hrefString}
                                                isCallback={item.isCallback}
                                                callback={item.callback}
                                            />
                                            {item.hasDividerAfter && <DefaultMenuDivider key={`divider-${index}`} />}
                                        </>
                                    ))}
                                </List>
                            </Box>
                            <Box>

                                <List disablePadding sx={{
                                    bottom: 0,
                                    mb: 2
                                }}>
                                    {defaultMenuItemsBottom && defaultMenuItemsBottom.map((item: IDefaultMenuItem, index: number) => (
                                        <>
                                            <DefaultMenuItem
                                                key={`item-${index + 20}`}
                                                index={index + 20}
                                                title={item.title}
                                                disabled={item.disabled}
                                                hrefString={item.hrefString}
                                                isCallback={item.isCallback}
                                                callback={item.callback}
                                            />
                                            {item.hasDividerAfter && <DefaultMenuDivider key={`divider-${index}`} />}
                                        </>
                                    ))}
                                </List>
                            </Box>

                        </Paper>
                        <Paper
                            square sx={{
                                height: "70px",
                                width: "100%",
                                backgroundColor: "#232f3e",
                                zIndex: 0,
                            }}
                        >

                        </Paper>


                    </SwipeableDrawer>
                </>
            ) : <DrawerComponent />
            }
        </>

    )
}