import React, { ReactElement } from "react";
import usePermission, { createPermissionsQueryOptions, createRoleIdQueryOptions } from "../hooks/fetches/usePermission.service";
import useTemplate, { createMergeTemplatePermissionsOptions } from "@/hooks/fetches/useTemplate.service";
import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { handleUnauthorizedError } from "@/lib/auth";

interface PermissionsProps {
  target: string;
  children: ReactElement;
  disableMode?: boolean
}

interface CheckResourceAccessProps {
  queryClient: QueryClient
  pathname: string
  permissions: any
}

export const checkResourceAccess = async ({
  queryClient,
  pathname,
  permissions
}: CheckResourceAccessProps): Promise<boolean> => {
  try {
    const [permissions, mergeTemplatePermission, roleId] = await Promise.all([
      queryClient.fetchQuery(createPermissionsQueryOptions()),
      queryClient.fetchQuery(createMergeTemplatePermissionsOptions()),
      queryClient.fetchQuery(createRoleIdQueryOptions()),
    ])

    console.log('permissions')
    console.log(pathname)
    console.log(permissions)
    console.log(mergeTemplatePermission)
    console.log(roleId)

    return (
      // adding data object for same data access as from useQuery
      handleAccess(pathname, { data: permissions }, { data: mergeTemplatePermission }, { data: roleId })
    )
  } catch (error) {
    // handleUnauthorizedError(queryClient)
    console.error('Error checking resource access:', error)
    return false
  }
}

export const handleAccess = (target: string, permissions?: any, mergeTemplatePermission?: any, roleId?: any): boolean => {

  // console.log(permissions)
  // console.log(permissions?.data)

  if (!permissions?.data) return false;

  switch (target) {
    case "ticketDetailSave":
      return permissions?.data["Ticket Editor"];

    case "ticketDetailDelete":
      return permissions?.data["Ticket Deleter"];

    case "createTicket":
      return permissions?.data["Ticket Creator"];

    case "canChangeStatus":
      return (
        permissions?.data["Ticket Editor"] &&
        permissions?.data["Ticket Mover"]
      )

    case "canChangeAssignee":
      return (
        permissions?.data["Ticket Editor"] &&
        permissions?.data["Ticket Assign-other"]
      )

    case "canDeleteAttachment":
      return (
        //Self is managed inside of Attachments.tsx
        roleId?.data === 10 || // Drop Supervisor
        permissions?.data["Customer Administrator"]
      )

    // NavBar below

    case "Tickets":
      return true

    case "/":
      return true

    case "/tickets":
      return true

    case "/ticket":
      return true

    case "Status Editor":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case '/status-editor':
      console.log('here')
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "Type Editor":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "/type-editor":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "Custom Fields":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "/custom-fields":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "Field Groups":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "/field-groups":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "Customers":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Customer Manager"] ||
        permissions?.data["All-seer"]
      )

    case "/customers":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Customer Manager"] ||
        permissions?.data["All-seer"]
      )

    case "Users":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "/agents":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "Roles & Permissions":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "/permissions":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "Workflow Rules":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    case "/workflow-rules":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"]
      )

    // case "Add Workflow Rule":
    //   return (
    //     permissions?.data["System Manager"] ||
    //     permissions?.data["Customer Administrator"]
    //   )

    case "Templates":
      return mergeTemplatePermission?.data?.MergeTemplateAccess

    case "/templates":
      return mergeTemplatePermission?.data?.MergeTemplateAccess

    case "Reports":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Report Executor"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "/reports":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Report Executor"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "Batch Updates":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "/batch-updates":
      return (
        permissions?.data["System Manager"] ||
        permissions?.data["Customer Administrator"] ||
        permissions?.data["Mass Update Manager"]
      )

    case "User Profile":
      return true

    case "/user-profile":
      return true

    case "Release Notes":
      return true

    case "/release-notes":
      return true

    case "Sign Out":
      return true

    default:
      return false;
  }
};

const Permissions = ({ target, children, disableMode = false }: PermissionsProps) => {
  const permissions = useSuspenseQuery(createPermissionsQueryOptions());
  const mergeTemplatePermission = useSuspenseQuery(createMergeTemplatePermissionsOptions());
  const roleIdPermission = useSuspenseQuery(createRoleIdQueryOptions());
  const hasAccess = handleAccess(target, permissions, mergeTemplatePermission, roleIdPermission);

  if (disableMode) {
    return React.cloneElement(children, {
      disabled: !hasAccess || children.props.disabled, // Keep the original `disabled` prop
    });
  }

  return hasAccess ? <>{children}</> : null;
};

export default Permissions;