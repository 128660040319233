import TicketDetail from '@/app/pages/TicketDetail/TicketDetail.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/ticket/$ticketId')({
  component: () => <TicketDetailLayout />,
  params: {
    parse: (params) => ({
      ticketId: z.number().int().parse(Number(params.ticketId)),
    }),
    stringify: ({ ticketId }) => ({ ticketId: `${ticketId}` }),
  },
})

const TicketDetailLayout = () => {
  return (
    <DashboardLayout>
      <TicketDetail />
    </DashboardLayout>
  )
}
