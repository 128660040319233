import * as React from 'react'
import { createFileRoute, useLoaderData } from '@tanstack/react-router'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import useTicket from '@/hooks/fetches/useTicket.service'
import { createUserQueryOptions } from '@/services/queries/pingOptions'
import { queryClient } from '@/lib/queryClient'

export const Route = createFileRoute('/_auth/user-profile')({
  loader: async ({ context: { queryClient } }) => {
    const query = createUserQueryOptions({ customerid: 0, ticketid: 0, limittocustomer: false });
    const agentContext = await queryClient.ensureQueryData(query);
    if (!agentContext?.ContextUser?.AgentId) {
      throw new Error('Agent context not found');
    }
    return { agentContext };
  },
  component: () => {
    const { agentContext } = useLoaderData({ from: '/_auth/user-profile' });
    return <IFramePage url={`user-crud.html?LeId=${agentContext?.ContextUser?.AgentId}`} />
  },
})
