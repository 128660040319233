import BatchUpdates from '@/app/pages/BatchUpdates/BatchUpdates.page'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/batch-updates')({
  component: () => <IFramePage url='mass-update.html' />,
})

const BatchUpdatesLayout = () => {
  return (
    <DashboardLayout title="Batch Updates Editor">
      <BatchUpdates />
    </DashboardLayout>
  )
}
