import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';
import useTicketList from '@/contexts/stores/ticketList.store';
import useTicket from './useTicket.service';

type ActionType = 'addTicket' | 'updateTicketBaseFields' | 'deleteTicket';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useTicketMutationsBaseFields = (): {
  updateTicketBaseFields: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingTicketBaseFields: Record<string, boolean>;
  setIsUpdatingTicketBaseFields: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const { ticketListState, updateTicketList } = useTicketList();
  // const { allTickets } = useTicket(undefined, ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label);
  const [isUpdatingTicketBaseFields, setIsUpdatingTicketBaseFields] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const ticketService = TicketService();
    const action: { [key in ActionType]?: Function } = {
      updateTicketBaseFields: ticketService.postTicketBaseFields,
      // deleteTicket: ticketService.deleteTicket
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateTicketBaseFields } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdatingTicketBaseFields({ ...isUpdatingTicketBaseFields, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      console.log('base fields onSuccess:', data, variables);
      // console.log('Mutation successful');
      console.log('Base Field variables.data:', variables.data);
      // queryClient.invalidateQueries({ queryKey: ['all-tickets'] });
      // if (Object.keys(variables.data).length > 2 && variables.actionType === 'updateTicketBaseFields') {
      //   console.log('successful update');
      //   enqueueSnackbar('Base Fields saved successfully', { variant: 'success' });
      // } else if (variables.data.TId && variables.actionType === 'updateTicketBaseFields') {
      //   // console.log('successful update');
      //   enqueueSnackbar('Base Field saved successfully', { variant: 'success' });
      // }
      // if (variables.actionType === 'deleteTicket' && data.ticketId) {
      //     queryClient.removeQueries({ queryKey: ['ticket', data.ticketId] })
      //     enqueueSnackbar(`Ticket #${data.ticketId} deleted successfully`, { variant: 'success' })
      //     queryClient.invalidateQueries({ queryKey: ['archived-tickets'], exact: true });
      // }
    },
    onError: (error: any, variables: any) => {
      // console.error('Error during mutation:', error);
      // console.warn('ERROR', variables);
      // if (Object.keys(variables.data).length > 2 && variables.actionType === 'updateTicketBaseFields') {
      //   enqueueSnackbar('Base Field updates failed', { variant: 'error' });
      // } else if (variables.data.TId && variables.actionType === 'updateTicketBaseFields') {
      //   enqueueSnackbar('Base Field update failed', { variant: 'error' });
      // }

      if (variables.actionType === 'deleteTicket' && variables.data.TId) {
        enqueueSnackbar(`Ticket #${variables.data[0].TicketID} delete failed`, {
          variant: 'error',
        });
      }
    },
    // onSettled: ({ actionType }) => {
    //   setIsUpdating({ ...isUpdating, [actionType]: false });
    // },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return { updateTicketBaseFields, isUpdatingTicketBaseFields, setIsUpdatingTicketBaseFields };
};

export default useTicketMutationsBaseFields;
