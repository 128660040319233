import { set, get, del } from 'idb-keyval';

const useIndexedDB = () => {
  const IndexedDBAdapter = {
    async getItem(key: string) {
      try {
        const value = await get(key);
        return value ? value : null;
      } catch (error) {
        console.error('IndexedDB getItem error:', error);
        throw error;
      }
    },
    async setItem(key: string, value: any) {
      try {
        await set(key, value);
      } catch (error) {
        console.error('IndexedDB setItem error:', error);
        throw error;
      }
    },
    async removeItem(key: string) {
      try {
        await del(key);
      } catch (error) {
        console.error('IndexedDB removeItem error:', error);
        throw error;
      }
    },
  };

  return IndexedDBAdapter;
};

export default useIndexedDB;
