import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import Reports from '@/app/pages/Reports/Reports.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/reports')({
  component: () => <IFramePage url='reports2.html' />,
})

const ReportsLayout = () => {
  return (
    <DashboardLayout title="Reports">
      <Reports />
    </DashboardLayout>
  )
}
