import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'

export const Route = createFileRoute('/_auth/permissions')({
  component: () => <IFramePage url='roles-crud.html' />,
})

function RouteComponent() {
  return 'Hello /_auth/permissions!'
}

// const PermissionsLayout = () => {
//   return (
//     <DashboardLayout title="Roles & Permissions Editor">
//       <Permissions />
//     </DashboardLayout>
//   )
// }
