import {
  type MutationFunction,
  QueryClient,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useRouter, useRouterState } from '@tanstack/react-router'
import React, { useState } from 'react'
import { queryClient } from './queryClient'

export interface ReactQueryAuthConfig<User, Permissions, LoginCredentials, RegisterCredentials> {
  userFn: QueryFunction<User, QueryKey>
  usePermissionFn: QueryFunction<Permissions, QueryKey>
  loginFn: MutationFunction<User, LoginCredentials>
  registerFn: MutationFunction<User, RegisterCredentials>
  logoutFn: MutationFunction<unknown, unknown>
  userKey?: QueryKey
  permissionKey?: QueryKey
  queryClient: QueryClient
}

export interface AuthProviderProps {
  children: React.ReactNode
}

export function configureAuth<User, Error, Permissions, LoginCredentials, RegisterCredentials>(
  config: ReactQueryAuthConfig<User, Permissions, LoginCredentials, RegisterCredentials>
) {
  let isAuthenticated = !!localStorage.getItem('jwt')
  const { userFn, userKey = ['authenticated-user'], usePermissionFn, permissionKey = ['user-permissions'], loginFn, registerFn, logoutFn } = config

  const useUser = (options?: Omit<UseQueryOptions<User, Error, User, QueryKey>, 'queryKey' | 'queryFn'>) =>
    useQuery({
      queryKey: userKey,
      queryFn: userFn,
      ...options,
    })

  const usePermission = (options?: Omit<UseQueryOptions<Permissions, Error, Permissions, QueryKey>, 'queryKey' | 'queryFn'>) =>
    useQuery({
      queryKey: permissionKey,
      queryFn: usePermissionFn,
      ...options,
    })

  const useLogin = (options?: Omit<UseMutationOptions<User, Error, LoginCredentials>, 'mutationFn'>) => {
    // const queryClient = useQueryClient()
    const setUser = React.useCallback((data: User) => queryClient.setQueryData(userKey, data), [queryClient])
    isAuthenticated = true
    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user)
        options?.onSuccess?.(user, ...rest)
      },
    })
  }

  const useRegister = (options?: Omit<UseMutationOptions<User, Error, RegisterCredentials>, 'mutationFn'>) => {
    // const queryClient = useQueryClient()
    const setUser = React.useCallback((data: User) => queryClient.setQueryData(userKey, data), [queryClient])
    isAuthenticated = true

    return useMutation({
      mutationFn: registerFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user)
        options?.onSuccess?.(user, ...rest)
      },
    })
  }


  const useLogout = (options?: UseMutationOptions<unknown, Error, unknown>) => {
    // const queryClient = useQueryClient()
    const setUser = React.useCallback((data: User | null) => queryClient.setQueryData(userKey, data), [queryClient])
    isAuthenticated = false

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null)
        options?.onSuccess?.(...args)
      },
    })
  }

  function AuthLoader({
    children,
    renderLoading,
    renderUnauthenticated,
    renderNoPermission,
    renderSafeFallback,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: {
    children: React.ReactNode
    renderLoading: () => any
    renderUnauthenticated: any
    renderNoPermission?: () => any
    renderSafeFallback?: () => any
    renderError?: (error: Error) => any
  }) {
    // const user = useUser()
    // const logout = useLogout()

    // if (user?.status === 'error' && renderUnauthenticated) {
    //   console.log(user?.status)
    //   console.log('error mutate')
    //   // logout.mutate({})
    //   return <>{children}</>
    // }

    return <>{children}</>

    // const permission = usePermission()



    //! USER USE CASE
    // if (user.isSuccess) {
    //   if (renderUnauthenticated && !user.data) {
    //     return renderUnauthenticated()
    //   }
    //   return <>{children}</>
    // }

    // if (!user.isFetched) {
    //   return renderLoading()
    // }



    //! PERMISSION USE CASE
    // if (permission.isSuccess) {
    //   if (renderNoPermission && renderSafeFallback && !permission.data) {
    //     return renderNoPermission() && renderSafeFallback()
    //   }
    //   return <>{children}</>
    // }

    // if (!permission.isFetched) {
    //   return renderLoading()
    // }

    // if (permission.status === 'error') {
    //   return renderError(permission.error)
    // }

    // return renderUnauthenticated()
  }

  return {
    useUser,
    useLogin,
    useRegister,
    useLogout,
    usePermission,
    AuthLoader,
  }
}