import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { Theme, useMediaQuery, Drawer } from '@mui/material';
import { Box, Button, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import SimpleTable from '@/components/Molecules/Table/SimpleTable.component';
import useSampleTable from '@/hooks/tables/sampleTable/useSampleTable';
import { useNavigate } from '@tanstack/react-router';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  useOnSelectionChange,
  Panel,
  ConnectionLineType,
  useReactFlow,
  Edge,
  Node,
  Connection,
  addEdge
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import TriggerNode from '@/components/Organisms/NodeFlow/nodes/TriggerNode';
import { Plus } from 'lucide-react';
import dagre from '@dagrejs/dagre';
import { s } from '@kubb/plugin-oas/dist/SchemaMapper-BpjlzuXc';


const initialNodes: Node[] = [
  { id: '1', type: 'trigger', data: { label: 'Node 1' }, position: { x: 100, y: 100 } },
  { id: '2', type: 'source', data: { label: 'Node 2' }, position: { x: 200, y: 200 } },
  { id: '3', type: 'source', data: { label: 'Node 3' }, position: { x: 200, y: 300 } },
  { id: '4', type: 'source', data: { label: 'Node 4' }, position: { x: 100, y: 400 } },
  { id: '5', type: 'source', data: { label: 'Node 5' }, position: { x: 100, y: 500 } },
];

const nodeWidth = 172;
const nodeHeight = 36;

const nodeTypes = {
  trigger: TriggerNode,
}

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const initialEdges: Edge[] = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e1-3', source: '1', target: '3' },
  { id: 'e3-4', source: '3', target: '4', style: { stroke: 'red' } },
  { id: 'e4-5', source: '4', target: '5' },
];
const defaultViewport = { x: 400, y: 200, zoom: 1 };

const WorkflowBuilder = () => {

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  // const tableData = [
  //   {
  //     id: 1,
  //     name: 'WorkflowBuilder Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  //   {
  //     id: 2,
  //     name: 'WorkflowRules Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  //   {
  //     id: 3,
  //     name: 'WorkflowRules Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  //   {
  //     id: 4,
  //     name: 'WorkflowRules Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  //   {
  //     id: 5,
  //     name: 'WorkflowRules Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  //   {
  //     id: 6,
  //     name: 'WorkflowRules Name',
  //     value: 'WorkflowRules Value',
  //     description: 'WorkflowRules Description',
  //     createdAt: 17005683,
  //     updatedAt: 17005683,
  //   },
  // ];

  // const { sampleTable } = useSampleTable({
  //   data: tableData,
  //   loading: false,
  //   refreshQueryKey: [],
  // });

  const getLayoutedElements = (nodes: any, edges: any, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node: any) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge: any) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes.map((node: any) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const newNode = {
        ...node,
        targetPosition: isHorizontal ? 'left' : 'top',
        sourcePosition: isHorizontal ? 'right' : 'bottom',
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        },
      };

      return newNode;
    });

    return { nodes: newNodes, edges };
  };


  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges,
  );

  const reactFlowInstance = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const [nodeName, setNodeName] = useState('Node 1');
  const [nodeBg, setNodeBg] = useState('#eee');
  const [nodeHidden, setNodeHidden] = useState(false);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);

  // the passed handler has to be memoized, otherwise the hook will not work correctly
  const onChange = useCallback(({ nodes, edges }: any) => {
    setSelectedNodes(nodes.map((node: any) => node.id));
    setSelectedEdges(edges.map((edge: any) => edge.id));
  }, []);

  const onConnect = useCallback((connection: Connection) => {
    const edge = { ...connection, id: `${edges.length} + 1` }
    setEdges((prevEdges) => addEdge(edge, prevEdges))
  }, []);

  useOnSelectionChange({
    onChange,
  });

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === '1') {
          // it's important that you create a new node object
          // in order to notify react flow about the change
          return {
            ...node,
            data: {
              ...node.data,
              label: nodeName,
            },
          };
        }

        return node;
      }),
    );
  }, [nodeName, setNodes]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === '1') {
          // it's important that you create a new node object
          // in order to notify react flow about the change
          return {
            ...node,
            style: {
              // ...node.style,
              backgroundColor: nodeBg,
            },
          };
        }

        return node;
      }),
    );
  }, [nodeBg, setNodes]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === '1') {
          // it's important that you create a new node object
          // in order to notify react flow about the change
          return {
            ...node,
            hidden: nodeHidden,
          };
        }

        return node;
      }),
    );
    setEdges((eds) =>
      eds.map((edge) => {
        if (edge.id === 'e1-2') {
          return {
            ...edge,
            hidden: nodeHidden,
          };
        }

        return edge;
      }),
    );
  }, [nodeHidden, setNodes, setEdges]);

  const onLayout = useCallback(
    (direction: any) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges],
  );

  useEffect(() => {
    if (selectedNodes) {
      console.log('Selected Nodes:', selectedNodes);
      const node = reactFlowInstance.getNode(selectedNodes[0]);
      console.log(node)
      // You can access other properties as needed
    }
  }, [selectedNodes]);

  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      rowGap={4}
      mt={8}
      sx={{ height: '100%', width: '100%' }}
    >
      <Grid xs={12} md={12} width={'100%'} marginTop={!isDesktop ? 2 : 0} height={'100%'}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" marginBottom={4}>
            Manage Workflow Rules
          </Typography>
          <Button
            id="createWorkflowRules"
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginBottom: 4 }}
            onMouseDown={() => {
              console.log('open drawer');
              navigate({
                to: '/workflow-rules/$workflowruleId',
                params: { workflowruleId: 0 },
                search: {
                  variant: 'new',
                  step: 0
                }
              })
              // setVariant('create');
              // setCustomerId(0)
              // openDrawer();
            }}
          >
            + New Workflow Rule
          </Button>
        </div>
        {/* {<SimpleTable table={sampleTable} />} */}
        <div style={{ width: '75vw', height: '75vh' }}>

          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            defaultViewport={defaultViewport}
            minZoom={0.2}
            maxZoom={4}
            attributionPosition="bottom-left"
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            fitViewOptions={{ padding: 0.5, duration: 1000, minZoom: 0.5, maxZoom: 1.5 }}
          >
            {/* <div className="updatenode__controls">
              <label>label:</label>
              <input
                value={nodeName}
                onChange={(evt) => setNodeName(evt.target.value)}
              />

              <label className="updatenode__bglabel">background:</label>
              <input value={nodeBg} onChange={(evt) => setNodeBg(evt.target.value)} />

              <div className="updatenode__checkboxwrapper">
                <label>hidden:</label>
                <input
                  type="checkbox"
                  checked={nodeHidden}
                  onChange={(evt) => setNodeHidden(evt.target.checked)}
                />
              </div>
            </div> */}
            {selectedNodes && (
              <div>
                <h3>Selected Node Information</h3>
                <p>ID: {selectedNodes[0]}</p>
                {/* <p>Label: {selectedNodes.data.label}</p> */}
                {/* <p>Position: x={selectedNodes.position.x}, y={selectedNodes.position.y}</p> */}
              </div>
            )}
            <Panel position="top-right" className="bg-white p-4 rounded-lg shadow-lg">
              <div className="space-y-2">
                <Button
                  variant="contained"
                  startIcon={<Plus size={18} />}
                  onClick={() => onLayout('TB')}
                  className="w-full" id={''}              >
                  Vertical Layout
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Plus size={18} />}
                  // onClick={addConditionNode}
                  onClick={() => onLayout('LR')}
                  className="w-full" id={''}              >
                  Horizontal Layout
                </Button>
              </div>
            </Panel>
            <MiniMap />
            <Controls />
          </ReactFlow>
        </div>
      </Grid>
    </Grid>

  );
};

export default WorkflowBuilder;
