import { Typography, Box, TextField, useTheme, useMediaQuery, IconButton, CircularProgress } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Button, Tooltip } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import useTicketList from '@/contexts/stores/ticketList.store';
import { timezoneOffset } from '@/util/timezoneOffset.util';
import useTicket from '@/hooks/fetches/useTicket.service';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from '@tanstack/react-router';

const TicketHistory = () => {
  const { ticketListState, updateTicketList } = useTicketList();
  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const ticketId2 = Number(ticketId)
  const { ticketHistoryById } = useTicket(ticketId2);
  const [searchInput, setSearchInput] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isHistoryAscending, setisHistoryAscending] = useState(false);
  dayjs.extend(utc);

  const buildHistoryItems = (tickets: any) => {
    return tickets.map((ticket: any, index: number) => {
      switch (ticket.FieldName) {
        case "EMAIL":
          return (
            <Typography key={index} variant="body2" color="textSecondary" sx={{ marginBottom: 0.5 }}>
              <strong>{ticket.FieldName}</strong> was queued to {ticket.Value}{' '}
              <Typography component="span" variant="caption" color="textSecondary">
                {dayjs(ticket.UpdateTime).format("hh:mm:ss A")} {ticket.TimeZone}
              </Typography>
            </Typography>
          )
        case "ATTACHMENT":
          return (
            <Typography key={index} variant="body2" color="textSecondary" sx={{ marginBottom: 0.5 }}>
              <strong>{ticket.FieldName}</strong> was added{' '}
              {/* <em>"{ticket.Value}"</em>{' '} */}
              <Typography component="span" variant="caption" color="textSecondary">
                {dayjs(ticket.UpdateTime).format("hh:mm:ss A")} {ticket.TimeZone}
              </Typography>
            </Typography>
          )
        default:
          return (
            <Typography key={index} variant="body2" color="textSecondary" sx={{ marginBottom: 0.5 }}>
              <strong>{ticket.FieldName}</strong> was set to {ticket.Value}{' '}
              <Typography component="span" variant="caption" color="textSecondary">
                {dayjs(ticket.UpdateTime).format("hh:mm:ss A")} {ticket.TimeZone}
              </Typography>
            </Typography>
          )
      }
    })
  }

  const buildTicketHistory = () => {
    const sorted = ticketHistoryById?.data?.TicketHistories?.slice().sort((a: any, b: any) => {
      return isHistoryAscending
        ? a.Timestamp - b.Timestamp
        : b.Timestamp - a.Timestamp;
    });

    const matchesSearch = (history: any) => {
      const searchLower = searchInput.toLowerCase();

      if (history.Name.toLowerCase().includes(searchLower)) return true;

      return history.Tickets.some((ticket: any) => {
        return (
          ticket.FieldName.toLowerCase().includes(searchLower) ||
          ticket.Value.toLowerCase().includes(searchLower)
        );
      });
    };

    const newSorted = sorted.filter(matchesSearch);

    return newSorted.map((history: any, index: number) => {
      const offsetInMinutes = timezoneOffset(history.TimeZoneOffset);
      timezoneOffset
      const localizedTime = dayjs.utc(history.Date).utcOffset(offsetInMinutes);
      return (
        <Box
          key={index}
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: 1.5,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
            padding: 4,
            marginBottom: 4
          }}
        >
          <Typography variant="subtitle2" fontWeight="bold" sx={{ color: '#555' }}>
            {dayjs(history.Date).format("MM/DD/YYYY")} by{' '}
            <Typography component="span" variant="subtitle2" fontWeight="bold" sx={{ color: '#3f51b5' }}>
              {history.Name}
            </Typography>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {localizedTime.format("hh:mm:ss A")} {history.TimeZone}
          </Typography>
          <Box sx={{ marginTop: 1, paddingLeft: 1, borderLeft: '3px solid #ddd' }}>
            {buildHistoryItems(history.Tickets)}
          </Box>
        </Box>
      )
    });
  };

  const handleTicketHistoryCollapse = () => {
    updateTicketList({ ticketHistoryOpen: false })
  }

  return (
    <Box
      sx={{
        padding: 4,
        height: isSmallScreen ? 'calc(100vh - 262px)' : 'calc(100vh - 250px)',
        maxHeight: '100%',
        overflowY: 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Box display="flex" alignItems="center" mb={4} justifyContent="space-between">
        <Typography variant="h6">Ticket History</Typography>
        <Button id="collapse-btn" variant='outlined' onClick={handleTicketHistoryCollapse}><KeyboardDoubleArrowRightIcon />Collapse</Button>
      </Box>
      {ticketHistoryById.isLoading &&
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      }
      {ticketHistoryById?.data && (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <Tooltip title="Sort" placement='right'>
              <IconButton sx={{ marginRight: 4 }}>
                <SwapVertIcon onClick={() => setisHistoryAscending(!isHistoryAscending)} />
              </IconButton>
            </Tooltip>
            <TextField
              label="Search"
              size="small"
              sx={{ width: "100%" }}
              onChange={(event) => { setSearchInput(event.target.value) }}
            />
          </Box>
          <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 2 }}>
            Ticket has been active for {ticketHistoryById?.data.TimeActive}
          </Typography>
        </>
      )}
      {ticketHistoryById?.data && buildTicketHistory()}
    </Box>
  );
}

export default TicketHistory