// Helper functions for Blob conversion
async function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return await blob.arrayBuffer();
}

function arrayBufferToBlob(buffer: ArrayBuffer, type: string): Blob {
  return new Blob([buffer], { type: type });
}

export const customSerializer = {
  serialize: async (data: any): Promise<string> => {
    const serialized = JSON.stringify(data, async (key, value) => {
      if (value instanceof Blob) {
        const buffer = await blobToArrayBuffer(value);
        return {
          __type: 'Blob',
          data: Array.from(new Uint8Array(buffer)),
          type: value.type,
        };
      }
      if (value instanceof ArrayBuffer) {
        return {
          __type: 'ArrayBuffer',
          data: Array.from(new Uint8Array(value)),
        };
      }
      return value;
    });
    return serialized;
  },
  deserialize: (data: string): any => {
    return JSON.parse(data, (key, value) => {
      if (value && value.__type === 'Blob') {
        const buffer = new Uint8Array(value.data).buffer;
        return arrayBufferToBlob(buffer, value.type);
      }
      if (value && value.__type === 'ArrayBuffer') {
        return new Uint8Array(value.data).buffer;
      }
      return value;
    });
  },
};