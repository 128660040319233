import { BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';


const PermissionService = () => {

  const getPermissions = async ({
    onSuccess,
    onError,
  }: {
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/permissions',
      onSuccess,
      onError,
    });
  };

  const getRoleId = async ({
    onSuccess,
    onError,
  }: {
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/roleid',
      onSuccess,
      onError,
    });
  };

  return {
    getPermissions,
    getRoleId
  };
};

export default PermissionService;
