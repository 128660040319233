import FieldGroups from '@/app/pages/FieldGroups/FieldGroups.page'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/field-groups')({
  component: () => <IFramePage url='field-group-admin.html' />,
})

const FieldGroupsLayout = () => {
  return (
    <DashboardLayout title="Field Groups Editor">
      <FieldGroups />
    </DashboardLayout>
  )
}
