import { z } from 'zod';
import dayjs from 'dayjs';

export const schema = z.object({
  description: z
    .string()
    .optional()
    .refine((value) => !value || value.trim().length > 0, {
      message: 'Description is required',
    }),

  type: z.preprocess(
    (val) => (val === null ? undefined : val),
    z
      .object({
        id: z.number().min(0, { message: 'Type is Required' }),
        label: z.string().min(1, { message: ' Type is Required' }),
      })
      .nullable(),
  ),

  // type: z
  // .number()
  // .positive({ message: "Type must be a positive number" }),
  // // .optional(), // Since you might allow optional selection

  priority: z.preprocess(
    (val) => (val === null ? undefined : val),
    z
      .object({
        id: z.number().min(0, { message: 'Required' }),
        label: z.string().min(1, { message: 'Required' }),
      })
      .nullable(),
  ),

  status: z.preprocess(
    (val) => (val === null ? undefined : val),
    z
      .object({
        StatusId: z.number().min(0, { message: 'Required' }),
        Name: z.string().min(1, { message: 'Required' }),
        Color: z.any()
      })
      .nullable(),
  ),

  // dueDate: z.date().nullable().optional(),
  // dueDate: z.object({

  // }),

  // const formSchema = z.object({  dueDate: dayjsSchema    .refine((date) => date.isAfter(dayjs()), {      message: 'Due date must be in the future',    })    .nullable(),});
  dueDate: z.custom<dayjs.Dayjs>((val) => dayjs.isDayjs(val), { message: 'Invalid date', }).nullable(),
  // .preprocess((value) => {
  //   // Ensure that value is not undefined or empty and is a valid date format
  //   return value && (typeof value === 'string' || value instanceof Date || typeof value === 'number')
  //     ? dayjs(value).toDate() // Convert to Date object for zod date validation
  //     : undefined;
  // }, z.date().nullable().optional())
  // .refine((date) => date === undefined || dayjs().isBefore(dayjs(date)), {
  //   message: 'Due date must be in the future',
  // }),

  assignee: z
    .object({
      id: z.number().min(1, { message: 'Assignee is required' }), // Require `id` > 0
      label: z.string().min(1, { message: 'Assignee is required' }), // Require a valid label
    })
    .nullable() // Allow `null` when no value is selected
    .optional(), // Allow field to be omitted if unselected




  clientName: z
    .string()
    .optional()
    .refine((value) => !value || value.length > 0, {
      message: 'Client name is required',
    }),

  clientEmail: z
    .string()
    .optional()
    .refine((value) => !value || value.trim().length > 0, {
      message: 'Client email is required',
    })
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'Invalid email address',
    }),


  clientPhone: z.string().optional(),

  clientAddress: z
    .string()
    .optional()
    .refine((value) => !value || value.length > 0, {
      message: 'Client address is required',
    }),
});

export type BaseInformationSchema = {
  description: string;
  type: number | null;
  priority: number | null;
  status: number | null;
  dueDate: Date | null;
  assignee: { AgentId: number; Name: string } | null;
  clientName: string;
  clientEmail: string;
  clientPhone: string | null;
  clientAddress: string;
};

export type BaseInformationSchema2 = z.infer<typeof schema>;

export const defaultValues: BaseInformationSchema2 = {
  description: '',
  type: null,
  priority: null,
  status: null,
  dueDate: null,
  assignee: null,
  clientName: '',
  clientEmail: '',
  clientPhone: '',
  clientAddress: '',
};
