import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Container, Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { SmartStepper } from '../WorkflowRules/Stepper';
import WorkflowRuleStep1 from './WorkflowRuleStep1';
import WorkflowRuleStep2 from './WorkflowRuleStep2';
import WorkflowRuleStep3 from './WorkflowRuleStep3';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';


export interface Condition {
  condition: string;
  equality: string;
  equalityNumber: number;
  value: string | number | boolean;
  conditionObj: Record<string, any>;
  type: 'baseField' | 'customField';
  allAny: string;
}


export interface ActionConfig {
  type: string;
  enabled: boolean;
  config: {
    assignee?: string;
    emailTo?: string[];
    emailSubject?: string;
    emailBody?: string;
    status?: string;
    taskTitle?: string;
    taskDescription?: string;
    notificationType?: string;
    notificationMessage?: string;
  };
}

export interface WorkflowRule {
  description: string;
  customer: {
    id: number;
    label: string;
  };
  matchType: 'any' | 'all';
  any_conditions: Condition[];
  all_conditions: Condition[];
  actions: ActionConfig[];
}

const initialWorkflowRule: WorkflowRule = {
  description: '',
  customer: {
    id: 0,
    label: ''
  },
  matchType: 'any',
  any_conditions: [],
  all_conditions: [],
  actions: [],
};

export const workflowRuleSchema = z.object({
  description: z.string(),
  customer: z.object({
    id: z.number(),
    label: z.string(),
  }),
  matchType: z.enum(['any', 'all']),
  any_conditions: z.array(z.object({
    condition: z.string(),
    equality: z.string(),
    equalityNumber: z.number(),
    value: z.string(),
    conditionObj: z.record(z.string(), z.any()),
    type: z.string(),
    allAny: z.string()
  })),
  all_conditions: z.array(z.object({
    condition: z.string(),
    equality: z.string(),
    equalityNumber: z.number(),
    value: z.string(),
    conditionObj: z.record(z.string(), z.any()),
    type: z.string(),
    allAny: z.string()
  })),
})

const WorkflowRuleDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/workflow-rules/$workflowruleId' });
  const { variant, step } = useSearch({ from: '/_auth/workflow-rules/$workflowruleId' })
  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm<WorkflowRule>({
    mode: 'all',
    defaultValues: initialWorkflowRule,
    shouldUnregister: false,
    resolver: zodResolver(workflowRuleSchema)
  });

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  const [isOpen, setIsOpen] = useState(false)

  // Open drawer when component mounts
  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/workflow-rules' })
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    setActiveStep(0);
    setCompleted({});
    // methods.reset(editCustomerValues2);
    navigate({ to: '/workflow-rules' });
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    console.log('formValues : ', formValues);

    if (variant === 'edit') {
    } else {
    }
    return undefined
  }

  const steps = [
    {
      label: 'Step 1',
      key: 'step1',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep1 />,
    },
    {
      label: 'Step 2',
      key: 'step2',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep2 />,
    },
    {
      label: 'Step 3',
      key: 'step3',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep3 />,
    },
    {
      label: 'Step 4',
      key: 'step4',
      component: ({ stepKey }: { stepKey: string }) => <div>Hello Step 4</div>,
    },
  ]

  // const totalSteps = () => {
  //   return steps.length;
  // };

  // const isLastStep = () => {
  //   return activeStep === totalSteps() - 1;
  // };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  // const handleNext = () => {
  //   const newActiveStep =
  //     isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
  //   console.log(newActiveStep);
  //   setActiveStep(newActiveStep);
  //   if (activeStep === 1) {
  //     step1Ref.current?.submitForm();
  //     setCompleted({ ...completed, [activeStep]: true });
  //   }
  //   if (activeStep === 2) {
  //     step2Ref.current?.submitForm();
  //   }
  // };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  //   navigate({
  //     to: "/workflow-rules/$workflowruleId",
  //     params: { workflowruleId },

  //     search: (prev) => ({
  //       ...prev,
  //       variant: variant,
  //       step: step,
  //       completed: completed
  //     })
  //   })
  //   if (activeStep === 1) {
  //     step1Ref.current?.submitForm();
  //   }
  //   if (activeStep === 2) {
  //     step2Ref.current?.submitForm();
  //   }
  // };

  // const handleComplete = () => {
  //   if (completedSteps() === totalSteps() - 1) {
  //     console.log('saved');
  //   } else {
  //     const newCompleted = completed;
  //     newCompleted[activeStep] = true;
  //     setCompleted(newCompleted);
  //     handleNext();
  //   }
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  //   navigate({ to: '/status-editor' });
  // };

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'80%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={variant === 'edit' ? 'Edit Workflow Rule' : 'New Workflow Rule'}
        />
      }
    >
      <Grid
        container
        style={{
          height: '100%',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={'space-between'}
        flexWrap="nowrap"
        padding={4}
        overflow="auto"
      >
        <FormProvider {...methods}>
          <Container maxWidth='xl' component='form' onSubmit={methods.handleSubmit(handleSubmit)} sx={{ mt: 4 }}>
            <Grid size={{ xs: 12 }} marginTop={!isDesktop ? 2 : 0} height={'100%'} sx={{ overflow: 'auto', paddingBottom: '90px' }}>
              <SmartStepper
                routePath='/workflow-rules'
                steps={steps}
                key={steps.length}
              />
            </Grid>
          </Container>
        </FormProvider>
      </Grid >
    </DrawerLayout >
  );
};

export default WorkflowRuleDrawer;