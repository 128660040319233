import Customers from '@/app/pages/Customers/Customers.page'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/customers')({
  component: () => <IFramePage url='customer-crud.html' />,
})

const CustomersLayout = () => {
  return (
    <DashboardLayout title="Customers Editor">
      <Customers />
    </DashboardLayout>
  )
}
