import React from 'react';
import { Drawer, Paper, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import type { IDrawerProps as DrawerProps } from '@utilisourcepackagelibdev/utilisourcepackagelib';

interface IDrawerLayout extends DrawerProps {
    anchor?: 'left' | 'right' | 'top' | 'bottom';
    width?: number | string;
    children: React.ReactNode;
    Header?: any;
    Content?: any;
    Footer?: any;
}

export const DrawerLayout = (props: IDrawerLayout) => {
    const { width, anchor, Header, Content, Footer, children, ...rest } = props;

    return (
        <Drawer
            sx={[
                {
                    height: '100%',
                    flexShrink: 0,
                    zIndex: 1,
                    p: 8,

                    '& .MuiDrawer-paper': {
                        marginTop: 0 + 'px',
                        boxSizing: 'border-box',
                        borderRight: 'none',
                    },
                },
                width
                    ? {
                        width: width,
                    }
                    : {
                        width: '50%',
                    },
                width
                    ? {
                        '& .MuiDrawer-paper': {
                            width: width,
                        },
                    }
                    : {
                        '& .MuiDrawer-paper': {
                            width: '50%',
                        },
                    },
            ]}
            anchor={anchor}
            {...rest}
        >
            <Paper
                square
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignContent: 'space-between',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Grid
                    container
                    style={{
                        height: '100vh',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent={'space-between'}
                    flexWrap="nowrap"
                    padding={8}
                >
                    <Box
                        sx={{
                            height: '20px',
                        }}
                    >
                        {Header ? Header : null}
                    </Box>
                    <Box sx={{ width: '100%', height: '100%' }}>{Content ? Content : children}</Box>
                    {Footer ? (
                        <Box
                            sx={{
                                // height: "100px",
                                // width: '100%',
                                bottom: 10,
                                position: 'absolute',
                            }}
                        >
                            {Footer}
                        </Box>
                    ) : null}
                </Grid>
            </Paper>
        </Drawer>
    );
};
