export const timezoneOffset = (offset: any) => {
  if (!offset || offset === "00:00:00") {
    // console.warn("Invalid or zero offset:", offset);
    return 0; // Return 0 or any other default value you prefer
  }

  // console.log('offset:', offset);

  const match = offset.match(/([+-])(\d+):(\d+):\d+/);
  if (!match) {
    // console.error("Invalid offset format:", offset);
    return 0; // Return 0 or any other default value you prefer
  }
  const [sign, hours, minutes] = offset.match(/([+-])(\d+):(\d+):\d+/).slice(1);
  const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  return sign === '-' ? -totalMinutes : totalMinutes;
};