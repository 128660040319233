import EditIcon from '@mui/icons-material/Edit';
import { Box, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Typography, Tooltip } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton, MRT_ToggleFiltersButton, useMaterialReactTable } from 'material-react-table';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from '@tanstack/react-router';
import useColumns from '@/hooks/useColumns';
import { ticketListTableColumns } from '@/components/Organisms/TicketListTable/ticketListTableColumns';
import useTicket from '@/hooks/fetches/useTicket.service';
import useTicketTableStore, { getTableColumnSortingState } from './useTicketTableStore';
import { useDebounce } from '@/hooks/useDebounce';
import useSettings, { createUserSettingsOptions } from '@/hooks/fetches/ useSettings.service';
import { createUserQueryOptions } from '@/services/queries/pingOptions';
import { FilterToggleButton } from './TicketList.page';

const useTicketTable = () => {

  const {
    ticketTableState,
    setPagination,
    setSorting,
    setColumnFilters,
    setColumnVisibility,
    setDensity,
    setGlobalFilter,
    setShowColumnFilters,
    setTicketIds,
    setColumnOrder,
  } = useTicketTableStore();

  const columns = useColumns(ticketListTableColumns, ticketTableState?.customFilters?.customer?.id);

  const theme = useTheme();
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  const { allTickets, agentsByCustomer } = useTicket(undefined,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters.category?.label,)
  const data = allTickets?.data || [];
  const refreshQueryKey = ['all-tickets', ticketTableState?.customFilters?.customer?.id, ticketTableState?.customFilters.category?.label]

  const { updateTicketTableColumns, getSettings } = useSettings(agentsByCustomer?.data?.ContextUser?.AgentId);

  const Message = (message: string) => {
    return <Typography>{message}</Typography>;
  };

  const handleRefresh = async () => {
    // console.log('refreshing')
    const previousData = queryClient.getQueryData(refreshQueryKey);
    // console.log(previousData)

    allTickets.refetch()

    await queryClient.invalidateQueries({ queryKey: refreshQueryKey, exact: true }); // Replace 'yourQueryKey' with the actual query key
    const newData = queryClient.getQueryData(refreshQueryKey);
    // console.log(newData)
    if (newData) {
      if (JSON.stringify(newData) !== JSON.stringify(previousData)) {
        enqueueSnackbar(Message('New records found!'), { variant: 'success' });
      } else {
        enqueueSnackbar(Message('Table successfully updated'), {
          variant: 'default',
          style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
        });
      }
      // } else {
      // enqueueSnackbar(Message('No new records found'), { variant: 'error' })
    }
  };

  // Memoize the update function
  const debouncedUpdate = useDebounce(() => {
    if (ticketListTable) {
      // console.log('...updating');
      updateTicketIds();
    }
  }, 500);

  const updateTicketIds = () => {
    const newTicketIds = ticketListTable?.getPrePaginationRowModel()?.rows
      .map(row => row.original?.TicketExt?.Ticket?.TId)
      .filter(Boolean);

    setTicketIds(newTicketIds);
  };

  const saveTicketTableColumnOrder = async (newColumnOrder: any) => {

    const agentByContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));
    const settings = await queryClient.fetchQuery(createUserSettingsOptions(agentByContext?.ContextUser?.AgentId));

    const previousData = settings?.data || {};
    let preparedData = {}
    if (!previousData || !previousData.Data || previousData.Data.length === 0) {
      preparedData = {
        Id: agentByContext?.ContextUser?.AgentId,
        Data: [
          {
            Identifier: "ticketTableColumnSorting",
            ColumnOrder: newColumnOrder,
          },
        ],
      };
    } else {
      const updatedData = [...previousData.Data];

      const columnSortingIndex = updatedData.findIndex(
        (entry: any) => entry.Identifier === "ticketTableColumnSorting"
      );

      if (columnSortingIndex !== -1) {
        updatedData[columnSortingIndex] = {
          ...updatedData[columnSortingIndex],
          ColumnOrder: newColumnOrder,
        };
      } else {
        updatedData.push({
          Identifier: "ticketTableColumnSorting",
          ColumnOrder: newColumnOrder,
        });
      }

      preparedData = {
        SettingsId: previousData.SettingsId,
        Type: previousData.Type,
        Id: previousData.Id,
        Data: updatedData,
      };
    }

    try {
      await updateTicketTableColumns({
        actionType: "updateTicketTableColumns",
        data: preparedData,
      });
    } catch (error) {
      console.error("Error saving column order:", error);
    }
  };

  useEffect(() => {
    const getColumnOrder = async () => {
      const columnSortingState = await getTableColumnSortingState()
      setColumnOrder(columnSortingState)
    }

    getColumnOrder();
  }, []);

  useEffect(() => {
    setColumnVisibility(prev => ({
      ...prev,
      conditionalColumn: ticketTableState?.customFilters?.customer?.id === 6
    }));
  }, [ticketTableState?.customFilters?.customer?.id]);

  useEffect(() => {
    debouncedUpdate();
  }, [
    ticketTableState.sorting,
    ticketTableState.columnFilters,
    ticketTableState.globalFilter,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters?.category?.label,
    data // Add this to update when data changes
  ]);

  const ticketListTable = useMaterialReactTable({
    data,
    columns,
    enableSorting: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: true,
    positionGlobalFilter: 'left',
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onPaginationChange: setPagination,
    enableTopToolbar: true,
    positionToolbarAlertBanner: 'bottom',
    enableColumnOrdering: true,
    onColumnOrderChange: (newColumnOrder) => {
      saveTicketTableColumnOrder(newColumnOrder);
      setColumnOrder(newColumnOrder);
    },
    enableFilterMatchHighlighting: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 10,
      },
    },
    muiTablePaperProps: {
      sx: {
        p: 2,
        borderRadius: '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px',
        backgroundImage: 'none',
      },
    },
    muiTableBodyProps: {
      sx: {
        border: 'none',
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    getRowId: (row: any) => row?.name,
    renderRowActions: ({ row, table }: any) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        <Tooltip title="Edit">
          <IconButton
            id="edit"
            size="small"
            onClick={(event: any) => {
              event.stopPropagation();
              navigate({
                to: `/ticket/$ticketId`,
                params: { ticketId: row.original.TicketExt.Ticket.TId },
                search: { variant: 'edit', step: 0 }
              })
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: (event: any) => {
        event.stopPropagation();
        navigate({
          to: `/ticket/$ticketId`,
          params: { ticketId: row.original.TicketExt.Ticket.TId },
          search: { variant: 'edit', step: 0 }
        })
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderToolbarInternalActions: ({ table }: any) => (
      <>
        <FilterToggleButton />
        <MRT_ToggleFiltersButton table={ticketListTable} />
        <IconButton onClick={handleRefresh} id={'refresh'}>
          <RefreshIcon />
        </IconButton>
        <MRT_ShowHideColumnsButton table={ticketListTable} />
        <MRT_ToggleFullScreenButton table={ticketListTable} />
      </>
    ),
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: ticketTableState.sorting,
      columnVisibility: ticketTableState.columnVisibility,
      columnFilters: ticketTableState.columnFilters,
      globalFilter: ticketTableState.globalFilter,
      showColumnFilters: ticketTableState.showColumnFilters,
      pagination: ticketTableState.pagination,
      columnOrder: ticketTableState.columnOrder,
    },
    state: {
      showAlertBanner: allTickets.isError,
      showSkeletons: allTickets.isLoading,
      showProgressBars: allTickets.isFetching,
      sorting: ticketTableState.sorting,
      columnVisibility: ticketTableState.columnVisibility,
      columnFilters: ticketTableState.columnFilters,
      globalFilter: ticketTableState.globalFilter,
      showColumnFilters: ticketTableState.showColumnFilters,
      pagination: ticketTableState.pagination,
      columnOrder: ticketTableState.columnOrder
    },
  });

  return {
    ticketListTable,
    refetch: allTickets.refetch,
  };
}

export default useTicketTable