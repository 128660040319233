import type { TextFieldProps } from '@mui/material';
import { TextField, useForkRef } from '@mui/material';
import { useTransform, useFormError } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import type { ReactNode, Ref, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
import type {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  PathValue,
  UseControllerReturn,
} from 'react-hook-form';
import { useController } from 'react-hook-form';

export type UseTransformOptions<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  value: UseControllerReturn<TFieldValues, TName>['field']['value'];
  onChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'];
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => PathValue<TFieldValues, TName>;
    output?: (...event: any[]) => PathValue<TFieldValues, TName>;
  };
};

type TextBoxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'name'> & {
  validation?: UseControllerProps<TFieldValues, TName>['rules'];
  name: TName;
  parseError?: (error: FieldError) => ReactNode;
  control?: Control<TFieldValues>;
  /**
   * You override the MUI's TextField component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of TextField.
   */
  component?: typeof TextField;
  transform?: UseTransformOptions<TFieldValues, TName>['transform'];
};

type Textbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: TextBoxProps<TFieldValues, TName> & RefAttributes<HTMLDivElement>,
) => JSX.Element;

const TextBox = forwardRef(function TextBox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: TextBoxProps<TFieldValues, TName>, ref: Ref<HTMLDivElement>): JSX.Element {
  const {
    validation = {},
    parseError,
    type,
    required,
    name,
    control,
    component: TextFieldComponent = TextField,
    inputRef,
    transform,
    ...rest
  } = props;

  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;

  const rules = {
    ...validation,
    ...(required && !validation.required && { required: 'This field is required' }),
    ...(type === 'email' &&
      !validation.pattern && {
        pattern: {
          value:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Please enter a valid email address',
        },
      }),
  };

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    disabled: rest.disabled,
    rules,
    // shouldUnregister: true,
  });
  const { value, onChange } = useTransform({
    value: field.value,
    onChange: field.onChange,
    transform,
  });

  const handleInputRef = useForkRef(field.ref, inputRef);

  return (
    <TextFieldComponent
      {...rest}
      name={field.name}
      value={value ?? ''}
      onChange={(ev) => {
        onChange(type === 'number' && ev.target.value ? +ev.target.value : ev.target.value);
        if (typeof rest.onChange === 'function') {
          rest.onChange(ev);
        }
      }}
      onBlur={field.onBlur}
      required={required}
      type={type}
      error={!!error}
      helperText={
        error ? (typeof customErrorFn === 'function' ? customErrorFn(error) : error.message) : rest.helperText
      }
      ref={ref}
      inputRef={handleInputRef}
    />
  );
});

export default TextBox as Textbox;
