import useTicket from '@/hooks/fetches/useTicket.service'
import { Container } from '@mui/material'
import { AutoComplete, Box, Divider, TextField, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import React from 'react'

const WorkflowRuleStep1 = () => {

  const { customers } = useTicket()

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant='button' color="text.primary" sx={{ mt: 4 }}>
        Select a Customer and Description
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box sx={{ mt: 4 }}>
        <TextField
          id="description"
          name="description"
          required
          variant="outlined"
          label="Description"
          placeholder='Type a Title'
          style={{ width: '100%' }}
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <AutoComplete
          id="customer"
          options={
            customers.isPending
              ? []
              : customers?.data?.map((customer: any) => ({
                id: customer.CustomerEntId,
                label: customer.EntName,
              })) || []
          }
          loading={customers.isPending}
          autocompleteProps={{
            id: 'customer',
            loadingText: 'Loading Customers...',
          }}
          multiple={false}
          label="Customer"
        />
      </Box>
    </Container>
  )
}

export default WorkflowRuleStep1