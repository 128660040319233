import { Container, Divider, Switch } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AutoComplete, Box, TextField, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import React, { useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

const WorkflowRuleStep3 = () => {

  const methods = useFormContext()

  const assigneeSwitchWatch = useWatch({ control: methods.control, name: 'assignee_switch' });
  const statusSwitchWatch = useWatch({ control: methods.control, name: 'status_switch' });
  const emailSwitchWatch = useWatch({ control: methods.control, name: 'email_switch' });

  useEffect(() => {
    console.log(assigneeSwitchWatch)
  }, [assigneeSwitchWatch])

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant='button' color="text.primary" sx={{ mt: 4 }}>
        Choose Actions
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Grid spacing={2}>
        <Grid size={3}>
          <Typography variant="body2" color="text.primary" >
            Set Assignee:
          </Typography>
        </Grid>
        <Grid size={8}>
          {assigneeSwitchWatch && (

            <AutoComplete
              id="action_assignee"
              label={"Select Assignee"}
              placeholder='Select Assignee'
              required
              options={[
                { label: 'Test 1', id: "test1" },
                { label: 'Test 2', id: "test2" },
                { label: 'Test 3', id: "test3" },
              ]}
            />
          )}
          {/* // <SelectElement
            //   name="action_assignee"
            //   required
            //   size='small'
            //   fullWidth
            //   label="Select Assignee"
            //   // key={workflowRulesState.customers.length}
            //   options={workflowRulesState?.oldConfig?.Agents && workflowRulesState.oldConfig.Agents.map((agent: any) => {
            //     return { label: agent.Name, id: agent.AgentId }
            //   })}
            //   // valueKey="CustomerEntId"
            //   // labelKey="EntName"
            //   onChange={(value: any) => {
            //     console.log("selected customer")
            //     setValue("action_assignee_string", value)
            //   }}
            // />
            // )} */}
        </Grid>
        <Grid size={1}>
          <Controller
            name="assignee_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='assignee_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.primary" >
            Set Status:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {statusSwitchWatch && (
            <AutoComplete
              id="action_status"
              label={"Select Status"}
              placeholder='Select Status'
              required
              options={[
                { label: 'Test 1', id: "test1" },
                { label: 'Test 2', id: "test2" },
                { label: 'Test 3', id: "test3" },
              ]}
            />
            // <SelectElement
            //   name="action_status"
            //   required
            //   size='small'
            //   fullWidth
            //   label="Select Status"
            //   options={workflowRulesState?.oldConfig?.Stati && workflowRulesState.oldConfig.Stati.map((status: any) => {
            //     return { label: status.Name, id: status.StatusId }
            //   })}
            //   onChangeReturnValue="value"
            //   // objectOnChange={true}
            //   onChange={(value: any) => {
            //     console.log("selected ")
            //     console.log(value)
            //     setValue("action_status_string", value)
            //   }}
            // />
          )}
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="status_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='status_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.primary" >
            Set Email:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {emailSwitchWatch && (
            <>
              <Box>
                <AutoComplete
                  id="email_recipients"
                  required
                  multiple
                  label="Recipients"
                  placeholder='Type or Select Emails'
                  options={[]}
                // autocompleteProps={{
                // freeSolo: true
                // }}
                // options={workflowRulesState?.oldConfig?.Agents && workflowRulesState.oldConfig.Agents.map((agent: any) => {
                //   return { label: agent.Name, id: agent.AgentId }
                // })}
                // showAddOption={true}
                />
              </Box>
              <Box style={{ marginTop: '1rem' }}>
                <TextField
                  id="email_subject"
                  name="email_subject"
                  required
                  variant="outlined"
                  label="Subject"
                  placeholder='Type Subject'
                  style={{ width: '100%' }}
                />
              </Box>
              <Box style={{ marginTop: '1rem' }}>
                {/* <Controller
                                            name="email_body"
                                            control={methods.control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <EditorMenu
                                                    initialContent={content}
                                                    extractContent={handleContent}
                                                    placeholder='Type Email Body Here...'
                                                    {...field}
                                                />
                                            )}
                                        /> */}
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="email_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='email_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default WorkflowRuleStep3