import Agents from '@/app/pages/Agents/Agents.page'
import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/agents')({
  component: () => <IFramePage url='user-crud.html' />,
})

const AgentsLayout = () => {
  return (
    <DashboardLayout title="Agents Editor">
      <Agents />
    </DashboardLayout>
  )
}
