import { Accordion, AccordionSummary, Button } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Typography, Box, AccordionDetails, TextField } from '@mui/material';
import useTicketList from '@/contexts/stores/ticketList.store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { timezoneOffset } from '@/util/timezoneOffset.util';
import useTicket from '@/hooks/fetches/useTicket.service';
import Permissions from '@/permissions/Permissions';
import { useParams } from '@tanstack/react-router';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import dayjs from 'dayjs';

const Notes = () => {

  const { ticketListState } = useTicketList();
  dayjs.extend(utc);
  const [showAddNoteButton, setShowAddNoteButton] = useState(true);
  const methods = useForm();

  const { control } = methods;

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const ticketId2 = Number(ticketId)

  const {
    notesById,
    updateTicketNotes,
    agentsByCustomer
  } = useTicket(
    ticketId2,
    ticketListState?.selectedCustomer?.id
  );

  const createNoteAccordions = () => {
    const sortedNotesWithSubjects = notesById?.data
      .filter((note: any) => note.Message)
      .sort((a: any, b: any) => new Date(b.Createtime).getTime() - new Date(a.Createtime).getTime());

    return sortedNotesWithSubjects?.map((note: any) => {
      const offsetInMinutes = timezoneOffset(note.TimeZoneOffset);
      const localizedTime = offsetInMinutes ? dayjs.utc(note.Createtime).utcOffset(offsetInMinutes) : dayjs(note.Createtime);
      return (
        <Accordion id={note.Nid} key={note.Nid} sx={{ marginBottom: 2, borderRadius: 1, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", '&::before': { display: 'none' }, }}>
          <AccordionSummary
            sx={{
              '& .MuiAccordionSummary-content': {
                display: "flex",
                flexDirection: "column",
              },
              padding: '8px 16px',
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="body2" >
              {note.AuthorName} {dayjs(note.Createtime).format("MM/DD/YYYY")} {localizedTime.format("hh:mm A")} {note.TimeZone}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '16px',
            }}
          >
            <Typography variant="body1">
              {note.Message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    });

  };

  const onSubmit = async () => {
    const methodValues = methods.getValues()
    setShowAddNoteButton(true);

    const preparedData = {
      "message": methodValues.note,
      "subject": "",
      "ticketid": ticketId2,
      "authorname": agentsByCustomer.data.ContextUser.Name
    }

    try {
      if (methodValues.note) {
        await updateTicketNotes({ actionType: 'updateTicketNotes', data: preparedData });
      }
      methods.reset({ note: "" });
    } catch (error) {
      console.error("Error updating Note:", error);
    }
  }

  return (
    <>
      {showAddNoteButton &&
        <Permissions target={'ticketDetailSave'}>
          <Button id='addNoteBtn' variant='outlined' onClick={() => setShowAddNoteButton(false)} sx={{ mb: 4 }}>Add Note</Button>
        </Permissions>
      }

      {!showAddNoteButton &&
        <Box>
          <Button id='cancelNoteBtn' color='error' variant='outlined' onClick={() => setShowAddNoteButton(true)} sx={{ mb: 4 }}>Cancel</Button>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box component="form">
                <Controller
                  name="note"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-static"
                      label="Note"
                      multiline
                      rows={4}
                      sx={{ mb: 4, width: '30%' }}
                    />
                  )}
                />
              </Box>
            </form>
          </FormProvider>
          <Button id='saveNoteBtn' variant='outlined' onClick={methods.handleSubmit(onSubmit)} sx={{ mb: 4 }}>Save</Button>
        </Box>
      }
      {notesById.data && createNoteAccordions()}
    </>
  )
}

export default Notes