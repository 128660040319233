import { Accordion, AccordionSummary, AutoComplete, TextField } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { AccordionDetails, Autocomplete, Box, Checkbox, Grid2 as Grid, Typography } from '@mui/material';
import NumberFieldInput from '@/components/Atoms/TextField/NumberField.component';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import useTicketList from '@/contexts/stores/ticketList.store';
import { useFormContext, Controller } from "react-hook-form";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useTicket from '@/hooks/fetches/useTicket.service';
import { useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface typeinfo {
  ClassBinding: string
}

export function CFTypeIdDetail(id: number): typeinfo {
  switch (id) {
    case 16: return { ClassBinding: "cfTInteger" }
    case 17: return { ClassBinding: "cfTString" }
    case 18: return { ClassBinding: "cfTFile" }
    case 19: return { ClassBinding: "cfTDropdown" }
    case 20: return { ClassBinding: "cfTToggle" }
    case 21: return { ClassBinding: "cfTArcGIS" }
    case 22: return { ClassBinding: "cfTDate" }
  }
  throw new Error('Not a valid typeid')
}

export const checkType = (parent: any) => {
  let typeInfo = CFTypeIdDetail(parent.ValueMeta.C.TypeId).ClassBinding

  switch (typeInfo) {
    case 'cfTInteger':
      return parent.Value.MyInt

    case 'cfTDropdown':
      return parent.Value.SelectedOpt

    case 'cfTString':
      return parent.Value.Str

    case 'cfTToggle':
      return parent.Value.Checked

    case 'cfTDate':
      return parent.Value.TimeVal

    default:
      break;
  }
}

const CustomFields = (props: any) => {
  const renderedFieldsSet = new Set();
  const [customFieldValuesData, setCustomFieldValuesData] = useState<any>(null);

  const { ticketListState, updateTicketList } = useTicketList();
  const methods = useFormContext();
  const [customFieldSearchOptions, setCustomFieldSearchOptions] = useState([]);
  const { control, watch } = methods;

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const ticketId2 = Number(ticketId)

  const {
    customFields,
    customFieldGroups
  } = useTicket(
    ticketId2,
  );

  const checkAncestorsVisibility = (childField: any, allFields: any): any => {
    // This function checks if the child field should be visible based on its ancestors.
    // So if the parent is hidden, both the child and grandchilds will be hidden and so on.
    const parentFieldId = childField.ValueMeta.C.Meta.Settings.ParentFieldId;
    const parentFieldValue = childField.ValueMeta.C.Meta.Settings.ParentFieldValue
    const parentInputValue = watch(parentFieldId.toString());

    if (
      (parentFieldValue === "Has value" && parentInputValue) ||
      (parentFieldValue === "Has no value" && !parentInputValue) ||
      (parentFieldValue === "Checked" && parentInputValue === true) ||
      (parentFieldValue === "Unchecked" && parentInputValue === false) ||
      (parentFieldValue === "any" && parentInputValue) ||
      (parentFieldValue.includes('Greater than') && (parentInputValue > parseInt(parentFieldValue.match(/Greater than (\d+)/)[1], 10))) ||
      (parentFieldValue.includes('Less than') && (parentInputValue < parseInt(parentFieldValue.match(/Less than (\d+)/)[1], 10))) ||
      (parentFieldValue.includes('Equals') && (parentInputValue == parentFieldValue.match(/Equals (\d+)/)[1])) ||
      (parentInputValue && typeof parentInputValue === 'object' && parentInputValue.label && parentInputValue.label === parentFieldValue) ||
      (!parentFieldId)
    ) {
      // Recursively check if the parent should be visible.
      const parentField = allFields.find((field: any) => field.ValueMeta.C.Meta.Cid === parentFieldId);
      return parentField ? checkAncestorsVisibility(parentField, allFields) : true;
    }
  };

  const renderFieldWithChildren = (childField: any, allFields: any, currentGroupId: number) => {
    // This function renders a field and its children if they should be visible.

    // Check if the field belongs to the current group
    if (childField.ValueMeta.C.Meta.Settings.FieldGroupId !== currentGroupId) {
      return null;
    }

    // Determine if the field and all its ancestors should be visible
    const shouldRender = checkAncestorsVisibility(childField, allFields);

    if (!shouldRender) return null; // If not visible, don't render this field or its children.

    // Check if this field has already been rendered
    if (renderedFieldsSet.has(childField.ValueMeta.C.Meta.Cid)) {
      return null; // If already rendered, don't render it again
    }

    // Mark the field as rendered
    renderedFieldsSet.add(childField.ValueMeta.C.Meta.Cid);

    // Recursively find children of this field
    const childFields = allFields.filter(
      (field: any) => field.ValueMeta.C.Meta.Settings.ParentFieldId === childField.ValueMeta.C.Meta.Cid
    );

    return (
      <>
        {/* Render the field */}
        {renderField(childField)}

        {/* Recursively render its child fields */}
        {childFields.map((field: any) => renderFieldWithChildren(field, allFields, currentGroupId))}
      </>
    );
  };

  const renderField = (childField: any) => {
    let typeInfo = CFTypeIdDetail(childField.ValueMeta.C.TypeId).ClassBinding;

    switch (typeInfo) {
      case 'cfTInteger':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }} key={childField.ValueMeta.C.Meta.Cid.toString()}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <NumberFieldInput
                    {...field}
                    id={childField.ValueMeta.C.Meta.Cid.toString()}
                    placeholder={childField.ValueMeta.C.Meta.Name}
                    onDataChange={(newValue: any) => field.onChange(newValue)}
                    label={childField.ValueMeta.C.Meta.Name}
                    min={childField.ValueMeta.C.Type.Minimum}
                    max={childField.ValueMeta.C.Type.Maximum}
                    step={childField.ValueMeta.C.Type.Step}
                    required={childField.ValueMeta.C.Meta.Settings.IsRequired}
                  />
                )}
              />
            </Box>
          </Grid>
        );

      case 'cfTDropdown':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <AutoComplete
                id={childField.ValueMeta.C.Meta.Cid.toString()}
                options={childField.ValueMeta.C.Type.OptLabels.map((data: any, index: number) => ({ id: index, label: data })) || []}
                loading={childField.ValueMeta.C.Type.OptLabels?.isPending}
                autocompleteProps={{
                  id: 'assignee',
                  loadingText: `Loading ${childField.ValueMeta.C.Meta.Name} Options...`,
                  size: 'small'
                }}
                multiple={false}
                label={childField.ValueMeta.C.Meta.Name}
                required={childField.ValueMeta.C.Meta.Settings.IsRequired}
              />
            </Box>
          </Grid>
        );

      case 'cfTString':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <TextBox
                id={childField.ValueMeta.C.Meta.Cid.toString()}
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                label={childField.ValueMeta.C.Meta.Name}
                size="small"
                fullWidth
                required={childField.ValueMeta.C.Meta.Settings.IsRequired}
              />
            </Box>
          </Grid>
        );

      case 'cfTToggle':
        return (
          <Grid size={{ xs: 6, sm: 12, md: 12 }} key={childField.ValueMeta.C.Meta.Cid.toString()}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Grid display="flex" alignItems="center">
                    <Checkbox
                      {...field}
                      id={childField.ValueMeta.C.Meta.Cid.toString()}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      required={childField.ValueMeta.C.Meta.Settings.IsRequired}
                    />
                    <Typography ml={1}>{childField.ValueMeta.C.Meta.Name}</Typography>
                  </Grid>
                )}
              />
            </Box>
          </Grid>
        );

      case 'cfTDate':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => {
                  if (childField.ValueMeta.C.Type.IncludeTime) {
                    return (
                      <DateTimePicker
                        {...field}
                        label={childField.ValueMeta.C.Meta.Name}
                        onChange={(newValue) => field.onChange(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                            id: childField.ValueMeta.C.Meta.Cid.toString(),
                          },
                        }}
                      />
                    );
                  } else {
                    return (
                      <DatePicker
                        {...field}
                        label={childField.ValueMeta.C.Meta.Name}
                        onChange={(newValue) => field.onChange(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                            id: childField.ValueMeta.C.Meta.Cid.toString(),
                          },
                        }}
                      />
                    );
                  }
                }}
              />
            </Box>
          </Grid>
        );

      default:
        return null;
    }
  };

  const buildCustomFieldInputs = (groupFields: any, allFields: any, currentGroupId: number) => {
    return groupFields.map((field: any) => renderFieldWithChildren(field, allFields, currentGroupId));
  };

  const requiredColor = (trueCount: number, totalCount: number) => {
    if (trueCount === 0 && totalCount === 0) {
      return 'gray';
    } else if (trueCount === totalCount) {
      return 'green';
    } else {
      return 'red';
    }
  };

  const createGroupAccordions = () => {
    const allFieldValues = watch();

    const sortedAllFields = customFields?.data?.sort((a: any, b: any) => {
      const nameA = a.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
      const nameB = b.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });

    const groupedFields: Record<number, { groupName: string, fields: any[] }> = {};

    sortedAllFields?.forEach((field: any) => {
      const fieldGroupId: number = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId;

      if (!groupedFields[fieldGroupId]) {
        const groupName = customFieldGroups?.data.find((group: any) => group.FieldGroupId === fieldGroupId)?.GroupName || 'Unknown Group';
        groupedFields[fieldGroupId] = {
          groupName: groupName,
          fields: []
        };
      }

      groupedFields[fieldGroupId].fields.push(field);
    });

    return customFieldGroups?.data.map((group: any) => {
      const sortedFields = groupedFields[group.FieldGroupId]?.fields || [];

      const trueVisible = sortedFields.filter((field: any) =>
        checkAncestorsVisibility(field, sortedAllFields)
      );

      const visibleRequiredFields = sortedFields.filter(
        (field: any) => field.ValueMeta?.C?.Meta?.Settings?.IsRequired && checkAncestorsVisibility(field, sortedAllFields)
      );

      const totalCount = visibleRequiredFields.length;

      const trueCount = visibleRequiredFields.reduce((count, field) => {
        const cid = field.ValueMeta.C.Meta.Cid.toString();
        const fieldValue = allFieldValues[cid];
        return fieldValue ? count + 1 : count;
      }, 0);

      return (
        <Accordion id={group.FieldGroupId.toString()} key={group.FieldGroupId} sx={{ marginBottom: 4, '&::before': { display: 'none' } }}>
          <AccordionSummary
            sx={{
              '& .MuiAccordionSummary-content': {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid>
              {group.GroupName} ({trueVisible.length})
            </Grid>
            <Grid sx={{ color: requiredColor(trueCount, totalCount), marginRight: 2 }}>
              {`Required ${trueCount}/${totalCount}`}
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 4 }}>
            <Grid key={group.FieldGroupId.toString()} container spacing={3} display={"flex"} flexWrap={"wrap"}>
              {sortedFields && buildCustomFieldInputs(sortedFields, sortedAllFields, group.FieldGroupId)}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const deepEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const createCustomFieldSearch = () => {
    const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
      if (newInputValue.trim()) {
        const customFieldsContainer = document.getElementById("custom-fields");

        const customFieldInputs = customFieldsContainer?.querySelectorAll("input");
        const customFieldInputsArray = Array.from(customFieldInputs || []);

        const filteredOptions = customFields?.data
          .filter((data: any) => data.ValueMeta.C.Meta.Name.toLowerCase().includes(newInputValue.toLowerCase()))
          .map((data: any) => ({
            id: data.ValueMeta.C.Meta.Cid,
            label: data.ValueMeta.C.Meta.Name,
            isHidden: !customFieldInputsArray.some(
              (input) => input.getAttribute("id") === String(data.ValueMeta.C.Meta.Cid)
            ),
            parentName: customFields?.data.find(
              (input: any) => input.ValueMeta.C.Meta.Cid === data.ValueMeta.C.Meta.Settings.ParentFieldId)?.ValueMeta.C.Meta.Name,
          })) || [];

        setCustomFieldSearchOptions(filteredOptions);
      } else {
        setCustomFieldSearchOptions([]);
      }
    };

    const handleFieldFocus = (inputId: string) => {
      const targetInput = document.getElementById(inputId);
      if (targetInput) {
        const parentAccordion = targetInput.closest(".MuiAccordion-root");
        if (parentAccordion) {
          const isExpanded = parentAccordion.querySelector("[aria-expanded=true]");
          if (!isExpanded) {
            const accordionHeader = parentAccordion.querySelector(
              ".MuiAccordionSummary-root"
            );
            if (accordionHeader) {
              (accordionHeader as HTMLElement).click();
            }
          }

          setTimeout(() => {
            targetInput.scrollIntoView({ behavior: "smooth", block: "center" });
            targetInput.focus();
          }, 300);
        }
      }
    };

    return (
      <Autocomplete
        id="custom-field-search"
        options={customFieldSearchOptions}
        onInputChange={handleInputChange}
        renderInput={(params) => <TextField {...params} label="Search Custom Fields" />}
        size="small"
        sx={{ mb: 4 }}
        onChange={(event, newValue: { id: string, label: string, isHidden: boolean, parentName: string } | null) => {
          if (newValue) {
            handleFieldFocus(newValue.id.toString());
          }
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Box>
              <Typography>{option.label}</Typography>
              {option.isHidden && (
                <Typography variant="caption" color="textSecondary">
                  Hidden by {option.parentName}. The value of {option.parentName} has to change before this field is visible.
                </Typography>
              )}
            </Box>
          </li>
        )}
      />
    );
  };

  useEffect(() => {
    if (customFields.data && ticketId2 && !deepEqual(customFields.data, customFieldValuesData)) {
      // This useEffect will run everytime that the customFields API endpoint gets called. This will cause the inputs values to be reset everytime.
      // By adding the deepEqual check, this will determine if the incoming data is actually different from the previous state and allow the data to be reset.
      const { data } = customFields

      if (data) {
        const customFieldValues = {};
        data.forEach((field: any, index: number) => {
          const cid = field.ValueMeta.C.Meta.Cid.toString();
          const cfValue = checkType(field)
          const classBinding = CFTypeIdDetail(field.ValueMeta.C.TypeId).ClassBinding

          if (classBinding === "cfTDropdown") {
            const id = field.ValueMeta.C.Type.OptLabels.findIndex((opt: any) => opt === cfValue);
            return (customFieldValues as any)[cid] = {
              id: id,
              label: cfValue,
            }
          } else if (classBinding === "cfTDate") {
            return (cfValue && cfValue !== "0001-01-01T00:00") ? (customFieldValues as any)[cid] = dayjs(cfValue) : (customFieldValues as any)[cid] = null
          } else {
            (customFieldValues as any)[cid] = cfValue;
          }
        });

        methods.reset({
          ...methods.getValues(),
          ...customFieldValues,
        });
        setCustomFieldValuesData(customFields.data);
      }
    }
  }, [customFields.data, ticketId2]);

  useEffect(() => {
    if (customFields.data) {
      const initialRequiredFields: { [key: string]: any } = {};

      customFields.data.forEach((field: any) => {
        if (field.ValueMeta?.C?.Meta?.Settings.IsRequired) {
          const fieldGroupId = field.ValueMeta.C.Meta.Settings.FieldGroupId;
          const cid = field.ValueMeta.C.Meta.Cid.toString();
          const inputElement = document.querySelector(`[name="${cid}"]`) as HTMLInputElement;

          initialRequiredFields[fieldGroupId] = {
            ...(initialRequiredFields[fieldGroupId] || {}),
            [cid]: !!inputElement?.value,
          };
        }
      });
    }
  }, [customFields.data]);

  useEffect(() => {
    if (customFields?.data) {
      const customFieldsContainer = document.getElementById("custom-fields");
      const customFieldInputs = customFieldsContainer?.querySelectorAll("input");
      const customFieldInputsArray = Array.from(customFieldInputs || []);
      const length = customFieldInputsArray.length;

      if (ticketListState?.totalVisibleCustomFields !== length) {
        updateTicketList({ totalVisibleCustomFields: length });
      }
    }
  }, [customFields?.data, watch()]);


  return (
    <>
      {customFields?.data && createCustomFieldSearch()}
      {customFieldGroups?.data && createGroupAccordions()}
    </>
  )
}

export default CustomFields