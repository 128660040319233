import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { Theme, useMediaQuery } from '@mui/material';
import { AutoComplete, Button, Card, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import SimpleTable from '@/components/Molecules/Table/SimpleTable.component';
import useSampleTable from '@/hooks/tables/sampleTable/useSampleTable';
import { useNavigate } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { createAgentsQueryOptions, createBaseFieldsQueryOptions, createCustomFieldsQueryOptions, createWorkflowRuleQueryOptions, createAllWorkflowRulesQueryOptions } from '@/services/queries/workflowOptions';
import useTicket from '@/hooks/fetches/useTicket.service';
import useWorkflowRuleTable from '@/app/pages/WorkflowRules/useWorkflowRuleTable';
import WorkflowBuilder from '@/components/Organisms/NodeFlow/WorkflowBuilder';
import { FormProvider, useForm } from 'react-hook-form';

const WorkflowRules = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const { workflowTable } = useWorkflowRuleTable()

  const customFields = useQuery(createCustomFieldsQueryOptions({ full: true, customerid: 8 }))
  const workflowRule = useQuery(createWorkflowRuleQueryOptions({ srid: 6 }))
  const allWorkflowRules = useQuery(createAllWorkflowRulesQueryOptions())

  const { baseFields, agentsByCustomer, customers } = useTicket(8)

  const methods = useForm();


  useEffect(() => {
    console.log(customFields.data)
    console.log(baseFields.data)
    console.log(agentsByCustomer.data)
    console.log(workflowRule.data)
    console.log(allWorkflowRules.data)

  }, [customFields.isFetching, baseFields.data, agentsByCustomer.data, workflowRule.data, allWorkflowRules.data])

  const handleSubmit = (formValues: any) => {
    console.log(formValues);
    const customer = workflowTable.setColumnFilters((prev: any) => [
      ...prev,
      {
        id: 'Customer',
        value: formValues.customer_filter ? formValues.customer_filter?.label : '',
      },
    ]);

    return { customer };
  }

  return (
    <>
      <Grid size={{ xs: 12, md: 3 }} display="flex" flexDirection="column" rowGap={4} paddingX={2} paddingY={1}>
        <Typography variant="h4">Filters</Typography>
        <Card sx={{ p: 2 }}>
          <FormProvider {...methods}>
            <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
              <Grid
                size={{ xs: 12, md: 12 }}
                display="flex"
                flexDirection="column"
                rowGap={4}
                paddingX={2}
                paddingY={1}
              >
                <AutoComplete
                  id="customer_filter"
                  options={
                    customers.isPending
                      ? []
                      : customers?.data?.map((customer: any) => ({
                        id: customer.CustomerEntId,
                        label: customer.EntName,
                      })) || []
                  }
                  loading={customers.isPending}
                  autocompleteProps={{
                    id: 'customer_filter',
                    loadingText: 'Loading Customers...',
                  }}
                  multiple={false}
                  label="Customer"
                />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    id="clear"
                    size='small'
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => {
                      methods.reset();
                      workflowTable.resetColumnFilters();
                    }}
                  >
                    Clear
                  </Button>
                  <Button id="filterResults" variant="contained" type="submit" size='small'>
                    Filter Results
                  </Button>
                </div>
              </Grid>
            </form>
          </FormProvider>
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 9 }} width={'100%'} marginTop={!isDesktop ? 2 : 0} height={'100%'}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" marginBottom={4}>
            Manage Workflow Rules
          </Typography>
          <Button
            id="createWorkflowRules"
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginBottom: 4 }}
            onMouseDown={() => {
              console.log('open drawer');
              navigate({
                to: '/workflow-rules/$workflowruleId',
                params: { workflowruleId: 0 },
                search: {
                  variant: 'new',
                  step: 0,
                  completed: {},
                }
              })
            }}
          >
            + New Workflow Rule
          </Button>
        </div>
        {/* <WorkflowBuilder /> */}
        {<SimpleTable table={workflowTable} />}
      </Grid>
    </>
  );
};

export default WorkflowRules;