import { queryOptions } from '@tanstack/react-query';
import WorkflowRulesService from '@/services/workflowRules.service';

// Define the workflow rules key factory
export const workflowRulesKeys = {
  all: ['workflowRules'] as const,
  lists: () => [...workflowRulesKeys.all, 'list'] as const,
  detail: (srid?: number) => [...workflowRulesKeys.all, 'detail', srid] as const,
  agents: (customerid?: number) => [...workflowRulesKeys.all, 'agents', customerid] as const,
  baseFields: (ticket?: number) => [...workflowRulesKeys.all, 'baseFields', ticket || -1] as const,
  customFields: (full: boolean, customerid?: number) => [...workflowRulesKeys.all, 'customFields', customerid, full] as const
};

const workflowRulesService = WorkflowRulesService();

export const createAllWorkflowRulesQueryOptions = () => {
  return queryOptions({
    queryKey: workflowRulesKeys.all,

    queryFn: async () => {
      const response = await workflowRulesService.getAllWorkflowRules();
      return response;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 60 * 1000 * 5, // 1 minute
    enabled: true,
    refetchOnWindowFocus: false
  });
};

export const createWorkflowRuleQueryOptions = ({ srid }: { srid?: number }) => {
  return queryOptions({
    queryKey: workflowRulesKeys.detail(srid),
    queryFn: async () => {
      if (!srid) return null;
      const response = await workflowRulesService.getWorkflowRule({ srid });
      return response;
    },
    staleTime: 5 * 60 * 1000,
    refetchInterval: 60 * 1000,
    enabled: !!srid,
    refetchOnWindowFocus: false
  });
};

export const createAgentsQueryOptions = ({ customerid }: { customerid?: number }) => {
  return queryOptions({
    queryKey: workflowRulesKeys.agents(customerid),
    queryFn: async () => {
      if (!customerid) return null;
      const response = await workflowRulesService.getAgents({ customerid });
      return response;
    },
    staleTime: 10 * 60 * 1000, // 10 minutes
    enabled: !!customerid,
    refetchOnWindowFocus: false
  });
};

export const createBaseFieldsQueryOptions = ({ ticket }: { ticket?: number }) => {
  return queryOptions({
    queryKey: workflowRulesKeys.baseFields(ticket),
    queryFn: async () => {
      const response = await workflowRulesService.getBaseFields(ticket ? { ticket } : { ticket: -1 });
      return response;
    },
    staleTime: 30 * 60 * 1000, // 30 minutes
    enabled: true,
    refetchOnWindowFocus: false
  });
};

export const createCustomFieldsQueryOptions = ({ full, customerid }: { full: boolean; customerid?: number }) => {
  return queryOptions({
    queryKey: workflowRulesKeys.customFields(full, customerid),
    queryFn: async () => {
      if (!customerid) return null;
      const response = await workflowRulesService.getCustomFields({ full, customerid });
      return response;
    },
    staleTime: 15 * 60 * 1000, // 15 minutes
    enabled: !!customerid,
    refetchOnWindowFocus: false
  });
};