import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, MenuItem, Tooltip, Typography, Menu, Badge, Chip, Divider } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import useModals from '@/hooks/useModals';
// import { UPDATE_MODALS } from '@/contexts/reducers/modals.reducer';
// import ConfirmModal from '../../ConfirmModal/ConfirmModal.component';
import { Stack } from '@utilisourcepackagelibdev/utilisourcepackagelib';

interface IDrawerHeaderProps {
    close?: (shouldClear?: boolean) => void;
    date?: string;
    title?: string;
    subTitle?: string;
    subTitle2?: string;
    resetForm?: () => void;
    editRow?: boolean;
    table?: any;
}

const DrawerHeader = (props: IDrawerHeaderProps) => {
    const { close, date, resetForm, editRow, table } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        props.close(true);
        if (resetForm) {
            resetForm();
        }
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const openConfirmation = () => {
        setAnchorEl(null);
        setOpen(false);

        const rows = table.getPrePaginationRowModel().rows;
        const nameCounts = rows.reduce((acc: any, row: any) => {
            const name = row.original.priceScheduleName || row.original.contractName || row.original.customerName;
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});
        console.log(nameCounts);

    };

    const handleDelete = () => {
        handleClose();
    };

    return (
        <Box
            sx={{
                height: '34px',
            }}
        >
            <Box
                alignItems="center"
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                {date ? (
                    <Typography variant="body1" mr={12}>
                        {date}
                    </Typography>
                ) : (
                    <Stack direction="column">
                        <Typography variant="h4" mr={12}>
                            {props.title}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            {props.subTitle}
                        </Typography>
                        {props.subTitle2 && (
                            <Typography variant="subtitle2" color="text.secondary">
                                {props.subTitle2}
                            </Typography>
                        )}
                    </Stack>
                )}

                <IconButton
                    id="closeBtn"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                    }}
                    size="medium"
                    aria-label="edit"
                    onClick={handleClose}
                >
                    <Tooltip title="Close">
                        <CloseIcon fontSize="large" />
                    </Tooltip>
                </IconButton>
            </Box>
        </Box>
    );
};

export default DrawerHeader;
