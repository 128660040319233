import { createContext, useEffect, useState } from 'react';

import { THEMES } from '../constants/theme/themes.constant';

const initialState = {
  theme: THEMES.INDIGO,
  setTheme: (theme: string) => {},
};
const ThemeContext = createContext(initialState);

type ThemeProviderProps = {
  children: React.ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, _setTheme] = useState<string>(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (newTheme: string) => {
    localStorage.setItem('theme', JSON.stringify(newTheme));
    _setTheme(newTheme);
  };

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}

export { ThemeProvider, ThemeContext };
