import TicketList from '@/app/pages/TicketList/TicketList.page'
import { createPermissionsQueryOptions, createRoleIdQueryOptions } from '@/hooks/fetches/usePermission.service'
import { createMergeTemplatePermissionsOptions } from '@/hooks/fetches/useTemplate.service'
import DashboardLayout from '@/layouts/DashboardLayout'
import { useAuth, useUser } from '@/lib/auth'
import InactivityChecker from '@/lib/InactivityChecker.component'
import { checkResourceAccess, handleAccess } from '@/permissions/Permissions'
import { createFileRoute, Outlet, redirect, useRouter } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useEffect } from 'react'
import { z } from 'zod'

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location, }) => {
    context.queryClient.cancelQueries()
    const isAuth = context.auth.checkAuthStatus();
    console.warn('isAuth: ', isAuth)
    if (!isAuth) {
      console.log('Not authenticated, redirecting to login')
      context.auth.setCurrentPath(location.href)
      console.log(location.href)
      const redirectPath = `${location.href}`
      console.log('Redirect path:', redirectPath) // Debug log
      const encodedPath = encodeURIComponent(redirectPath)
      console.log('Encoded path:', encodedPath)
      throw redirect({
        to: '/login',
        search: {
          redirect: encodedPath
        },
      })
    }

    const permissions = context.auth.permissions

    if (permissions) {

      console.log(location)

      function sanitizePathname(pathname: string): string {
        const segments = pathname.split('/')
        if (segments.length > 1) {
          return '/' + segments[1]
        }
        return '/'
      }

      const path = sanitizePathname(location.pathname)
      console.log(path)

      const hasAccess = await checkResourceAccess({
        queryClient: context.queryClient,
        pathname: path,
        permissions
      })


      console.warn('hasAccess', hasAccess)

      if (!hasAccess) {
        console.log('toss em out!')
        throw redirect({
          to: '/',
          replace: true,
          search: { drawer: undefined }
        })
      }
    }
  },
  loader: async ({ context }) => {
    const isAuth = context.auth.checkAuthStatus();

    const permissions = context.auth.permissions
    console.warn('permissions', permissions)

    if (!isAuth && !permissions) {
      console.log('fire ensurequeryData')
      await Promise.all([
        context?.queryClient?.prefetchQuery(createPermissionsQueryOptions()),
        context?.queryClient?.prefetchQuery(createMergeTemplatePermissionsOptions()),
        context?.queryClient?.prefetchQuery(createRoleIdQueryOptions())
      ])
    }
  },
  component: AuthLayout,
})

function AuthLayout() {
  const router = useRouter()
  const navigate = Route.useNavigate()
  const auth = useAuth()
  // const user = useUser({})
  // const { redirect } = Route.useSearch()

  useEffect(() => {
    if (!auth.isAuthenticated) {
      const redirectPath = `${router.state.location.href}`
      const encodedPath = encodeURIComponent(redirectPath)
      navigate({
        to: '/login',
        search: {
          redirect: encodedPath
        },
        replace: true
      });
    }
  }, [auth.isAuthenticated, router]);

  // useEffect(() => {
  //   if (!user) {
  //     console.log('user')
  //   }
  // }, [user])

  return (
    <>
      <Outlet />
      {/* <InactivityChecker
        renderTimeLeft
        timeoutInMinutes={15}
      /> */}
      {/* <TanStackRouterDevtools /> */}
    </>
  )
}