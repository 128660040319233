import React, { lazy, Suspense } from 'react';
// import { ticketReducer, initialTicketState } from "./reducers/ticket.reducer";
// import { reportReducer, initialReportState } from "./reducers/report.reducer";
// import { TicketProvider } from "./ticket.context";
// import { ReportProvider } from "./report.context";
// import {
//   dataPreloadReducer,
//   initialDataPreloadState,
// } from "./reducers/dataPreload.reducer";
// import { DataPreloadProvider } from "./dataPreload.context";
import { ThemeProvider } from './ThemeContext';
import DayjsProvider from './DayJSProvider';
import createTheme from '@/constants/theme';
import useTheme from '@/hooks/useTheme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import useIndexedDB from '../services/database/useIndexedDB';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Close } from '@mui/icons-material';
import { custom } from 'zod';
import { customSerializer } from '@/services/database/customSerializer';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/queryClient';

// export const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       gcTime: 1000 * 60 * 60 * 24, // 24 hours
//     },
//   },
// });

const GlobalStateProvider = (props: any) => {
  const { theme } = useTheme();
  const IndexedDBAdapter = useIndexedDB();

  const ReactQueryDevtoolsProduction = lazy(async () =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
      default: d.ReactQueryDevtools,
    })),
  );

  const asyncStoragePersister = createAsyncStoragePersister({
    storage: IndexedDBAdapter,
    key: 'dropSource',
    throttleTime: 1000,
    serialize: customSerializer.serialize,
    deserialize: customSerializer.deserialize,
  });

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: asyncStoragePersister }}>
        {/* <DataPreloadProvider
          reducer={dataPreloadReducer}
          initialState={initialDataPreloadState}
        >
          <TicketProvider
            reducer={ticketReducer}
            initialState={initialTicketState}
          >
            <ReportProvider
              reducer={reportReducer}
              initialState={initialReportState}
            > */}
        <DayjsProvider>
          {/* <AuthProvider> */}
          {props.children}
          {/* </AuthProvider> */}
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </DayjsProvider>
        {/* </ReportProvider>
          </TicketProvider>
        </DataPreloadProvider> */}
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
          hideIconVariant={true}
          action={(key: any) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
              id={'notification'}
            >
              <Close />
            </IconButton>
          )}
          autoHideDuration={5000}
        />
      </PersistQueryClientProvider>
    </MuiThemeProvider>
  );
};

export default GlobalStateProvider;
