import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface IProps {
  children: any;
  button1?: any;
  button2?: any;
  button1Func?: any;
  button2Func?: any;
  title?: string;
  width?: string;
  height?: string;
  actionChildren?: any;
  sx?: any;
}

export default function CustomCard(IProps: IProps) {
  return (
    <Card style={{ maxWidth: IProps.width ? IProps.width : 345, height: IProps.height }} sx={IProps.sx}>
      <CardContent sx={{ paddingBottom: '16px !important' }}>
        {IProps.title ? (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {IProps.title}
          </Typography>
        ) : (
          ''
        )}
        {IProps.children}
      </CardContent>
      {IProps.button1 || IProps.button2 ? (
        <CardActions>
          {IProps.button1 ? (
            <Button size="small" onClick={IProps.button1Func}>
              {IProps.button1}
            </Button>
          ) : (
            ''
          )}
          {IProps.button2 ? (
            <Button size="small" onClick={IProps.button2Func}>
              {IProps.button2}
            </Button>
          ) : (
            ''
          )}
          {IProps.actionChildren}
        </CardActions>
      ) : (
        ''
      )}
    </Card>
  );
}
