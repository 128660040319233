import { queryOptions } from "@tanstack/react-query";
import TicketService from "../ticket.service";

// Query Key Factory
export const ticketKeys = {
  all: ['tickets'] as const,
  types: (customerId?: number) => [...ticketKeys.all, 'types', customerId] as const,
  statuses: (customerId?: number) => [...ticketKeys.all, 'statuses', customerId] as const
};

const ticketService = TicketService();
// Query Options Functions
export const createTicketTypesByCustomerOptions = ({ customerId }: { customerId?: number }) => {
  return queryOptions({
    queryKey: ticketKeys.types(customerId),
    queryFn: async () => {
      if (!customerId) return null;
      const response = await ticketService.getTicketTypesByCustomer({
        customerId: customerId,
      });
      return response.data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false
  });
};


export const createStatusesByCustomerOptions = ({ customerId }: { customerId?: number }) => {
  return queryOptions({
    queryKey: ticketKeys.statuses(customerId),
    queryFn: async () => {
      if (!customerId) return null;
      const response = await ticketService.getStatusesByCustomer({
        customerId: customerId,
      });
      return response.data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false
  });
};