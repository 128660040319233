import React, { useEffect, useMemo, useState } from 'react';
import useTicket from '@/hooks/fetches/useTicket.service';
import { Autocomplete, Theme, useMediaQuery } from '@mui/material';
import Grid from "@mui/material/Grid2"
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { AutoComplete, Button, Stack, TextField, } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import useTicketList from '@/contexts/stores/ticketList.store';
import { useNavigate, useSearch } from '@tanstack/react-router';
import useTicketTableStore from './useTicketTableStore';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { createStatusesByCustomerOptions, createTicketTypesByCustomerOptions } from '@/services/queries/statusTypeOptions';
import { useQuery } from '@tanstack/react-query';
import { custom } from 'zod';

interface CreateTicketFormSchema {
  customer: { id: number; label: string } | null;
  priority: { id: number; label: string } | null;
  type: { id: number; label: string } | null;
  status: { id: number; label: string, color: number } | null;
  description: string
}

const CreateTicketDrawer = () => {

  const navigate = useNavigate();
  const { drawer } = useSearch({ from: '/_auth/' });

  const { ticketListState, updateTicketList, } = useTicketList();
  const { ticketTableState, setCustomFilters } = useTicketTableStore()

  const [isOpen, setIsOpen] = useState(false)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const methods = useForm<CreateTicketFormSchema>({
    mode: 'all',
    defaultValues: {
      customer: null,
      priority: null,
      type: null,
      status: null,
      description: ''
    }
  });

  const customerWatch = useWatch({ control: methods.control, name: 'customer' });

  const {
    customers,
    priorities,
    agentsByCustomer,
    createTicketMutation
  } = useTicket(
    undefined,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters?.category?.label,
  );

  const ticketTypesByCustomer = useQuery(createTicketTypesByCustomerOptions({
    customerId: customerWatch?.id
  }));

  const statusesByCustomer = useQuery(createStatusesByCustomerOptions({
    customerId: customerWatch?.id
  }))

  const handleClose = () => {
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/', search: { drawer: undefined } });
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    navigate({ to: '/', search: { drawer: undefined } });
  };

  const handleSubmit = (formValues: any) => {
    console.log('hello world submit')
    console.log(formValues);

    const response = createTicketMutation.mutate({
      actionType: 'createTicket',
      payload: {
        customer: formValues.customer?.id,
        typeId: formValues.type?.id,
        priorityId: formValues.priority?.id,
        Title: formValues.description,
        ticketId: 0,
        statusId: formValues.status?.id
      },
    })
  }

  const getColor = (colorValue: number) => {
    const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
    return color;
  };

  const getBrightness = (color: any) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getTextColor = (backgroundColor: any) => {
    return getBrightness(backgroundColor) > 186 ? 'black' : 'white';
  };

  return (
    <DrawerLayout
      open={drawer === 'create'}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'25%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={'Create Ticket'}
        />
      }
    >
      <Grid
        container
        style={{
          height: '100%',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={'space-between'}
        flexWrap="nowrap"
        padding={4}
        overflow="auto"
      >
        <Grid size={{ xs: 12 }} marginTop={!isDesktop ? 2 : 0} height={'100%'} sx={{ overflow: 'auto', paddingBottom: '90px' }}>
          <FormProvider {...methods} >
            <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
              <Grid
                size={{ xs: 12, md: 12 }}
                display="flex"
                flexDirection="column"
                rowGap={4}
                paddingX={2}
                paddingY={1}
                sx={{ mt: 4 }}
              >
                <AutoComplete
                  id="customer"
                  options={
                    customers.isPending
                      ? []
                      : customers?.data?.map((customer: any) => ({
                        id: customer.CustomerEntId,
                        label: customer.EntName,
                      })) || []
                  }
                  control={methods.control}
                  loading={customers.isPending}
                  autocompleteProps={{
                    id: 'customer',
                    loadingText: 'Loading Customers...',
                  }}
                  multiple={false}
                  label="Customer"
                />
                <AutoComplete
                  id="type"
                  options={
                    ticketTypesByCustomer?.isPending
                      ? []
                      : ticketTypesByCustomer?.data?.map((data: any) => ({
                        id: data.TypeId,
                        label: data.Name,
                      })) || []
                  }
                  disabled={!customerWatch}
                  // loading={ticketTypesByCustomer?.isPending}
                  autocompleteProps={{
                    id: 'type',
                    loadingText: 'Loading Types...',
                  }}
                  multiple={false}
                  label="Type"
                  required
                />
                <Controller
                  name="status"
                  control={methods.control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field }) => {
                    const backgroundColor = field.value ? getColor(field.value.color) : 'white';
                    const textColor = field.value ? getTextColor(backgroundColor) : 'black';

                    return (
                      <Autocomplete
                        options={
                          statusesByCustomer?.isPending
                            ? []
                            : statusesByCustomer?.data?.map((data: any) => ({
                              id: data.StatusId,
                              label: data.Name,
                              color: data.Color
                            })) || []
                        }
                        getOptionLabel={(option: any) => option.label}
                        isOptionEqualToValue={(option, value) => {
                          return option?.id === value?.id
                        }}
                        onBlur={field.onBlur}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const { id, label, color } = newValue;
                            field.onChange({ id, label, color });
                          } else {
                            field.onChange(null);
                          }
                        }}
                        disabled={!customerWatch}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={field.name}
                            label="Status"
                            // size="small"
                            error={!!methods.formState.errors.status}
                            required
                            sx={{
                              '& .MuiOutlinedInput-input': {
                                backgroundColor: backgroundColor && `${backgroundColor} !important`,
                                color: textColor && `${textColor} !important`,
                                borderRadius: '4px'
                              },
                            }}
                            helperText={methods.formState.errors.status?.message}
                          />
                        )}
                      />
                    )
                  }}
                />

                <AutoComplete
                  id="priority"
                  options={
                    priorities?.isPending
                      ? []
                      : priorities?.data?.map((data: any) => ({
                        id: data.PriorityID,
                        label: data.Name,
                      })) || []
                  }
                  disabled={!customerWatch}
                  loading={priorities?.isPending}
                  autocompleteProps={{
                    id: 'priority',
                    loadingText: 'Loading Priorities...',
                  }}
                  multiple={false}
                  label="Priority"
                  required
                />
                <TextBox
                  name='description'
                  control={methods.control}
                  label="Description"
                  fullWidth
                  disabled={!customerWatch}
                />
                <Button id='sendit' type='submit' variant='contained' disabled={!customerWatch}>Create Ticket</Button>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid >
    </DrawerLayout >
  )
}

export default CreateTicketDrawer