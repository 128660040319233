import CustomFields from '@/app/pages/CustomFields/CustomFields.page'
import { env } from '@/config/env'
import DashboardLayout from '@/layouts/DashboardLayout'
import { useAuth } from '@/lib/auth'
import Grid from '@mui/material/Grid2'
import { createFileRoute } from '@tanstack/react-router'
import { Box, Typography, useWindowSize } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import { useEffect, useRef, useState } from 'react'


export interface PostMessageEvent {
  type: 'READY_FOR_AUTH' | 'AUTH_DATA' | 'AUTH_COMPLETE'
  jwt?: string
  publicKey?: string
  success?: boolean
  error?: string
}

interface IFramePageInterface {
  url: string
}

export function IFramePage({ url }: IFramePageInterface) {
  const auth = useAuth()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  // Get the target URL and origin from environment
  const targetURL = `${env.TARGET_APP_URL}${env.ROUTING_PREFIX}/${url}`
  const targetOrigin = env.TARGET_APP_URL

  // Handle iframe load event
  const handleIframeLoad = () => {
    setIsLoaded(true)
    // sendMessage({ type: 'READY_FOR_AUTH' })
  }

  // Helper function to send messages to the legacy app
  // const sendMessage = (message: PostMessageEvent) => {
  //   if (!iframeRef.current?.contentWindow) return
  //   iframeRef.current.contentWindow.postMessage(message, targetOrigin)
  // }

  // // Handle incoming messages from the legacy app
  // const handleMessage = (event: MessageEvent) => {
  //   // Verify origin
  //   if (event.origin !== targetOrigin) {
  //     console.warn('Invalid origin:', event.origin)
  //     return
  //   }

  //   const data = event.data as PostMessageEvent
  //   console.log('Received message:', data)

  //   switch (data.type) {
  //     case 'AUTH_DATA':
  //       handleAuthData(data)
  //       break
  //     case 'AUTH_COMPLETE':
  //       handleAuthComplete(data)
  //       break
  //     default:
  //       console.warn('Unknown message type:', data.type)
  //   }
  // }

  // // Handle AUTH_DATA message
  // const handleAuthData = (data: PostMessageEvent) => {
  //   if (!data.jwt || !data.publicKey) return

  //   try {
  //     localStorage.setItem('jwt', data.jwt)
  //     localStorage.setItem('public_key', data.publicKey)

  //     // Send success message back to legacy app
  //     sendMessage({
  //       type: 'AUTH_COMPLETE',
  //       success: true
  //     })

  //     // Update auth context
  //     auth.login()
  //   } catch (error) {
  //     console.error('Failed to store auth data:', error)
  //     sendMessage({
  //       type: 'AUTH_COMPLETE',
  //       success: false,
  //       error: 'Failed to store authentication data'
  //     })
  //   }
  // }

  // // Handle AUTH_COMPLETE message
  // const handleAuthComplete = (data: PostMessageEvent) => {
  //   if (data.success) {
  //     console.log('Authentication completed successfully')
  //   } else {
  //     console.error('Authentication failed:', data.error)
  //   }
  // }

  // // Set up message listener
  // useEffect(() => {
  //   window.addEventListener('message', handleMessage)
  //   return () => {
  //     window.removeEventListener('message', handleMessage)
  //   }
  // }, [])

  // Get window dimensions for iframe sizing
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <DashboardLayout>
      <Grid display="flex" flexDirection="row" flexWrap="wrap" rowGap={4} sx={{ height: '100%' }}>
        {!isLoaded && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          >
            <Typography variant="h6">Loading...</Typography>
          </Box>
        )}
        <iframe
          ref={iframeRef}
          src={targetURL}
          title="Legacy Application"
          width={windowDimensions.width - 264}
          height={windowDimensions.height - 90}
          frameBorder={0}
          style={{
            border: 'none',
            visibility: isLoaded ? 'visible' : 'hidden',
            padding: 'none',
            margin: 'none',
          }}
          sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-popups allow-popups-to-escape-sandbox allow-presentation allow-modals"
          onLoad={handleIframeLoad}
        />
      </Grid>

    </DashboardLayout>
  )
}