import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import WorkflowRules from '@/app/pages/WorkflowRules/WorkflowRules.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { ReactFlow, ReactFlowProvider, useReactFlow } from '@xyflow/react'

export const Route = createFileRoute('/_auth/workflow-rules')({
  // component: () => <IFramePage url='workflow-rules.html' />,
  component: () => <WorkflowRulesLayout />,
})

const WorkflowRulesLayout = () => {
  return (
    <DashboardLayout title="WorkflowRules Editor">
      <FlowWithProvider>
        <WorkflowRules />
        <Outlet />
      </FlowWithProvider>
    </DashboardLayout>
  )
}

function Flow(props: any) {
  // you can access the internal state here
  const reactFlowInstance = useReactFlow();

  return <ReactFlow {...props} />;
}

// wrapping with ReactFlowProvider is done outside of the component
function FlowWithProvider(props: any) {
  return (
    <ReactFlowProvider>
      {props.children}
      {/* <Flow {...props} /> */}
    </ReactFlowProvider>
  );
}