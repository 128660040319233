import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import Templates from '@/app/pages/Templates/Templates.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/templates')({
  component: () => <IFramePage url='notification-templates.html' />,
})

const TemplateLayout = () => {
  return (
    <DashboardLayout title="Template Editor">
      <Templates />
    </DashboardLayout>
  )
}
