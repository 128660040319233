import BasicDrawer from '@/components/Organisms/BasicDrawer/BasicDrawer.component'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/status-editor/$statusId')({
  component: () => <BasicDrawer />,
  params: {
    parse: (params) => ({
      statusId: z.number().int().parse(Number(params.statusId)),
    }),
    stringify: ({ statusId }) => ({ statusId: `${statusId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        step: z.number().min(0).max(1).default(0), // Based on your steps array length
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
})
