import { BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';

const AttachmentService = () => {

  const getAttachmentByIdForDownload = async ({
    fid,
    onSuccess,
    onError,
  }: {
    fid: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/attachments/download',
      params: {
        fid: fid,
      },
      responseType: 'blob',
      onSuccess,
      onError,
    });
  };

  const getAttachmentByIdForView = async ({
    fid,
    onSuccess,
    onError,
  }: {
    fid: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/attachments/view',
      params: {
        fid: fid,
      },
      responseType: 'blob',
      onSuccess,
      onError,
    });
  };

  const postAttachments = async ({
    data,
    onSuccess,
    onError,
  }: {
    data: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    // console.log('data: ', data);
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/attachments',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onSuccess,
      onError,
      data,
    });
  };

  const deleteAttachmentByFid = async ({
    fileid,
    ticketid,
    onSuccess,
    onError,
  }: {
    fileid: number;
    ticketid: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'delete',
      url: BASE_UAPI_URL + `/attachments?fileid=${fileid}&ticketid=${ticketid}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onSuccess,
      onError,
    });
  };

  return {
    getAttachmentByIdForDownload,
    getAttachmentByIdForView,
    postAttachments,
    deleteAttachmentByFid
  };
};

export default AttachmentService;
