import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import SettingsService from '@/services/settings.service';

type ActionType = 'updateTicketTableColumns';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useTicketTableColumnMutation = (): {
  updateTicketTableColumns: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingTicketTableColumns: Record<string, boolean>;
  setIsUpdatingTicketTableColumns: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdatingTicketTableColumns, setIsUpdatingTicketTableColumns] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const settingsService = SettingsService();
    const action: { [key in ActionType]?: Function } = {
      updateTicketTableColumns: settingsService.postSettings,
      // addAttachment: ticketService.postNewAttachment
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateTicketTableColumns } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType, ...newSettings }) => {
      setIsUpdatingTicketTableColumns({ ...isUpdatingTicketTableColumns, [actionType]: true });

      // const formData = newAttachment.data;
      // const extractedData: Record<string, any> = {};

      // for (const [key, value] of formData.entries()) {
      //   extractedData[key] = value;
      // }
      // const noteMetadata = JSON.parse(extractedData.noteMetadata || "{}");

      // const preparedData = {
      //   AttachmentTypeId: noteMetadata.attachmenttypeid,
      //   UpdatedByName: noteMetadata.username,
      //   Fid: 0,
      //   UpdatedAt: dayjs(),
      //   Filename: extractedData.attachment.name,
      // }

      // queryClient.setQueryData(['attachments-by-id', noteMetadata.ticketid], (old: any) => {
      //   if (old) return [...old, preparedData]
      //   else return [preparedData]
      // })
    },
    onSuccess: (data: any, variables: any) => {
      // console.log('Mutation successful');
      // console.log('Attachment variables.data:', variables.data);
      // console.log('data.data.Success:', data.data.Success);
      // queryClient.invalidateQueries({ queryKey: ['all-tickets'] });
      if (data?.data?.Success === true && variables.actionType === 'updateTicketTableColumns') { //todo this isnt right yet
        // console.log('successful update');
        enqueueSnackbar('Column Order saved successfully', { variant: 'success' });
      } else if (data.data.Success === false) {
        enqueueSnackbar('Column Order save failed', { variant: 'error' });
      }
      // if (variables.actionType === 'deleteTicket' && data.ticketId) {
      //     queryClient.removeQueries({ queryKey: ['ticket', data.ticketId] })
      //     enqueueSnackbar(`Ticket #${data.ticketId} deleted successfully`, { variant: 'success' })
      //     queryClient.invalidateQueries({ queryKey: ['archived-tickets'], exact: true });
      // }
    },
    onError: (error: any, variables: any) => {
      // console.error('Error during mutation:', error);
      // console.warn('ERROR', variables);
      if (variables.actionType === 'updateTicketTableColumns') {
        enqueueSnackbar('Column Order save failed', { variant: 'error' });
      }

    },
    // onSettled: ({ actionType }) => {
    //   setIsUpdating({ ...isUpdating, [actionType]: false });
    // },
    onSettled: () => {
      // queryClient.invalidateQueries();
    },
  });

  return { updateTicketTableColumns, isUpdatingTicketTableColumns, setIsUpdatingTicketTableColumns };
};

export default useTicketTableColumnMutation;
