import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';

type ActionType = 'deleteTicket';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useTicketDelete = (): {
  updateTicketDelete: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingTicketDelete: Record<string, boolean>;
  setIsUpdatingTicketDelete: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdatingTicketDelete, setIsUpdatingTicketDelete] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const ticketService = TicketService();
    const action: { [key in ActionType]?: Function } = {
      deleteTicket: ticketService.deleteTicketById,
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateTicketDelete } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdatingTicketDelete({ ...isUpdatingTicketDelete, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      if (typeof data?.data === 'string' && data?.data?.includes('deleted ticketID') && variables.actionType === 'deleteTicket') {
        enqueueSnackbar('Ticket Deleted successfully', { variant: 'success' });
      } else if (data.data.Success === false) {
        enqueueSnackbar('Ticket Delete failed', { variant: 'error' });
      }
    },
    onError: (error: any, variables: any) => {

      if (variables.actionType === 'deleteTicket') {
        enqueueSnackbar('Ticket Delete failed', { variant: 'error' });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return { updateTicketDelete, isUpdatingTicketDelete, setIsUpdatingTicketDelete };
};

export default useTicketDelete;
