import useTicket from '@/hooks/fetches/useTicket.service'
import { defaultValues } from '@/interfaces/schemas/ticketListFilter.schema'
import { CircularProgress, Container, FormControl, IconButton, InputLabel, ListSubheader, MenuItem, Paper, Select, TextField } from '@mui/material'
import Grid from "@mui/material/Grid2"
import { Box, Button, Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import { Plus, X } from 'lucide-react'
import React, { useCallback, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { should } from 'vitest'
import { CFTypeIdDetail } from '../WorkflowRules/useWorkflowRuleTable'
import { IcfTDateCasesArray, IcfTDropdownCasesArray, IcfTIntegerCasesArray, IcfTStringCasesArray, IcfTToggleCasesArray, turnEqualityValueToDisplayValue, turnEqualityValueToNumber, turnNumberToEqualityValue, turnOldNumberToEqualityValue } from '@/constants/filter/filter.constant'
import CheckboxElement from '@/components/Molecules/Checkbox/CheckboxElement.component'
import { Condition, WorkflowRule } from './WorkflowRuleDrawer'

const WorkflowRuleStep2 = () => {

  const methods = useFormContext()
  const { watch, control, setValue } = methods

  const customerWatch = useWatch({ control, name: 'customer' })

  const { baseFields, customFieldsByCustomer } = useTicket(undefined, customerWatch?.id)

  const { fields, append, remove, } = useFieldArray({
    control: control,
    name: 'any_conditions',
    shouldUnregister: false
  });

  const handleAddCondition = () => {
    append({
      condition: '',
      equality: '',
      equalityNumber: 0,
      value: '',
      conditionObj: {},
      type: '',
      allAny: ''
    });
  };

  // const handleConditionOnChange = (item: any, index: number, selectedValue: string) => {
  // let conditionObject;

  // const baseField = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  // if (baseField) {
  //   conditionObject = baseField;
  //   setValue(`any_conditions.${index}.type`, "baseField");

  // } else {
  //   const customField = customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);
  //   if (customField) {
  //     conditionObject = customField;
  //     setValue(`any_conditions.${index}.type`, "customField");
  //   }
  // }
  // // console.log(conditionObject)
  // setValue(`any_conditions.${index}.conditionObj`, conditionObject);
  // //! sanitize
  // setValue(`any_conditions.${index}.value`, "");

  //   const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  //     || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

  //   if (conditionObject) {
  //     setValue(`any_conditions.${index}`, {
  //       ...watch(`any_conditions.${index}`),
  //       condition: selectedValue,
  //       type: baseFields?.data.includes(conditionObject) ? 'baseField' : 'customField',
  //       conditionObj: conditionObject,
  //       value: "",
  //     }, { shouldValidate: true });
  //   }
  // };

  const handleConditionOnChange = useCallback((item: any, index: number, selectedValue: any) => {
    const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
      || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

    if (conditionObject) {
      const updates = {
        [`any_conditions.${index}.condition`]: selectedValue,
        [`any_conditions.${index}.type`]: baseFields?.data.includes(conditionObject) ? "baseField" : "customField",
        [`any_conditions.${index}.conditionObj`]: conditionObject,
        [`any_conditions.${index}.value`]: ""
      };

      Object.entries(updates).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
    }
  }, [baseFields?.data, customFieldsByCustomer?.data, setValue]);

  const handleEqualityOnChange = (item: any, index: number, selectedValue: any) => {
    // console.log(selectedValue)
    if (selectedValue === "Is Set" || selectedValue === "Is Not Set" || selectedValue === "Today") {
      setValue(`any_conditions.${index}.value`, "");
    }
    setValue(`any_conditions.${index}.equalityNumber`, turnEqualityValueToNumber(selectedValue));
  }

  const ValueController = React.memo(({ condition, index, children, rules }: any) => (
    <Controller
      name={`any_conditions.${index}.value`}
      control={control}
      rules={{ required: 'Value is required', ...rules }}
      render={({ field }) => children(field, index)}
    />
  ));

  const renderDropdownType = (condition: any, index: number) => (
    <>
      <ValueController
        condition={condition}
        index={index}
      >
        {(field: any, index: number) => (
          <>
            <InputLabel id="condition">Value</InputLabel>
            <Select
              size="small"
              sx={{ width: '100%' }}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              {...field}
            >
              {condition?.Type.OptLabels.map((option: any, index: number) => {
                return (
                  <MenuItem key={index} value={index}>{option}</MenuItem>
                )
              })
              }
            </Select>
          </>
        )}
      </ValueController>
      {/* <InputLabel id="condition">Value</InputLabel>

        <Select
            name={`conditions.${index}.value`}
            control={control}
            options={condition?.Type.OptLabels.map((option: any, index: number) => {
                return { label: option, id: option }
            })}
            objectOnChange
            key={index + 'value'}
            size="small"
            // label='Value'
            required
            fullWidth
            onChange={(value: any) => {
                console.log(value)
                setValue(`conditions.${index}.value`, value)
            }}
        /> */}
    </>

  );

  const renderDateType = (condition: any, index: number) => (
    <ValueController
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (
        <>
          {condition?.Type.IncludeTime === true ? <DateTime time={true} {...field} /> : <DateTime time={false} {...field} />}
        </>
      )}
    </ValueController>
  );

  const renderStringType = (condition: any, index: number) => (
    <ValueController
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (
        <>
          <InputLabel id="value">Value</InputLabel>
          <TextField placeholder="Type a Value" size="small" sx={{ width: '100%' }} {...field}></TextField>
        </>
      )}
    </ValueController>
  )

  const renderIntegerType = (condition: any, index: number) => (
    <ValueController
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (

        <>
          <InputLabel id="value">Value</InputLabel>
          <TextField type="number" placeholder="Type a Value" size="small" sx={{ width: '100%' }} max={condition?.Type.Maximum} min={condition?.Type.Minimum} step={condition?.Type.Step} {...field}></TextField>
        </>
      )}
    </ValueController>
  );

  const renderToggleType = (condition: any, index: number) => (
    <>
      <InputLabel id="value">Value</InputLabel>
      <CheckboxElement
        name={`any_conditions.${index}.value`}
        control={control}
        size="small"
        // label="Value"
        required
        // fullWidth
        onChange={(value: any) => {
          console.log(value)
          setValue(`any_conditions.${index}.value`, value.target.checked)
        }}
      />
    </>
  );

  const renderEqualityOptions = (condition: any, index: number) => {
    console.log(condition)
    if (condition && condition?.TypeId && CFTypeIdDetail(condition?.TypeId).ClassBinding) {
      switch (CFTypeIdDetail(condition.TypeId).ClassBinding) {
        case 'cfTDropdown': return IcfTDropdownCasesArray;
        case 'cfTDate': return IcfTDateCasesArray;
        case 'cfTString': return IcfTStringCasesArray;
        case 'cfTInteger': return IcfTIntegerCasesArray;
        case 'cfTToggle': return IcfTToggleCasesArray;
        default: return []
      }
    }
  };

  const renderDynamicField = React.useCallback((selectedObject: any, index: number) => {
    if (!selectedObject?.TypeId) return null;

    const binding = CFTypeIdDetail(selectedObject.TypeId).ClassBinding;
    const fieldMap = {
      'cfTDropdown': () => renderDropdownType(selectedObject, index),
      'cfTDate': () => renderDateType(selectedObject, index),
      'cfTString': () => renderStringType(selectedObject, index),
      'cfTInteger': () => renderIntegerType(selectedObject, index),
      'cfTToggle': () => renderToggleType(selectedObject, index)
    };

    // return fieldMap[binding]?.() ?? <div>default</div>;
    return fieldMap[binding as keyof typeof fieldMap]?.() ?? <div>default</div>;
  }, []);

  const ConditionRow = React.memo(({ item, index }: { item: Condition; index: number }) => {
    const { watch, control } = useFormContext<WorkflowRule>();
    return (
      <Paper elevation={0} sx={{ p: 2, mb: 4, border: '1px solid #e0e0e0', width: '90%' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid size={6}>
            <Controller
              name={`any_conditions.${index}.condition`}
              control={control}
              rules={{ required: 'Condition is required' }}
              render={({ field }) => (
                <>
                  <InputLabel id="condition">Condition</InputLabel>
                  <Select
                    {...field}
                    size="small"
                    onChange={(e) => {
                      field.onChange(e); // default RHF onChange
                      handleConditionOnChange(item, index, e.target.value); // custom handler
                    }}
                    placeholder={"Select a Condition"}
                    style={{ width: '100%' }}
                    endAdornment={
                      (baseFields.isPending || customFieldsByCustomer.isPending) ? (
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            right: 25,
                            color: 'grey.500'
                          }}
                        />
                      ) : null
                    }
                  >
                    <ListSubheader>Base Fields</ListSubheader>
                    {baseFields?.data && baseFields?.data.map((item2: any) => {
                      return (
                        <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                      )
                    })}
                    <ListSubheader>Custom Fields</ListSubheader>
                    {customFieldsByCustomer?.data && customFieldsByCustomer?.data.map((item2: any) => {
                      return (
                        <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                      )
                    })}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Grid size={5}>
            {watch(`any_conditions.${index}.condition`) && (
              <Controller
                name={`any_conditions.${index}.equality`}
                control={control}
                rules={{ required: 'Equality is required' }}
                render={({ field }) => (
                  <>
                    <InputLabel id="condition">Equality</InputLabel>
                    <Select
                      {...field}
                      size="small"
                      onChange={(e) => {
                        field.onChange(e); // default RHF onChange
                        handleEqualityOnChange(item, index, e.target.value); // custom handler
                      }}
                      placeholder={"Equals..."}
                      style={{ width: '100%' }}
                    >
                      {renderEqualityOptions(watch(`any_conditions.${index}.conditionObj`), index)?.map((item: any) => {
                        return (
                          <MenuItem value={item.name}>{item.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </>
                )}
              />
            )}

          </Grid>
          <Grid size={1}>
            <IconButton size="small" color="error" onClick={() => remove(index)}>
              <X size={20} />
            </IconButton>
          </Grid>
          <Grid size={12}>
            <Box sx={{ width: '100%' }}>

              {watch(`any_conditions.${index}.condition`) !== "" && watch(`any_conditions.${index}.equality`) !== "Is Set" && watch(`any_conditions.${index}.equality`) !== "Is Not Set" && watch(`any_conditions.${index}.equality`) !== "Today" && (
                renderDynamicField(watch(`any_conditions.${index}.conditionObj`), index)
              )}
            </Box>
          </Grid>

        </Grid>
      </Paper>
    )
  });

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant='button' color="text.primary" sx={{ mt: 4 }}>
        Select Conditions
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Grid container>

        <Grid size={{ xs: 12, md: 6 }}>

          {/* {match Any} */}
          <Box sx={{ height: '100%' }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Match Any Conditions
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Any conditions in this column must be true (AND)
              </Typography>
            </Box>

            <Box >
              {fields.map((item, index) => (
                <ConditionRow item={item} index={index} />
              ))}
            </Box>
            <Button
              id='add-condition-btn-any'
              variant="outlined"
              startIcon={<Plus size={20} />}
              onClick={handleAddCondition}
              sx={{ mt: 2 }}
            >
              Add Match Any Condition
            </Button>
            <Button
              id='add-condition-btn-any'
              variant="outlined"
              startIcon={<Plus size={20} />}
              type='submit'
              sx={{ mt: 2 }}
            >
              submit
            </Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          {/* {match All} */}
          {/* <Box sx={{ height: '100%' }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Match All Conditions
              </Typography>
              <Typography variant="body2" color="text.secondary">
                All conditions in this column must be true (AND)
              </Typography>
            </Box>

            <Box sx={{ minHeight: '200px' }}>

            </Box>

            <Button
              id='add-condition-btn-all'
              variant="outlined"
              startIcon={<Plus size={20} />}
              sx={{ mt: 2 }}
            >
              Add Match All Condition
            </Button>
          </Box> */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default WorkflowRuleStep2