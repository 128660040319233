import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Zap } from 'lucide-react';

export default function TriggerNode({ data, isConnectable }: any) {
  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent sx={{ pb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Zap style={{ width: 20, height: 20, color: 'primary.main' }} />
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            Trigger
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {data.type}
        </Typography>

        {data.config?.description && (
          <Typography variant="body2" sx={{ color: 'text.disabled', mt: 1 }}>
            {data.config.description}
          </Typography>
        )}
      </CardContent>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </Card>
  );
}