import ReleaseNotes from '@/app/pages/ReleaseNotes/ReleaseNotes.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/release-notes')({
  component: () => <ReleaseNotesLayout />,
})

const ReleaseNotesLayout = () => {
  return (
    <DashboardLayout>
      <ReleaseNotes />
    </DashboardLayout>
  )
}
