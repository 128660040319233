import { AutoComplete, Button, TextField } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Grid2 as Grid, Typography, Box, Autocomplete, IconButton, Tooltip } from '@mui/material';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import useTicketTableStore from '../TicketList/useTicketTableStore';
import useTicketList from '@/contexts/stores/ticketList.store';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useTicket from '@/hooks/fetches/useTicket.service';
import Permissions from '@/permissions/Permissions';
import { useParams } from '@tanstack/react-router';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from "react-hook-form";
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import dayjs from 'dayjs';

const BaseInformation = () => {

  const { ticketTableState } = useTicketTableStore()
  const methods = useFormContext();
  const { control } = methods;

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const ticketId2 = Number(ticketId)

  const {
    ticketById,
    statusesByCustomer,
    ticketTypesByCustomer,
    priorities,
    agentsByCustomer
  } = useTicket(
    ticketId2,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters?.category?.label
  );

  const createPriorityObj = (priorityString: string) => {
    if (priorityString) {
      switch (priorityString) {
        case "low":
          return {
            id: 0,
            label: "Low"
          }
        case "normal":
          return {
            id: 1,
            label: "Normal"
          }
        case "high":
          return {
            id: 2,
            label: "High"
          }
        case "critical":
          return {
            id: 3,
            label: "Critical"
          }
      }
    }
  };

  const getColor = (colorValue: number) => {
    const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
    return color;
  };

  const getBrightness = (color: any) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getTextColor = (backgroundColor: any) => {
    return getBrightness(backgroundColor) > 186 ? 'black' : 'white';
  };

  const handleAssignToMeClick = () => {
    const signedInUser = agentsByCustomer?.data?.ContextUser?.AgentId;
    const signedInUserObj = agentsByCustomer?.data?.VisibleAgents.find((agent: { AgentId: number }) => agent?.AgentId === signedInUser);
    if (signedInUserObj) {
      methods.setValue('assignee', { id: signedInUserObj?.AgentId, label: signedInUserObj?.Name });
    } else {
      enqueueSnackbar('Cannot assign ticket to you', { variant: 'error' });
    }
  }

  const handleLatLonCopy = () => {
    navigator.clipboard.writeText(ticketById?.data?.Latitude + " " + ticketById?.data?.Longitude).then(() => {
      enqueueSnackbar('Coordinates copied to clipboard', { variant: 'success' });
    })
  }

  useEffect(() => {
    if (ticketById.data && ticketId2) {
      const { data } = ticketById
      methods.reset({
        ...methods.getValues(),
        description: data?.Ticket?.Title || '',
        type: data?.Type
          ? {
            id: data?.Type?.TypeId,
            label: data?.Type?.Name,
          }
          : null,
        priority: createPriorityObj(data?.Ticket?.Priority) || null,
        status: data?.Status
          ? {
            StatusID: data?.Status?.StatusID,
            Name: data?.Status?.Name,
            Color: data?.Status?.Color
          }
          : null,
        dueDate: (data?.Ticket?.DueDate !== "0001-01-01T00:00:00Z") ? dayjs(data?.Ticket?.DueDate) : null,
        assignee: data?.Assignee?.AgentId
          ? {
            id: data?.Assignee?.AgentId,
            label: data?.Assignee?.Name,
          }
          : null,
        clientName: data?.Ticket?.ClientName || '',
        clientEmail: data?.Ticket?.ClientEmail || '',
        clientPhone: data?.Ticket?.ClientPhone || '',
        clientAddress: data?.Ticket?.ClientAddress || '',
      });
    }
  }, [ticketById.data, ticketId2]);

  return (
    <Grid size={{ xs: 12 }}>
      <Grid container spacing={3} display={"flex"} flexWrap={"wrap"}>
        <Grid size={12}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <TextBox
              name="description"
              control={control}
              label="Description"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <AutoComplete
              id="type"
              options={
                ticketTypesByCustomer?.isPending
                  ? []
                  : ticketTypesByCustomer?.data?.map((data: any) => ({
                    id: data.TypeId,
                    label: data.Name,
                  })) || []
              }
              loading={ticketTypesByCustomer?.isPending}
              autocompleteProps={{
                id: 'type',
                loadingText: 'Loading Types...',
                size: 'small'
              }}
              multiple={false}
              label="Type"
              required
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <AutoComplete
              id="priority"
              options={
                priorities?.isPending
                  ? []
                  : priorities?.data?.map((data: any) => ({
                    id: data.PriorityID,
                    label: data.Name,
                  })) || []
              }
              loading={priorities?.isPending}
              autocompleteProps={{
                id: 'priority',
                loadingText: 'Loading Priorities...',
                size: 'small'
              }}
              multiple={false}
              label="Priority"
              required
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                const backgroundColor = field.value ? getColor(field.value.Color) : 'white';
                const textColor = field.value ? getTextColor(backgroundColor) : 'black';
                return (
                  <Permissions target={'canChangeStatus'} disableMode>
                    <Autocomplete
                      options={statusesByCustomer && statusesByCustomer?.data || []}
                      getOptionLabel={(option: any) => option.Name}
                      isOptionEqualToValue={(option, value) => {
                        return option?.StatusID === value?.StatusID
                      }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          const { StatusID, Name, Color } = newValue;
                          field.onChange({ StatusID, Name, Color });
                        } else {
                          field.onChange(null);
                        }
                      }}
                      value={field.value ? { StatusID: field.value.StatusID, Name: field.value.Name, Color: field.value.Color } : null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={field.name}
                          label="Status"
                          size="small"
                          error={!!methods.formState.errors.status}
                          required
                          sx={{
                            '& .MuiOutlinedInput-input': {
                              backgroundColor: backgroundColor && `${backgroundColor} !important`,
                              color: textColor && `${textColor} !important`,
                              borderRadius: '4px'
                            },
                          }}
                        />
                      )}
                    />
                  </Permissions>
                )
              }}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Due Date"
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      InputProps: {
                        sx: { color: field.value && field.value.isBefore(dayjs()) ? 'red' : 'black' },
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 9 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Permissions target={'canChangeAssignee'} disableMode>
              <AutoComplete
                id="assignee"
                options={
                  agentsByCustomer?.isPending
                    ? []
                    : agentsByCustomer?.data?.VisibleAgents?.map((data: any) => ({
                      id: data.AgentId,
                      label: data.Name,
                    })) || []
                }
                loading={agentsByCustomer?.isPending}
                autocompleteProps={{
                  id: 'assignee',
                  loadingText: 'Loading Assignees...',
                  size: 'small'
                }}
                multiple={false}
                label="Assignee"
              />
            </Permissions>
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Button id='assign-to-me' variant='outlined' onClick={handleAssignToMeClick}>Assign To Me</Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <TextBox
              name="clientName"
              control={control}
              label="Client Name"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <TextBox
              name="clientEmail"
              control={control}
              label="Client Email"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <TextBox
              name="clientPhone"
              control={control}
              label="Client Phone"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={12}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
            <Typography fontSize={11} color="gray">
              Lat: {ticketById.data?.Latitude}, Lon: {ticketById.data?.Longitude}
              <Tooltip title="Copy to clipboard" placement='top'>
                <IconButton size="small" sx={{ p: 0, ml: 1 }} onClick={handleLatLonCopy} >
                  <ContentCopyIcon sx={{ fontSize: '0.8rem' }} />
                </IconButton>
              </Tooltip>
            </Typography>
            <TextBox
              name="clientAddress"
              control={control}
              label="Client Address"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid >
    </Grid >
  )
}

export default BaseInformation
