import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import Type from '@/app/pages/Type/Type.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/type-editor')({
  component: () => <IFramePage url='type-crud2.html' />,
})

const TypeLayout = () => {
  return (
    <DashboardLayout title="Ticket Type Editor">
      <Type />
    </DashboardLayout>
  )
}
