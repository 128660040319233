import { LoginCredentials, useAuth } from '@/lib/auth';
import { createFileRoute, redirect, useNavigate, useSearch } from '@tanstack/react-router'
import { Box, Button, Typography, useWindowSize } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { Alert, Dialog, DialogProps, useTheme } from '@mui/material';
import { env } from '@/config/env';
import { z } from 'zod';
import { LoadingScreen } from '@/app/pages/Loading/Loading';

// Types for PostMessage events
interface AuthDataMessage {
    type: 'AUTH_DATA'
    jwt: string
    publicKey: string
}

interface AuthCompleteMessage {
    type: 'AUTH_COMPLETE'
    success: boolean
    error?: string
}

interface ReadyForAuthMessage {
    type: 'READY_FOR_AUTH'
}

type PostMessageEvent = AuthDataMessage | AuthCompleteMessage | ReadyForAuthMessage

// Create a custom Dialog component that can be animated
const AnimatedDialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
    return <Dialog {...props} ref={ref} />;
});

export const Route = createFileRoute('/login')({
    // validateSearch: (search: Record<string, unknown>) => {
    //     return {
    //         redirect: search.redirect as string,
    //     }
    // },
    validateSearch: (search) => {
        return z
            .object({
                redirect: z.string().optional()
            })
            .parse(search)
    },
    beforeLoad: ({ context, search }) => {
        // Redirect already authenticated users
        if (context.auth.checkAuthStatus()) {
            console.log('Already authenticated, redirecting to home')
            console.log(search)
            console.log(search.redirect)
            throw redirect({
                to: search.redirect || '/',
                replace: true
            })
        }
    },
    component: () => <LoginPage />,
})

export function LoginPage() {
    const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(true);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const { redirect } = Route.useSearch()
    const auth = useAuth();
    const navigate = useNavigate();
    // const { redirect } = Route.useSearch()
    const theme = useTheme()
    const height = useWindowSize().height
    const iframeRef = useRef<HTMLIFrameElement>(null)
    // const searchParams = useSearch({
    //     from: '/'
    // });
    const [isLoaded, setIsLoaded] = useState(false)
    const handleIframeLoad = () => {
        setIsLoaded(true)
    }

    const targetOrigin = env.TARGET_APP_URL + env.ROUTING_PREFIX
    const loginURL = `${targetOrigin}${env.TARGET_APP_LOGIN_URL}`

    // Helper function to send messages to the legacy app
    const sendMessage = (message: PostMessageEvent) => {
        if (!iframeRef.current?.contentWindow) return
        iframeRef.current.contentWindow.postMessage(message, env.TARGET_APP_URL)
    }

    // Handle incoming messages from the legacy app
    const handleMessage = (event: MessageEvent) => {
        // console.log('Received message REACT:', event.data)
        // console.log(event.origin + ' vs ' + env.TARGET_APP_URL)
        // Verify origin
        if (event.origin !== env.TARGET_APP_URL) return

        // console.log('Received message:', event.data)

        switch (event.data.type) {
            case 'AUTH_DATA':
                handleAuthData(event.data)
                break
            case 'AUTH_COMPLETE':
                handleAuthComplete(event.data)
                break
            default:
                console.warn('Unknown message type:', event.data.type)
        }
    }

    // Handle AUTH_DATA message
    // const handleAuthData = async (data: AuthDataMessage) => {
    //     // console.log('Storing auth data:', data)
    //     try {
    //         localStorage.setItem('jwt', data.jwt)
    //         localStorage.setItem('public_key', data.publicKey)

    //         auth.login()
    //         await auth.getPermissions()


    //         // Notify legacy app of successful storage
    //         sendMessage({
    //             type: 'AUTH_COMPLETE',
    //             success: true
    //         })

    //         const decodedRedirect = decodeURIComponent(redirect || '/')
    //         const cleanRedirect = decodedRedirect?.replace('[object Object]', '') || '/'
    //         navigate({
    //             to: cleanRedirect,
    //             replace: true
    //         })

    //     } catch (error) {
    //         console.error('Failed to store auth data:', error)
    //         sendMessage({
    //             type: 'AUTH_COMPLETE',
    //             success: false,
    //             error: 'Failed to store authentication data'
    //         })
    //     }
    // }

    const handleAuthData = async (data: AuthDataMessage) => {
        try {
            await auth.setAuthStatus(true)
            setIsLoadingPermissions(true);

            // Store auth data
            localStorage.setItem('jwt', data.jwt)
            localStorage.setItem('public_key', data.publicKey)

            // Login and fetch permissions
            auth.login()

            // Prefetch permissions
            await auth.getPermissions()

            // Notify legacy app
            sendMessage({
                type: 'AUTH_COMPLETE',
                success: true
            })

            // Navigate
            const decodedRedirect = decodeURIComponent(redirect || '/')
            const cleanRedirect = decodedRedirect?.replace('[object Object]', '') || '/'
            navigate({
                to: cleanRedirect,
                replace: true
            })

        } catch (error) {
            console.error('Failed to store auth data:', error)
            sendMessage({
                type: 'AUTH_COMPLETE',
                success: false,
                error: 'Failed to store authentication data'
            })
        } finally {
            setIsLoadingPermissions(false);
        }
    }


    // Handle AUTH_COMPLETE message
    const handleAuthComplete = async (data: AuthCompleteMessage) => {

        if (data.success) {
            try {
                auth.login()
                const decodedRedirect = decodeURIComponent(redirect || '/')
                const cleanRedirect = decodedRedirect?.replace('[object Object]', '') || '/'
                // const cleanRedirect = decodedRedirect?.replace(/['"]/g, '') || '/'

                navigate({
                    to: cleanRedirect,
                    replace: true
                })
            } catch (error) {
                console.error('Login error:', error)
            }
        }
    }

    // Set up message listener
    useEffect(() => {
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {isLoadingPermissions && <LoadingScreen />}

                {/* Notification Area */}
                <Box
                    sx={{
                        // height: '150px',
                        width: '100%',
                        zIndex: 2,
                        bgcolor: '#f5f5f5',
                        flexShrink: 0,
                    }}
                >
                    {/* hello world */}
                </Box>

                {/* IFrame Container */}
                <Box
                    sx={{
                        position: 'relative',
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    <Box>
                        <iframe
                            ref={iframeRef}
                            src={loginURL}
                            title="Drop"
                            width="100%"
                            frameBorder={0}
                            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-popups allow-popups-to-escape-sandbox allow-presentation allow-modals allow-orientation-lock allow-pointer-lock allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-top-navigation"
                            allowFullScreen
                            allowTransparency
                            // allowTransparency
                            loading="lazy"
                            height={height}
                            onLoad={handleIframeLoad}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            style={{
                                // visibility: isLoaded ? 'visible' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                                backgroundColor: '#f5f5f5'
                            }}
                        ></iframe>
                    </Box>
                </Box>
            </Box>
        </>
    );
}