import React from 'react';
import { Chip, Container } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Paper,
  Typography,
} from '@utilisourcepackagelibdev/utilisourcepackagelib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ReleaseNotes = () => {
  interface ReleaseNote {
    version: string;
    date: string;
    features: string[];
    improvements: string[];
    bugFixes: string[];
  }

  const releaseNotes: ReleaseNote[] = [
    {
      version: 'v1.1.1',
      date: 'December 4, 2024',
      features: ['Added boilerplate'],
      improvements: [],
      bugFixes: [],
    },
    {
      version: 'v1.1.0',
      date: 'December 1, 2024',
      features: ['Added Release Notes Page'],
      improvements: [],
      bugFixes: [],
    },
  ];

  const legacyNotes: ReleaseNote[] = [
    {
      version: 'v20241030.1',
      date: 'October 30, 2024',
      features: [
        'Added current values of tickets and custom fields to the history tables',
        'Added better attachment and note handling'
      ],
      improvements: [
        'All ticket data is now present in the history table including the current values',
        'Simplified how ticket history is processed, greatly improved speed and accuracy of history'
      ],
      bugFixes: []
    },
    {
      version: 'v20240906.1',
      date: 'September 6, 2024',
      features: [
        'Ticket Detail Endpoint',
        'Secure External API Access',
        'Locate Ticket Creation API Endpoint',
        'Geocode API Endpoint to map tickets',
        'Duplicate Address Endpoint to check for duplicate addresses via API',
        'Attachment Endpoint to get ticket attachments via API',
        'Webhooks fire on ticket creation for special customer case',
        'Additional Instance Setup for future customer case'
      ],
      improvements: [
        'Restyled Nav bar now shows signed in agent',
        '811 Save Fields to Notes'
      ],
      bugFixes: [
        'Child Field not toggling off parent value bug fix',
        'Error modal on ticket creation or opening existing ticket bug fix'
      ]
    },
    {
      version: 'v20240708.1',
      date: 'July 8, 2024',
      features: [
        'Complete Application Redesign for Enhanced User Experience',
        'Attachments API Endpoint',
        'Endpoint for Locate Ticket Creation',
        'API Endpoint for Change Assignee',
        'API Endpoint for Geocoding Tickets',
        'API Endpoint for Duplicate Address Detection',
        'API Endpoint for Changing Status',
        'API Endpoint for Changing Status and Assignee for Groups of Tickets',
        'Endpoint for Getting Statuses',
        'Notes Endpoint'
      ],
      improvements: [
        'Set Up Security and Access for External API Access',
        'Set Up Geocoded Addresses in Ticket Object',
        'Merged Demo Site Changes into Production Pipeline'
      ],
      bugFixes: []
    },
    {
      version: 'v20240701.1',
      date: 'July 1, 2024',
      features: ['Added Marker Map to Ticket List'],
      improvements: ['Improved mobile experience for Ticket List and Ticket Detail'],
      bugFixes: []
    },
    {
      version: 'v20240103.1',
      date: 'January 3, 2024',
      features: [
        'Additional Report Header items added to the Custom Fields Page and to the Custom Fields Dialog',
        'Added Dirty Form Detection to the Edit Types Dialog on the Types page',
        'Show All added for Reporting Group Headers'
      ],
      improvements: [
        'Cursor shows as a pointer for all the attachment types on the Ticket Detail page'
      ],
      bugFixes: [
        'Fixed the gray bar that would stay in the Report Filters dialog after the data had loaded',
        'Fixed the height of the large yellow error box that would appear on Ticket Detail page if there was a duplicate address found'
      ]
    },
    {
      version: 'v20231113.1',
      date: 'November 13, 2023',
      features: ['New filter button in Ticket List, "Assigned to Me"'],
      improvements: [],
      bugFixes: [
        'Fixed issue with editing Attachment Types in Customer Editor',
        'Fixed bug in Reports causing rows to disappear after changing report filters'
      ]
    },
    {
      version: 'v20231030.1',
      date: 'October 30, 2023',
      features: ['History Email Status Lite release'],
      improvements: ['Latest Go Version and Dependency Update',
        "Normalize Emails in Storage"],

      bugFixes: [
        "Filter Tickets Assigned to current user button"
      ]


    },
    {
      version: 'v20231017.1',
      date: 'October 17, 2023',
      features: [
        'Big Feature Attachment Types supported in Ticket Detail',
        'Reporting Group Headers configurable inside Custom Fields Admin'],
      improvements: [
        'Attachment Types - Legacy attachments under Catch All'],
      bugFixes: []
    },
    {
      version: 'v20230928.1',
      date: 'September 28, 2023',
      features: [
        'Roles Sorted by Name'],
      improvements: [
        'Display General default group in Custom Field Admin Group Selector'],
      bugFixes: [
        'Attachment Type Add bug fix',
        'Custom Fields Deny edit if being used by multiple customers',
        'Customer Editor Removed Filter causing bugs',
        'Fixed custom field search',
        'Removed issues.html from React Navbar',
        'Users prompted when changing their own role and permissions that they could lose access',
        'Ticket Detail Optimized removing legacy Arbeitgebers to free up network traffic']
    },
    {
      version: 'v20230826.1',
      date: 'August 26, 2023',
      features: [
        'Add new user permission Customer Administrator'],
      improvements: [
        'Turn off several log entries, various categories'],
      bugFixes: []
    },
    {
      version: 'v20230809.1',
      date: 'August 9, 2023',
      features: [
        'Types Page updated to determine type filtering by customer'],
      improvements: [
        'Types By Customer Filtering added to Ticket detail, reports, and workflow rules'],
      bugFixes: [
        'Fixed bug with multi-word types']
    },
    {
      version: 'v20230806.1',
      date: 'August 6, 2023',
      features: ['Hot Fix'],
      improvements: ['WebHook notifications (multi-word ticket statuses)'],
      bugFixes: ['New customer creation error (Email Match)']
    },
    {
      version: 'v20230804.1',
      date: 'August 4, 2023',
      features: [
        'Attachment Types available in Customer Editor'],
      improvements: [
        'Ability to add, copy from existing customers, save, edit, and delete attachment types',
        'Attachment Types handle dirty form detection when user tries to leave with unsaved changes'],

      bugFixes: [
        "Ticket Detail page supports collapsable accordions",

        "Ticket Detail accordions store open/close state in local storage when navigating across multiple pages",

        "New Workflow Rule page, assignee and raised by now in alphabetical order",

        "Fixed visible error on open of Reports page without filters saved",

        "Users with only one customer will navigate to Reports will load in that one customer in invoice status",

        "New Customers without Groups set or Custom Fields Assigned will be met with an alert in Ticket Detail informing them there are No Custom fields set in the ticket, Group General will load with no Custom Fields inside it"]
    },
    {
      version: 'v20230711.1',
      date: 'July 11, 2023',
      features: [
        'Emails will retry any sent messages that do not get a webhook response validating they either made it to their destination or failed'],

      improvements: [

        "Emails will alert failure after maximum number of retries(5)",

        "Attachments are now stored and retrieved from blob storage"],

      bugFixes: [

        "Fixed duplicating header bug in Workflow Rules Admin",

        "Filter placeholder will behave correctly when navigating from ticket.html to reports2.html",

        "Selecting All in Ticket List will prompt user to select a customer to begin a search",

        "Ticket Detail page will no longer incorrectly detect changes on the page when a custom field is searched for",

        "Release notes page established",

        "Fixed timing issue, duplicate ticket status sent"]
    },
    {
      version: 'v20230626.4',
      date: 'June 26, 2023',

      features: [

        "Email Alerts for failed/bounced email messages to the support@opsource.us email address."],

      improvements: [

        "Type added to workflow rules condition.",

        "Filter Placeholder fix when navigating to and from ticket detail and reports.",

        "Removed “All” from ticket-list and replaced with “Select a Customer”.",

        "Ticket Detail – custom field search no longer will throw a dirty form detection alert if no changes were made to the ticket fields.",

        "Improved UX when filter active in reports and status/customer is changed.",

        "Upload attachments to blob storage instead of saving to database. (This feature is present but switched off pending additional testing.) Behavior will be transparent to the user."],

      bugFixes: []
    }
  ]


  const ReleaseNoteComponent = (note: ReleaseNote, index: number) => (
    <Paper key={note.version} elevation={3} sx={{ mb: 4, p: 3, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        {note.version}
        <Chip label={note.date} size="small" sx={{ ml: 2 }} />
      </Typography>
      <Divider sx={{ my: 2 }} />
      {note.features.length === 0 ? null : (
        <>
          <Typography variant="h6" gutterBottom>
            New Features
          </Typography>
          <ul>
            {note.features.map((feature, i) => (
              <li key={i}>
                <Typography>{feature}</Typography>
              </li>
            ))}
          </ul>
        </>
      )}
      {note.improvements.length === 0 ? null : (
        <>
          <Typography variant="h6" gutterBottom>
            Improvements
          </Typography>
          <ul>
            {note.improvements.map((improvement, i) => (
              <li key={i}>
                <Typography>{improvement}</Typography>
              </li>
            ))}
          </ul>
        </>
      )}
      {note.bugFixes.length === 0 ? null : (
        <>
          <Typography variant="h6" gutterBottom>
            Bug Fixes
          </Typography>
          <ul>
            {note.bugFixes.map((bugFix, i) => (
              <li key={i}>
                <Typography>{bugFix}</Typography>
              </li>
            ))}
          </ul>
        </>
      )}
      {/* {index === 0 && (
                        <Box mt={2}>
                            <Typography variant="body2">
                                For a full list of changes, see the{' '}
                                <Link href="https://github.com/mui/material-ui/blob/master/CHANGELOG.md">
                                    changelog
                                </Link>
                                .
                            </Typography>
                        </Box>
                    )} */}
    </Paper>
  )


  return (
    <Container
      maxWidth="md"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant="h2" component="h1" gutterBottom>
        Workflow Release Notes
      </Typography>
      {releaseNotes.map((note, index) => (
        ReleaseNoteComponent(note, index)
      ))}
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Previous Releases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {legacyNotes.map((note, index) => (
            ReleaseNoteComponent(note, index)
          ))}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default ReleaseNotes;
