const addParam = (newParamKey: string, newParamValue: string | number, currentParams: string) => {
  if (currentParams.includes('?')) return currentParams + `&${newParamKey}=${newParamValue}`;
  return currentParams + `?${newParamKey}=${newParamValue}`;
};

const createQueryParams = (
  params?: string | { [key: string]: any },
  options?: {
    excludeDefault?: boolean;
  },
) => {
  if (!params) return '';
  let search = options?.excludeDefault ? '' : ``;
  if (typeof params === 'string') return search + params;
  if (typeof params === 'object') {
    Object.keys(params).forEach((paramKey) => {
      const paramValue = params[paramKey];
      const typeOfParamKey = typeof paramKey;
      if (paramValue === undefined) return;
      //? Array type params
      if (Array.isArray(params[paramKey])) {
        params[paramKey]?.forEach((arrItem: any) => {
          search = addParam(`${paramKey}[]`, arrItem, search);
        });
      }
      //? String, number, and boolean param types
      else if (typeOfParamKey === 'string' || typeOfParamKey === 'number' || typeOfParamKey === 'boolean') {
        search = addParam(paramKey, params[paramKey], search);
      }
    });
  }
  return search;
};

export default createQueryParams;
