import { setupInterceptors } from '@/services/api.service';
import TicketService from '@/services/ticket.service';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { queryClient } from "@/lib/queryClient"
import useIndexedDB from '@/services/database/useIndexedDB';
import { createPingQueryOptions, createUserQueryOptions } from '@/services/queries/pingOptions';
import { createPermissionsQueryOptions, IPermissions, createRoleIdQueryOptions } from '@/hooks/fetches/usePermission.service';
import { createMergeTemplatePermissionsOptions } from '@/hooks/fetches/useTemplate.service';
import { QueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import useTicket from '@/hooks/fetches/useTicket.service';
import { configureAuth } from './auth2';
import { redirect } from '@tanstack/react-router';
import useTicketTableStore from '@/app/pages/TicketList/useTicketTableStore';

export interface AuthContext {
    isAuthenticated: boolean
    user: any | null
    permissions: any
    login: () => Promise<void>
    logout: () => Promise<void>
    getPermissions: () => any
    getCurrentPath: () => string
    setCurrentPath: (path: string) => void
    checkAuthStatus: () => boolean;
    setAuthStatus: (status: boolean) => void
}

const AuthContext = React.createContext<AuthContext | null>(null)

interface AuthData {
    jwt: string;
    publicKey: string;
}

const key = 'tanstack.auth.user'

export interface LoginCredentials {
    username: string;
}


export const handleUnauthorizedError = (queryClient: QueryClient) => {
    // enqueueSnackbar('Error', { variant: 'error', persist: true })
    console.log('handleUnauthorizedError fired')
    queryClient.clear();
    localStorage.removeItem('jwt');
    localStorage.removeItem('public_key');
    localStorage.removeItem('ticket-table-storage');
    redirect({ to: '/login' });
    // window.location.href = '/login';
};

// const { useUser, useLogin, useRegister, useLogout } = configureAuth({
//     userFn: () => api.get('/me'),
//     loginFn: (credentials) => api.post('/login', credentials),
//     registerFn: (credentials) => api.post('/register', credentials),
//     logoutFn: () => api.post('/logout'),
//   });


export const storage = {
    getToken: () => JSON.parse(window.localStorage.getItem('jwt') || 'null'),
    setToken: (token: string) =>
        window.localStorage.setItem('jwt', JSON.stringify(token)),
    clearToken: () => { window.localStorage.removeItem('jwt'); window.localStorage.removeItem('public_key') },
};


export const { useUser, useLogin, useRegister, useLogout, usePermission, AuthLoader } = configureAuth({
    usePermissionFn: async () => {
        const [permissions, mergeTemplatePermission] = await Promise.all([
            queryClient.fetchQuery(createPermissionsQueryOptions()),
            queryClient.fetchQuery(createMergeTemplatePermissionsOptions())
        ])
        return permissions ?? null
    },
    userFn: async () => {
        const userContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));
        const user = userContext?.ContextUser
        console.log(user)
        return user ?? null
    },
    loginFn: async () => {
        const userContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));
        const user = userContext?.ContextUser
        console.log(user)
        return user ?? null
    },
    registerFn: async () => {
        const userContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));
        const user = userContext?.ContextUser
        console.log(user)
        return user ?? null
    },
    logoutFn: async () => {
        const ticketService = TicketService()
        const IndexedDBAdapter = useIndexedDB()
        // try {
        // await ticketService.killSession({})
        IndexedDBAdapter.removeItem('dropSource')
        queryClient.clear()
        localStorage.removeItem('jwt')
        localStorage.removeItem('public_key')
        localStorage.removeItem('ticket-table-storage')
        // window.location.href = '/login'
        // } catch (error) {
        enqueueSnackbar('Error', { variant: 'error', persist: true })
        console.error('Logout failed:')
        // }

        // await ticketService.killSession({});
        // IndexedDBAdapter.removeItem('dropSource');
        // enqueueSnackbar('Error', { variant: 'error', persist: true })
        // console.log('handleUnauthorizedError fired')
        // queryClient.clear();
        // localStorage.removeItem('jwt');
        // localStorage.removeItem('public_key');
        // localStorage.removeItem('ticket-table-storage');
        // window.location.href = '/login';

    },
    queryClient: queryClient
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const getPermissions = useCallback(async () => {
        try {
            const permissions = await queryClient.fetchQuery(createPermissionsQueryOptions());
            const mergeTemplatePermission = await queryClient.fetchQuery(createMergeTemplatePermissionsOptions());
            const roleIdPermission = await queryClient.fetchQuery(createRoleIdQueryOptions());

            // setPermissions('hello world');


            console.assert({ permissions, mergeTemplatePermission, roleIdPermission })

            setPermissions({ permissions: permissions, mergeTemplatePermission: mergeTemplatePermission, roleIdPermission: roleIdPermission });
            return { permissions: permissions, mergeTemplatePermission: mergeTemplatePermission, roleIdPermission: roleIdPermission };
        } catch (error) {
            console.error('Login failed:', error);
            return null;
        }
    }, [queryClient]);

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => !!localStorage.getItem('jwt'));
    const [permissions, setPermissions] = useState<any>(() => getPermissions())
    const [user, setUser] = useState<any>(null)
    const { reset } = useTicketTableStore();

    const ticketService = TicketService()
    const IndexedDBAdapter = useIndexedDB()

    const setAuthStatus = useCallback((status: boolean) => {
        setIsAuthenticated(status);
    }, [])

    const checkAuthStatus = useCallback(() => {
        const hasToken = !!localStorage.getItem('jwt');
        // const ping = queryClient.fetchQuery(createPingQueryOptions())
        // const permissions = await queryClient?.fetchQuery(createPermissionsQueryOptions());
        // if (permissions) {
        //     console.log(permissions)
        // }
        setIsAuthenticated(hasToken);
        return hasToken;
    }, []);

    const login = useCallback(async () => {
        setIsAuthenticated(true);
        await setupInterceptors(queryClient);
        try {
            const userContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));
            setUser(userContext.ContextUser);
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    }, [queryClient]);


    const logout = useCallback(async () => {
        try {
            setIsAuthenticated(false);
            await ticketService.killSession({});
            reset();
            IndexedDBAdapter.removeItem('dropSource');
            setUser(null);
            setPermissions(null)
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        } finally {
            handleUnauthorizedError(queryClient)
        }
    }, [queryClient]);

    useEffect(() => {
        checkAuthStatus();
        // setupInterceptors();
    }, [checkAuthStatus]);

    // const login = useCallback(async () => {
    //     // await sleep(500)
    //     await setupInterceptors();
    //     setIsAuthenticated(true);

    //     ping.then(res => {
    //         console.log(res)
    //         setUser(ping)
    //     }).catch(err => {
    //         console.log(err)
    //     })

    //     agentContext.then(res => {
    //         console.log(res)
    //         setUser(res?.ContextUser)
    //     }).catch(err => {
    //         console.log(err)
    //     })

    // }, [])

    // Initialize auth state and interceptors

    // Listen to localStorage changes from other tabs/windows
    // useEffect(() => {
    //     const handleStorageChange = (event: StorageEvent) => {
    //         if (event.key === 'jwt') {
    //             checkAuthStatus();
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageChange);
    //     return () => window.removeEventListener('storage', handleStorageChange);
    // }, []);

    // Periodic check for JWT
    // useEffect(() => {
    //     const interval = setInterval(checkAuthStatus, 60000); // Check every minute
    //     return () => clearInterval(interval);
    // }, []);

    // const logout = useCallback(async () => {
    //     try {
    //         setIsAuthenticated(false);

    //         await ticketService.killSession({});
    //         await setupInterceptors();

    //         localStorage.removeItem('jwt');
    //         localStorage.removeItem('public_key');
    //         localStorage.removeItem('ticket-table-storage');
    //         IndexedDBAdapter.removeItem('dropSource');
    //         queryClient.removeQueries();


    //         // router.navigate({
    //         //     to: '/login',
    //         //     search: {
    //         //         redirect: router.state.location.href
    //         //     },
    //         //     replace: true
    //         // });
    //     } catch (error) {
    //         console.error('Logout error:', error);
    //     }
    // }, [ticketService, queryClient, IndexedDBAdapter]);



    const setCurrentPath = useCallback((path: string) => {
        localStorage.removeItem('currentPath');
        localStorage.setItem('currentPath', path);
    }, [])

    const getCurrentPath = useCallback(() => {
        return localStorage.getItem('currentPath') ?? '/';
    }, [])

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, permissions, login, logout, getPermissions, getCurrentPath, setCurrentPath, checkAuthStatus, setAuthStatus }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
