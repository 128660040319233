import { BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';

const TemplateService = () => {
  const getCustomFieldsByTemplateId = async ({
    templateId,
    onSuccess,
    onError,
  }: {
    templateId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/getcustomfieldsbytemplateid',
      params: {
        templateid: templateId,
      },
      onSuccess,
      onError,
    });
  };

  const getTemplatesByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/gettemplate',
      params: {
        templateid: 0,
        customerid: customerId
      },
      onSuccess,
      onError,
    });
  };

  const getMergeTemplatePermissions = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/mergetemplatepermission',
      onSuccess,
      onError,
    });
  };

  const postPopulatedTemplate = async ({
    data,
    attachmentIds,
    templateId,
    onSuccess,
    onError,
  }: {
    data: any;
    attachmentIds: number[];
    templateId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/mergetotemplate',
      data: {
        TemplateId: templateId,
        AttachmentIds: attachmentIds,
        ...data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
      onSuccess,
      onError,
    });
  };


  return {
    getCustomFieldsByTemplateId,
    getTemplatesByCustomer,
    getMergeTemplatePermissions,
    postPopulatedTemplate
  };
};

export default TemplateService;
