import { BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';

const SettingsService = () => {
  const getSettingsByAgentId = async ({
    agentId,
    onSuccess,
    onError,
  }: {
    agentId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/settings',
      params: {
        id: agentId,
      },
      onSuccess,
      onError,
    });
  };


  const postSettings = async ({
    data,
    agentId,
    onSuccess,
    onError,
  }: {
    data: any;
    agentId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/settings',
      params: {
        id: agentId,
      },
      onSuccess,
      onError,
      data,
    });
  };

  return {
    getSettingsByAgentId,
    postSettings,
  };
};

export default SettingsService;
