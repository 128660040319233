import { Grid2 as Grid, Typography, Box, Autocomplete, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, DialogActions } from '@mui/material';
import { getAttachmentDownloadOptions, getAttachmentViewOptions } from '@/hooks/fetches/useAttachment.service';
import { Button } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { FileDownload, PageviewOutlined, Delete } from '@mui/icons-material';
import useTicketTableStore from '../TicketList/useTicketTableStore';
import useTicketList from '@/contexts/stores/ticketList.store';
import useTicket from '@/hooks/fetches/useTicket.service';
import { useParams } from '@tanstack/react-router';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { queryClient } from '@/lib/queryClient';
import Permissions from '@/permissions/Permissions';

interface IAttachment {
  Fid: number;
  Nid: number;
  Filename: string;
  Filesize: number;
  UpdatedAt: string;
  UpdatedBy: number;
  DeletedAt: string;
  DeletedBy: number;
  URL: string;
  UpdatedByName: string;
  DeletedByName: string;
  CreatedByName: string;
  AttachmentTypeId: number;
  TimeZone: string;
  TimeZoneOffset: string;
  Subject: string;
  Message: string;
}

const Attachments = () => {

  const { ticketListState } = useTicketList();
  const { ticketTableState } = useTicketTableStore()
  const [downloadAttachment, setDownloadAttachment] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [selectedType, setSelectedType] = useState(null);

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const ticketId2 = Number(ticketId)

  const { attachmentsById, attachmentTypesByCustomer, updateTicketAttachments, agentsByCustomer, updateAttachmentDelete } = useTicket(ticketId2, ticketTableState?.customFilters?.customer?.id,);

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setSelectedFile([]);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    saveAttachment(selectedFile, selectedType)
  }

  const saveAttachment = async (selectedFiles: any, attachmentType: any) => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();

      selectedFiles.forEach((file: any) => {
        formData.append("attachment", file);
      });

      formData.append(
        "noteMetadata",
        JSON.stringify({
          attachmenttypeid: attachmentType?.attachmentType?.ATID ? attachmentType.attachmentType.ATID : attachmentType.id,
          message: "",
          subject: "",
          ticketid: ticketId2,
          username: agentsByCustomer.data.ContextUser.Name,
        })
      );

      try {
        await updateTicketAttachments({
          actionType: "updateTicketAttachments",
          data: formData,
        });
      } catch (error) {
        console.error("Error uploading attachments:", error);
      }
    }
  }

  const ChooseFileBtn = (attachmentType: any) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isThereAFile = (attachmentsById?.data && attachmentsById?.data || []).filter(
      (attachment: any) => attachment.AttachmentTypeId === attachmentType.attachmentType.ATID
    );

    const handleChooseFileClick = () => {
      fileInputRef.current?.click();
    };

    const handleFileChange = (event: any) => {
      const selectedFiles = Array.from<File>(event.target.files || []);
      setSelectedFile(selectedFiles)
      if (attachmentType.attachmentType.CatchAll) {
        setIsDialogOpen(true);
        return
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      saveAttachment(selectedFiles, attachmentType)
    };

    const showButton = (!attachmentType.attachmentType.Multiple && !isThereAFile.length) || attachmentType.attachmentType.Multiple;
    const buttonLabel = attachmentType.attachmentType.CatchAll ? "Choose File And Type" : "Choose File";

    return (
      <>
        {showButton && (
          <Permissions target={'ticketDetailSave'}>
            <>
              <Button id="addAttachment" onClick={handleChooseFileClick}>
                {buttonLabel}
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                multiple={attachmentType.attachmentType.Multiple}
              />
            </>
          </Permissions>
        )}
      </>
    );
  };

  const handleDownloadClick = async (attachment: IAttachment) => {
    try {
      const response = await queryClient.fetchQuery(getAttachmentDownloadOptions(attachment.Fid, true));
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadAttachment.Filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        console.error('Downloaded data is not a Blob.');
      }
    } catch (error) {
      console.error('Error fetching attachment:', error);
    }
    setDownloadAttachment(attachment);
  };

  const handleViewClick = async (Fid: number) => {
    try {
      const response = await queryClient.fetchQuery(getAttachmentViewOptions(Fid, true));
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
        window.open(url, '_blank');
      } else {
        console.error('Viewed data is not a Blob.');
      }
    } catch (error) {
      console.error('Error fetching attachment:', error);
    }
  };

  const handleDeleteClick = async (attachment: IAttachment) => {
    try {
      const confirmLeave = confirm("Are you sure you want to delete this attachment permanently?");
      if (confirmLeave) {
        await updateAttachmentDelete({ actionType: 'attachmentDelete', fileid: attachment.Fid, ticketid: ticketId2 });
      }
    } catch (error) {
      console.error('Error deleting attachment:', error);
    }
  }

  const DownloadBtn = (attachment: IAttachment) => (
    <Button
      className="downloadBtn note-btn"
      onClick={() => handleDownloadClick(attachment)}
      id="downloadAttachment"
    >
      <FileDownload
        className="material-icons-outlined"
        style={{
          fontSize: '1.125rem',
          width: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      Download
    </Button>
  );

  const ViewBtn = (Fid: number) => (
    <Button
      className="viewBtn note-btn"
      onClick={() => handleViewClick(Fid)}
      id="viewAttachment"
      sx={{ marginRight: '8px' }}
    >
      <PageviewOutlined
        className="material-icons-outlined"
        style={{
          fontSize: '1.125rem',
          width: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      View
    </Button>
  );

  const DeleteBtn = (attachment: IAttachment) => {
    console.log('attachment', attachment)
    if (agentsByCustomer?.data?.ContextUser?.Name === attachment?.CreatedByName) {
      return (
        <Button
          className="deleteAttachmentBtn note-btn"
          onClick={() => handleDeleteClick(attachment)}
          id="deleteAttachment"
          color='error'
        >
          <Delete
            className="material-icons-outlined"
            style={{
              fontSize: '1.125rem',
              width: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          Delete
        </Button>
      )
    }

    return (
      <Permissions target={'canDeleteAttachment'}>
        <Button
          className="deleteAttachmentBtn note-btn"
          onClick={() => handleDeleteClick(attachment)}
          id="deleteAttachment"
          color='error'
        >
          <Delete
            className="material-icons-outlined"
            style={{
              fontSize: '1.125rem',
              width: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          Delete
        </Button>
      </Permissions>

    )
  };

  const buildCatchAllAttachments = () => {
    const catchAllAttachmentTypeIds = attachmentTypesByCustomer?.data
      ?.filter((type: any) => !type.Required)
      ?.map((type: any) => type.ATID);

    const catchAllAttachments = attachmentsById?.data
      ?.filter((attachment: IAttachment) => attachment.AttachmentTypeId === 0 || catchAllAttachmentTypeIds?.includes(attachment.AttachmentTypeId))
      ?.sort((a: any, b: any) => a.AttachmentTypeId - b.AttachmentTypeId);

    const catchAllType = attachmentTypesByCustomer?.data?.find((type: any) => type.CatchAll);

    const modifiedCatchAllAttachments = catchAllAttachments?.map((attachment: IAttachment) => {
      const matchingType = attachmentTypesByCustomer?.data?.find(
        (type: any) => attachment.AttachmentTypeId === type.ATID
      );

      return {
        ...attachment,
        AttachmentTypeName: matchingType ? matchingType.Name : catchAllType?.Name,
      };
    });

    return (
      <Grid container direction="column" spacing={1} sx={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
        {modifiedCatchAllAttachments?.map((attachment: any, index: number) => (
          <Grid item xs={12} key={`${attachment.Fid}-${index}`} sx={{ padding: "8px 0" }}>
            <Typography variant="body1" fontWeight="bold">
              {attachment.AttachmentTypeName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {dayjs(attachment.UpdatedAt).format("MM/DD/YYYY")} By {attachment.UpdatedByName} {dayjs(attachment.UpdatedAt).format("hh:mm a")}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              {attachment.Filename}
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item>{ViewBtn(attachment.Fid)}</Grid>
              <Grid item>{DownloadBtn(attachment)}</Grid>
              <Grid item>{DeleteBtn(attachment)}</Grid>
            </Grid>
            {
              attachment.Message &&
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  Note
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <div dangerouslySetInnerHTML={{ __html: attachment.Message }} />
                </Typography>
              </Box>
            }
            <Box sx={{ borderBottom: "1px solid #ddd", marginTop: "8px" }} />
          </Grid>
        ))}
      </Grid>
    )
  }

  const buildAttachments = (attachmentType: any) => {
    if (attachmentType.CatchAll) return buildCatchAllAttachments()
    const filteredAttachments = attachmentsById?.data && attachmentsById?.data.filter((attachment: IAttachment) => attachment.AttachmentTypeId === attachmentType.ATID)

    return (
      <Grid container direction="column" spacing={1} sx={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
        {filteredAttachments?.map((attachment: IAttachment, index: number) => (
          <Grid item xs={12} key={`${attachment.Fid}-${index}`} sx={{ padding: "8px 0" }}>
            <Typography variant="body2" color="textSecondary">
              {dayjs(attachment.UpdatedAt).format("MM/DD/YYYY")} By {attachment.UpdatedByName} {dayjs(attachment.UpdatedAt).format("hh:mm a")}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              {attachment.Filename}
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item>{ViewBtn(attachment.Fid)}</Grid>
              <Grid item>{DownloadBtn(attachment)}</Grid>
              <Grid item>{DeleteBtn(attachment)}</Grid>
            </Grid>
            {
              attachment.Message &&
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  Note
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <div dangerouslySetInnerHTML={{ __html: attachment.Message }} />
                </Typography>
              </Box>
            }
            <Box sx={{ borderBottom: "1px solid #ddd", marginTop: "8px" }} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const buildAttachmentTypes = () => {
    const sortedAttachments = attachmentTypesByCustomer?.data
      .filter((attachmentType: any) => attachmentType.CatchAll || attachmentType.Required)
      .sort((a: any, b: any) => {
        if (a.CatchAll && !b.CatchAll) return 1;
        return a.Name.localeCompare(b.Name);
      });

    return (
      <Grid container direction="column" spacing={3} sx={{ padding: "16px", borderRadius: "8px" }}>
        {sortedAttachments.map((attachmentType: any, index: number) => {
          return <Grid item xs={12} key={`${attachmentType.ATID}-${index}`} sx={{ marginBottom: "16px" }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {attachmentType.Name}
            </Typography>
            <Box sx={{ marginBottom: "8px" }}>
              <ChooseFileBtn attachmentType={attachmentType} />
            </Box>
            {buildAttachments(attachmentType)}
          </Grid>
        })}
      </Grid>
    );
  };

  return (
    <>
      {attachmentTypesByCustomer.data && buildAttachmentTypes()}
      <Dialog open={isDialogOpen} fullWidth onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Select Attachment Type</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="type"
            sx={{
              mt: 2
            }}
            options={
              attachmentTypesByCustomer?.isPending
                ? []
                : attachmentTypesByCustomer?.data
                  ?.filter((data: any) => !data.Required)
                  .map((data: any) => ({
                    id: data.ATID,
                    label: data.Name,
                  })) || []
            }
            renderInput={(params) => <TextField {...params} label="Attachment Type" />}
            loading={attachmentTypesByCustomer?.isPending}
            multiple={false}
            size='small'
            fullWidth
            onChange={(event, newValue: any) => setSelectedType(newValue)}
          />
          <DialogContentText mt={4}>
            {selectedFile?.map((file: any) => (file.name))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id='cancelType' onClick={handleDialogCancel}>Cancel</Button>
          <Button id='AttachType' onClick={handleDialogClose} autoFocus>Attach</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Attachments
