import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { Box, useTheme, Stack, Paper, Chip } from '@mui/material';
import Grid from "@mui/material/Grid2"
import { IconButton, Tooltip, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import React, { useState } from 'react';

import useColumns from '@/hooks/useColumns';
import { MRT_ShowHideColumnsButton, MRT_SortingState, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, useMaterialReactTable } from 'material-react-table';

import { useNavigate } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { createAllWorkflowRulesQueryOptions, workflowRulesKeys } from '@/services/queries/workflowOptions';
import { queryClient } from '@/lib/queryClient';
import { enqueueSnackbar } from 'notistack';
import { WorkflowRuleTableColumns } from './WorkflowRuleTableColumns';
import { Filter, Mail, RefreshCw, UserCheck } from 'lucide-react';
import { ZodNumber } from 'zod';
// import useCustomerStore from '@/contexts/stores/Customer.store';

interface IActionData {
  Assignee?: number;
  StatusId?: number;
  Recipient?: number;
  RecipientList?: string[];
  Subject?: string;
  Body?: string;
}

export interface IAction {
  Actiontype: number;
  Actiondata: IActionData;
}

export interface TicketExt {
  Ticket: Ticket;
  Raised: Raised;
  Status: Status;
  Type: Type;
  Assignee: Assignee;
  CustomerEntity: CustomerEntity;
  TicketHistoryId: number;
  Context: string;
}

export interface TicketData {
  CustomFieldValues: CustomFieldValue;
  TicketExt: TicketExt;
}

interface Type {
  TypeId: number;
  Name: string;
  Description: string;
};

interface Status {
  StatusId: number;
  Name: string;
  Color: number;
  Flags: number;
};

interface Ticket {
  TId: number;
  StatusId: number;
  Assignee: number;
  Customer: number;
  RaisedBy: number;
  TypeId: number;
  Priority: string;
  CreateTime: string;
  LastUpdated: string;
  LastUpdatedBy: number;
  DeleteTime: string;
  DueDate: string;
  Title: string;
  EClassID: number;
  Revision: number;
  ClientName: string;
  ClientAddress: string;
  ClientPhone: string;
  ClientEmail: string;
  ClosedDate: string;
  ScheduledInstallDate: string;
  TimeZoneOffset: string;
  TimeZone: string;
  FieldJson: string;
};

interface Raised {
  AgentId: number;
  Name: string;
  Flags: number;
  Email: string;
  Phone: string;
  EClassID: number;
  RoleId: number;
  Customer: number;
  ArchiveTime: string;
  AgentType: number;
  DisabledDate: string;
  DisabledBy: number;
  FilterFeatureOn: boolean;
  TimeZoneOffset: string;
  TimeZone: string;
};

interface Assignee {
  AgentId: number;
  Name: string;
  Flags: number;
  Email: string;
  Phone: string;
  EClassID: number;
  RoleId: number;
  Customer: number;
  ArchiveTime: string;
  AgentType: number;
  DisabledDate: string;
  DisabledBy: number;
  FilterFeatureOn: boolean;
  TimeZoneOffset: string;
  TimeZone: string;
};

interface CustomerEntity {
  CustomerEntId: number;
  EntName: string;
  Description: string;
  EntAddress: string;
  RoleId: number;
  Phone: string;
  Open: string;
  Close: string;
  EmailMatch: string;
  ArchiveTime: string;
};

interface Meta {
  Cid: number;
  Name: string;
}

interface ValueMeta {
  C: {
    Meta: Meta;
    TypeId: number;
    ReportHeaderId: number;
  };
}

interface Value {
  Str?: string;
  MyInt?: number;
  SelectedOpt?: string;
}

export interface CustomFieldValue {
  ValueMeta: ValueMeta;
  Value: Value;
}

export interface IoldConfig {
  DisabledDate: string;
  DisabledBy: number;
  FilterFeatureOn: boolean;
  TimeZoneOffset: string;
  TimeZone: string;
  Agents: Agent[];
  Types: Type[];
  Stati: Status[];
}

interface Agent {
  AgentId: number;
  Name: string;
  Flags: number;
  Email: string;
  Phone: string;
  EClassID: number;
  RoleId: number;
  Customer: number;
  ArchiveTime: string;
  AgentType: number;
  DisabledDate: string;
  DisabledBy: number;
  FilterFeatureOn: boolean;
  TimeZoneOffset: string;
  TimeZone: string;
}

interface Type {
  TypeId: number;
  Name: string;
  Description: string;
  Meta?: Meta;
  Type?: TypeDetail;
}

interface Meta {
  Cid: number;
  Name: string;
  ArchivedTime: string;
  Archivedts: number;
  Settings: Settings;
}

interface Settings {
  ParentField: string;
  ParentFieldId: number;
  ParentFieldValue: string;
  GroupName: string;
  FieldGroupId: number;
  IsRequired: boolean;
  ReportHeaderId: number;
}

interface TypeDetail {
  Maximum?: number;
  Minimum?: number;
  Step?: number;
  IncludeTime?: boolean;
}

interface Status {
  StatusId: number;
  Name: string;
  Color: number;
  Flags: number;
}

interface Action {
  Actiontype: number;
  Actiondata: {
    Assignee: number;
  };
}

interface Condition {
  column: string;
  fieldId: number;
  isEqual: string;
  statusValue: null;
  type: string;
  userId: null;
  value: string[];
}

export interface SaveWorkflowRule {
  SmartRuleId: number;
  Customer: number;
  Description: string;
  Actions: Action[] | IAction[];
  AllConditions: Condition[];
  AnyConditions: Condition[]; // Assuming AnyConditions would have the same structure as AllConditions
  ExecCount: number;
}

interface typeinfo {
  ClassBinding: string
}

let globalActions = [
  {
    actionName: "Set Assignee",
    actionType: 3,
  },
  {
    actionName: "Send Email",
    actionType: 1,
  },
  {
    actionName: "Set Status",
    actionType: 2,
  },
];

export function CFTypeIdDetail(id: number): typeinfo {
  switch (id) {
    case 22: return { ClassBinding: "cfTDate" }
    case 16: return { ClassBinding: "cfTInteger" }
    case 17: return { ClassBinding: "cfTString" }
    case 18: return { ClassBinding: "cfTFile" }
    case 19: return { ClassBinding: "cfTDropdown" }
    case 20: return { ClassBinding: "cfTToggle" }
    case 21: return { ClassBinding: "cfTArcGIS" }
  }
  throw new Error('Not a valid typeid')
}

class SmartRuleCondition {
  ConditionId: number = 0
  FieldData: field = new field()

  // 1 for equal, 0 for not equal
  Equality: number = 1

  // 1 for matchall, 0 for match any
  MatchType: number = 0
}

class field {
  Fieldtype: fieldtype = fieldtype.fieldStatus
  FieldString: any = 0 // json'd
}

enum fieldtype {
  // Value will be a uint of a statusid
  fieldStatus = 1,
  // Value will be a uint of a agentid
  fieldAssignee = 3,
  // Value will be a uint of a agentid
  fieldRaisedBy = 4,
  // Value will be a uint of a typeid
  fieldType = 5,

  // Value will be cfieldCondition.
  // if its over 0x1000, that means its 0x1000 + the cid of the custom field.
  fieldCustomField = 0x1000,
}

// enum actionType {
//   actionEmail = 3,
//   actionStatus = 2,
//   actionAssignee = 1
// }

interface fieldType {
  Status: number
  Assignee: number
  RaisedBy: number
  Type: number
  CustomField: number
}

export type fieldTypes = {
  Status: 1;
  Assignee: 3;
  RaisedBy: 4;
  Type: 5;
}

export const getEquality = (equality: number) => {
  switch (equality) {
    case 1:
      return 'Equals';
    case 0:
      return 'Not Equal';
    default:
      return 'Unknown Equality';
  }
}

export const getActionName = (actionType: number) => {
  switch (actionType) {
    case 2:
      return 'Set Status';
    case 3:
      return 'Set Assignee';
    case 1:
      return 'Send Email';
    default:
      return 'Unknown Action';
  }
};

export const findFieldName = (condition: any) => {


  // console.log(condition)


  const fieldMapping: { [key: number]: keyof fieldTypes } = {
    1: "Status",
    3: "Assignee",
    4: "RaisedBy",
    5: "Type",
    // map other fieldTypes as needed
  };

  if (condition?.FieldData?.Fieldtype > 10) {

  } else {
    const fieldName = fieldMapping[condition?.FieldData?.Fieldtype];
    // console.log(fieldName)
    return fieldName;
  }
}


const useWorkflowRuleTable = () => {

  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'SmartRuleId', desc: true }]);

  const columns = useColumns(WorkflowRuleTableColumns);

  const allWorkflowRules = useQuery(createAllWorkflowRulesQueryOptions())
  const data = allWorkflowRules?.data?.oldSmartRules || []

  const navigate = useNavigate()
  const theme = useTheme();

  const Message = (message: string) => {
    return <Typography>{message}</Typography>;
  };

  const handleRefresh = async () => {
    console.log('refreshing')
    const previousData = queryClient.getQueryData(workflowRulesKeys.all);
    // console.log(previousData)

    allWorkflowRules.refetch();

    await queryClient.invalidateQueries({ queryKey: workflowRulesKeys.all, exact: true }); // Replace 'yourQueryKey' with the actual query key
    const newData = queryClient.getQueryData(workflowRulesKeys.all);
    // console.log(newData)
    if (newData) {
      if (JSON.stringify(newData) !== JSON.stringify(previousData)) {
        enqueueSnackbar(Message('New records found!'), { variant: 'success' });
      } else {
        enqueueSnackbar(Message('Table successfully updated'), {
          variant: 'default',
          style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
        });
      }
    }
  };


  const ActionIcon = ({ actionType }: { actionType: number }) => {
    // console.log(actionType)
    switch (actionType) {
      case 3:
        return <UserCheck size={12} />;
      case 2:
        return <RefreshCw size={12} />;
      case 1:
        return <Mail size={12} />;
      default:
        return <>hello</>
    }
  };

  const ActionDetails = ({ action }: { action: IAction }) => {
    // console.log(action)
    switch (action?.Actiontype) {
      case 3:
        return (
          <Typography variant="caption" color="text.secondary">
            to: {action?.Actiondata?.Assignee}
          </Typography>
        );

      case 2:
        return (
          <Typography variant="caption" color="text.secondary">
            to: {action?.Actiondata?.StatusId}
          </Typography>
        );
      case 1:
        return (
          <Stack direction='column'>
            <Typography variant="caption" color="text.secondary">
              Recipients: {action?.Actiondata?.Recipient}
              {/* to: {action?.Actiondata?.RecipientList.map((item: any) => item)} */}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Subject: {action?.Actiondata?.Subject}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Body: {action?.Actiondata?.Body}
            </Typography>

          </Stack>
        );

    }
  };

  const workflowTable = useMaterialReactTable({
    data,
    columns,
    enableSorting: true,
    enableDensityToggle: false,
    enableFullScreenToggle: true,
    enableColumnFilters: false,
    positionGlobalFilter: 'left',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 10,
      },
    },
    muiTablePaperProps: {
      sx: {
        p: 2,
        borderRadius: '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px',
        backgroundImage: 'none',
      },
    },
    muiTableBodyProps: {
      sx: {
        border: 'none',
      },
    },
    onSortingChange: setSorting,
    getRowId: (row: any) => row?.name,
    renderDetailPanel: ({ row }) => (
      <Grid spacing={2} container>
        <Grid size={{ xs: 12, md: 6 }} >
          <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
            {row.original.AllConditions && (
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                  <Filter size={16} color="#1976d2" />
                  <Typography variant="subtitle2" color="text.secondary">
                    Match ALL
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  {row.original?.AllConditions?.map((condition: any, index: number) => (
                    <Stack key={index} direction="row" alignItems="center" spacing={1}>
                      <Chip
                        label={findFieldName(condition)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ fontSize: '0.75rem' }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {getEquality(condition.Equality)}
                      </Typography>
                      <Chip
                        label={condition.MatchType}
                        color="secondary"
                        variant="outlined"
                        size="small" F
                        sx={{ fontSize: '0.75rem' }}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Box>
            )}
            {row.original.AnyConditions && (
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                  <Filter size={16} color="#1976d2" />
                  <Typography variant="subtitle2" color="text.secondary">
                    Match ANY
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  {row.original?.AnyConditions?.map((condition: any, index: number) => (
                    <Stack key={index} direction="row" alignItems="center" spacing={1}>
                      <Chip
                        label={findFieldName(condition)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ fontSize: '0.75rem' }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {getEquality(condition.Equality)}
                      </Typography>
                      <Chip
                        label={condition.MatchType}
                        color="secondary"
                        variant="outlined"
                        size="small" F
                        sx={{ fontSize: '0.75rem' }}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Actions
            </Typography>
            <Stack spacing={1}>
              {row.original?.Actions?.map((action: IAction, index: number) => (
                <Paper key={index} >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                      sx={{
                        p: 0.5,
                        bgcolor: 'primary.50',
                        borderRadius: '50%',
                        display: 'flex'
                      }} >
                      <ActionIcon actionType={action.Actiontype} />
                    </Box>
                    <Stack >
                      <Chip
                        label={getActionName(action?.Actiontype)}
                        size="small"
                        sx={{ fontSize: '0.75rem', maxWidth: '100%' }}
                      />
                      <ActionDetails action={action} />
                    </Stack>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    ),
    renderRowActions: ({ row, table }: any) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        <Tooltip title="Edit">
          <IconButton
            id="edit"
            size="small"
            onClick={(event: any) => {
              event.stopPropagation();
              console.log('Checking Row : ', row.original.SmartRuleId);
              navigate({
                to: '/workflow-rules/$workflowruleId',
                params: { workflowruleId: row.original.SmartRuleId },
                search: {
                  variant: 'edit',
                  step: 0,
                  completed: {},
                },
              })
              //   setVariant('edit')
              //   setCustomerId(row.original.idstomerId)
              //   openDrawer()
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: (event: any) => {
        event.stopPropagation();
        console.log('Checking Row : ', row.original?.SmartRuleId);
        navigate({
          to: '/workflow-rules/$workflowruleId',
          params: { workflowruleId: row.original.SmartRuleId },
          search: {
            variant: 'edit',
            step: 0,
            completed: {}
          },
        })
        // setVariant('edit')
        // setCustomerId(row.original.idstomerId)
        // openDrawer()
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderToolbarInternalActions: ({ table }: any) => (
      <>
        {/* add your own custom print button or something */}
        <IconButton onClick={handleRefresh} id={'refresh'}>
          <RefreshIcon />
        </IconButton>
        {/* built-in buttons (must pass in table prop for them to work!) */}
        {/* <MRT_ToggleFiltersButton table={table} /> */}
        <MRT_ShowHideColumnsButton table={workflowTable} />
        <MRT_ToggleFullScreenButton table={workflowTable} />
      </>
    ),
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: sorting,
    },
    state: {
      showAlertBanner: allWorkflowRules.isError,
      showSkeletons: allWorkflowRules.isLoading,
      showProgressBars: allWorkflowRules.isFetching,
      sorting: sorting,
    },
  });

  return { workflowTable };
};

export default useWorkflowRuleTable;
