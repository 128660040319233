import React, { useEffect, useMemo, useState } from 'react';
import SimpleTable from '@/components/Molecules/Table/SimpleTable.component';
import useTicket from '@/hooks/fetches/useTicket.service';
import { Badge, Box, Card, Collapse, Container, IconButton, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Grid from "@mui/material/Grid2"
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { AutoComplete, Button, Stack, TextField } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import useTicketList from '@/contexts/stores/ticketList.store';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  TicketListFilterSchema,
  ticketListFilterSchema,
  defaultValues,
} from '@/interfaces/schemas/ticketListFilter.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TicketListMap from '@/components/Organisms/TicketListMap.component';
import { useNavigate, useSearch } from '@tanstack/react-router';
import useTicketTable from './useTicketTable';
import useTicketTableStore from './useTicketTableStore';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import CreateTicketDrawer from './CreateTicketDrawer';
import Permissions from '@/permissions/Permissions';
import { ToggleLeft, ToggleRight } from 'lucide-react';

interface CreateTicketFormSchema {
  customer: { id: number; label: string } | null;
  // Add other fields as needed
}

export const FilterToggleButton = () => {

  const { ticketTableState, setIsFilterVisible } = useTicketTableStore()

  return (
    <Tooltip title={ticketTableState?.isFilterVisible ? "Hide Header" : "Show Header"}>
      <IconButton
        onClick={() => setIsFilterVisible(!ticketTableState?.isFilterVisible)}
        size="small"
      >
        {ticketTableState?.isFilterVisible ? <ToggleLeft /> : <ToggleRight />}
      </IconButton>
    </Tooltip>
  )
}

const TicketList = () => {
  // const [assigneeFilterApplied, setAssigneeFilterApplied] = useState<boolean>(false); // To track the toggle state
  const navigate = useNavigate();
  const { drawer } = useSearch({ from: '/_auth/' });
  const { ticketListState, updateTicketList } = useTicketList();
  const { ticketTableState, setCustomFilters } = useTicketTableStore()

  const [isOpen, setIsOpen] = useState(false)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleClose = () => {
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/', search: { drawer: undefined } });
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    navigate({ to: '/', search: { drawer: undefined } });
  };

  const methods = useForm<TicketListFilterSchema>({
    mode: 'all',
    resolver: zodResolver(ticketListFilterSchema),
    defaultValues,
  });


  const customerValue = useWatch({ control: methods.control, name: 'customer_filter' });
  const categoryValue = useWatch({ control: methods.control, name: 'category_filter' });

  const {
    customers,
    statusesByCustomer,
    ticketTypesByCustomer,
    priorities,
    agentsByCustomer
  } = useTicket(
    undefined,
    ticketTableState?.customFilters?.customer?.id,
    ticketTableState?.customFilters?.category?.label,
  );

  const handleSubmit = (formValues: any) => {
    ticketListTable.resetColumnFilters();
    updateTicketList({ selectedCustomer: customerValue, selectedCategory: categoryValue });
    setCustomFilters({ customer: formValues.customer_filter, category: formValues.category_filter });
    // ticketListTable.setCreatingRow(formValues)
    const customer = ticketListTable.setColumnFilters((prev) => [
      ...prev,
      {
        id: 'CustomerName',
        value: formValues.customer_filter ? formValues.customer_filter?.label : '',
      },
    ]);

    return { customer };
  };

  // const handleCustomerChange = (customer: any) => {
  //   const customerId = customer ? customer.id : undefined;
  //   // setSelectedCustomer(customerId);
  //   updateTicketList({ selectedCustomer: customerId })
  // };

  // const handleCategoryChange = (category: any) => {
  //   const categoryValue = category ? category.label : undefined;
  //   // setSelectedCategory(categoryValue);
  //   updateTicketList({ selectedCategory: categoryValue })
  // };

  const handleToggleTicketListMap = () => {
    updateTicketList({ ticketListMapOpen: true });
  };

  const handleToggleAssigneeFilter = () => {
    const currentFilterApplied = ticketListState.assigneeFilterApplied;

    updateTicketList({ assigneeFilterApplied: !currentFilterApplied });

    if (!currentFilterApplied) {
      const assignee = ticketListTable.setColumnFilters((prev) => [
        ...prev,
        {
          id: 'Assignee',
          value: [agentsByCustomer.data?.ContextUser?.Name],
        },
      ]);

      return { assignee };
    } else {
      ticketListTable.setColumnFilters((prev) => prev.filter((filter) => filter.id !== 'Assignee'));
    }
  };

  const { ticketListTable } = useTicketTable()
  // const { ticketListTable } = useTicketListTable({
  //   // setEditRow,
  //   // setEditRowValues,
  //   // setCustomerDrawerOpen,
  //   sorting,
  //   setSorting,
  //   loading: allTickets.isLoading,
  //   data: allTickets.data ? allTickets.data : [],
  //   refreshQueryKey: ['all-tickets', ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label],
  // });

  // useEffect(() => {
  //   updateTicketList({ selectedCustomer: customerValue, selectedCategory: categoryValue })
  //   // updateTicketList({ selectedCategory: categoryValue })
  // }, [customerValue, categoryValue])

  const baseCategories = [
    { id: 0, label: 'Active' },
    { id: 1, label: 'Closed' },
    { id: 2, label: 'All' },
  ];

  // Get categories based on customer selection
  const getCategories = () => {
    // Check if the selected customer is Gateway
    const isGatewayCustomer = customerValue?.id === 9;

    return isGatewayCustomer
      ? [...baseCategories, { id: 3, label: 'Assigned' }]
      : baseCategories;
  };

  // useEffect(() => {
  //   // When the component mounts, check if the assignee filter should be applied
  //   if (ticketListState.assigneeFilterApplied) {
  //     ticketListTable.setColumnFilters((prev) => [
  //       ...prev,
  //       { id: 'Assignee', value: ['Arna Wade'] }, //! Replace this with a dynamic agent name
  //     ]);
  //   } else {
  //     ticketListTable.setColumnFilters((prev) => prev.filter((filter) => filter.id !== 'Assignee'));
  //   }
  // }, [ticketListState.assigneeFilterApplied, ticketListTable));

  const onSubmit = (formValues: any) => {
    console.log('hello world submit')
    console.log(formValues);

  }

  useEffect(() => {
    methods.setValue('customer_filter', ticketTableState?.customFilters?.customer ?? null);
    methods.setValue('category_filter', ticketTableState?.customFilters?.category?.label ? { id: ticketTableState?.customFilters?.category?.id, label: ticketTableState?.customFilters?.category?.label } : null);
    updateTicketList({ selectedTicket: { id: 0 } });
  }, []);

  useEffect(() => {
    if (categoryValue?.label === 'Assigned' && customerValue?.id !== 9) {
      methods.setValue('category_filter', null);
    }
  }, [customerValue?.id, categoryValue?.label])

  return (
    <>
      <Grid size={12} display="flex" flexDirection="column" sx={{ mt: -8 }}>

        <Collapse
          in={ticketTableState?.isFilterVisible}
          timeout={300}
          sx={{
            transition: theme => theme.transitions.create('margin'),
            marginBottom: ticketTableState?.isFilterVisible ? 2 : 0
          }}
        >
          <Grid size={12} display="flex" flexDirection="row" sx={{ mb: 4 }}>
            <Card sx={{ p: 4, width: '100%' }} >
              {/* <Typography variant="h4" sx={{ mb: 4 }}>Filters</Typography> */}
              <FormProvider {...methods}>
                <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
                  <Grid
                    size={12}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  // rowGap={4}
                  // paddingX={2}
                  // paddingY={1}
                  >
                    <FilterToggleButton />
                    <Grid
                      size={9}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >

                      <Grid size={3} sx={{ mr: 4 }}>

                        <AutoComplete
                          id="customer_filter"
                          options={
                            customers.isPending
                              ? []
                              : customers?.data?.map((customer: any) => ({
                                id: customer.CustomerEntId,
                                label: customer.EntName,
                              })) || []
                          }
                          loading={customers.isPending}
                          autocompleteProps={{
                            id: 'customer_filter',
                            loadingText: 'Loading Customers...',
                            size: 'small',
                          }}
                          multiple={false}
                          label="Customer"
                        />
                      </Grid>
                      <Grid size={2} sx={{ mr: 4 }}>

                        <AutoComplete
                          id="category_filter"
                          options={getCategories()}
                          label="Category"
                          autocompleteProps={{
                            id: 'category_filter',
                            size: 'small',
                          }}

                        />
                      </Grid>
                      <Grid>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Button
                            id="clear"
                            size='small'
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                            onClick={() => {
                              methods.reset();
                              ticketListTable.resetColumnFilters();
                              setCustomFilters({ customer: undefined, category: undefined });
                            }}
                          >
                            Clear
                          </Button>
                          <Button id="filterResults" variant="contained" type="submit" size='small'>
                            Filter Results
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid>

                        <Button
                          size='small'
                          id="ticketListMap"
                          variant="contained"
                          onClick={handleToggleTicketListMap}
                          sx={{ mr: 5 }}
                          disabled={!ticketListTable.getRowModel().rows.length}
                        >
                          Map
                        </Button>

                        <>
                          <Button
                            size='small'
                            id="assignedToMe"
                            variant={ticketTableState.columnFilters.some(filter => filter.id === 'Assignee') ? 'outlined' : 'contained'}
                            onClick={handleToggleAssigneeFilter}
                          >
                            Assigned to Me
                          </Button>
                          <Badge
                            badgeContent={ticketTableState.columnFilters.some(filter => filter.id === 'Assignee') ? 'On' : 'Off'}
                            color="secondary"
                            sx={{ ml: 2, mb: 5, mr: 5 }}
                          />
                        </>

                        <Permissions target={'createTicket'} disableMode>
                          <Button
                            size='small'
                            id="createTicket"
                            variant='contained'
                            onMouseDown={() => {
                              console.log('open drawer');
                              navigate({
                                to: '/',
                                search: (prev: any) => ({
                                  ...prev,
                                  drawer: 'create'
                                })
                              })
                            }}
                          >
                            + Create Ticket
                          </Button>
                        </Permissions>

                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Card>
          </Grid>
        </Collapse>
        <Grid size={12} marginTop={!isDesktop ? 2 : 0} height={'100%'}>

          <SimpleTable table={ticketListTable} showDevTools />
        </Grid>
      </Grid>

      {ticketListState.ticketListMapOpen && <TicketListMap table={ticketListTable} />}
      <CreateTicketDrawer />
    </>
  );
};

export default TicketList;