import { createRouter, redirect, RouterProvider, useNavigate } from '@tanstack/react-router';
import { AppRouter } from './app/router';
import { routeTree } from './routeTree.gen';
import { queryClient } from '@/lib/queryClient';
import { Box, CircularProgress } from '@mui/material';
import { AuthLoader, AuthProvider, useAuth } from './lib/auth';
import { z } from 'zod';
import { motion } from 'framer-motion';
import { LoadingScreen } from '@/app/pages/Loading/Loading';
import GlobalStateProvider from './contexts/globalStateProvider';
import { useMemo } from 'react';
import { NotFound } from './app/pages/NotFound/404Page';

export type LegacyUrlMapping = {
  newPath: string
  transformParams: (search: Record<string, string>) => Record<string, string | number>
  transformSearch: (search: Record<string, string>) => Record<string, string | number>
}


export type RouterContext = {
  legacyUrlMappings: Record<string, LegacyUrlMapping>
}

export const legacyUrlMappings: Record<string, LegacyUrlMapping> = {
  'ticket.html': {
    newPath: '/ticket/$ticketId',
    transformParams: (search) => ({
      ticketId: z.number().int().parse(Number(search.TicketId))
    }),
    transformSearch: () => ({
      variant: 'edit',
      step: 0
    })
  }
}

// Legacy URL support util

export function handleLegacyUrl(
  pathname: string,
  search: Record<string, string>,
  context: RouterContext
) {
  const legacyPath = pathname.split('/').pop()
  if (!legacyPath) return null

  const mapping = context.legacyUrlMappings[legacyPath]
  if (!mapping) return null

  const params = mapping.transformParams(search)
  const searchParams = mapping.transformSearch(search)

  const newPath = Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`$${key}`, String(value)),
    mapping.newPath
  )

  return redirect({
    to: newPath,
    search: searchParams,
    replace: true
  })
}

const router = createRouter({
  routeTree: routeTree,
  Wrap: ({ children }) => {
    return (
      <GlobalStateProvider>
        {children}
      </GlobalStateProvider>
    )
  },
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
    handles: {},
    queryClient: queryClient,
    legacyUrlMappings: legacyUrlMappings
  },

  defaultPendingComponent: () => (
    <div>
      <LoadingScreen />
    </div>
  ),
  defaultErrorComponent: () => (
    <div>
      <NotFound />
    </div>
  ),
  // This make the loader only wait 200ms before showing the pending component, instead of the default 1000ms
  defaultPendingMs: 200,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const auth = useAuth()
  const context = useMemo(() => ({
    auth,
    handles: {},
    queryClient,
    legacyUrlMappings
  }), [auth])

  return <RouterProvider router={router} context={context} />
}


const App = () => {

  // const navigate = useNavigate()

  return (
    <GlobalStateProvider>
      <AuthLoader
        renderLoading={() => <div>Loading ...</div>}
        renderUnauthenticated={() => redirect({ to: '/login', replace: true })}
      >
        <AuthProvider>
          <InnerApp />
        </AuthProvider>
      </AuthLoader>
    </GlobalStateProvider>
  )
};

export default App;
