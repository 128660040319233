import { BASE_UAPI_URL } from "@/constants/commonStrings.constant";
import { AxiosRequestConfig } from "axios";
import { apiService } from "./api.service";
import createQueryParams from "@/util/queryParams";

export type GetAgentsParams = {
  customerid: number;
  ticketid: number
  limittocustomer: boolean
};

const AuthService = () => {


  const getPing = async (
    // params: GetAgentsParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const requestId = `getPing_${Date.now()}`;
      const response = await apiService({
        method: 'post',
        url: `${BASE_UAPI_URL}/ping`,
        ...config,
        id: requestId,
      });
      
      return response.data;
  };  

  const getAgentContext = async (
    params: GetAgentsParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const search = createQueryParams(params);

    const requestId = `getAgentContext_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: `${BASE_UAPI_URL}/agents` + search,
      ...config,
      id: requestId,
    });

    return response.data;
  };

  return {
    getPing,
    getAgentContext
  }
};

export default AuthService