import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';
import AttachmentService from '@/services/attachment.service';
import dayjs from 'dayjs';

type ActionType = 'attachmentDelete';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useAttachmentsDelete = (): {
  updateAttachmentDelete: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingAttachmentDelete: Record<string, boolean>;
  setIsUpdatingAttachmentDelete: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdatingAttachmentDelete, setIsUpdatingAttachmentDelete] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const attachmentService = AttachmentService();
    const action: { [key in ActionType]?: Function } = {
      attachmentDelete: attachmentService.deleteAttachmentByFid,
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateAttachmentDelete } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: async ({ actionType, fileid, ticketid }) => {
      // Optimistically update the cache
      queryClient.setQueryData(['attachments-by-id', ticketid], (old: any) => {
        const attachmentsArray = old ? Object.values(old) : [];

        const updatedAttachments = attachmentsArray.filter(
          (attachment: any) => attachment.Fid !== fileid
        );

        return updatedAttachments
      });

      // Set the "is updating" state
      setIsUpdatingAttachmentDelete((prevState) => ({
        ...prevState,
        [fileid]: true,
      }));
    },
    onSuccess: (data: any, variables: any) => {
      console.log('Attachment Delete onSuccess:', data, variables);
      // console.log('Attachment variables.data:', variables.data);
      // console.log('data.data.Success:', data.data.Success);
      if (data?.data?.Success === false) {
        enqueueSnackbar('Attachment delete failed', { variant: 'error' });
      } else if (variables.actionType === 'attachmentDelete' && data?.data?.includes(variables?.fileid)) {
        enqueueSnackbar(`Attachment deleted successfully`, { variant: 'success' })
      }

    },
    onError: (error: any, variables: any) => {
      console.error('Error during mutation:', error);
      // console.warn('ERROR', variables);
      if (variables.actionType === 'attachmentDelete') {
        enqueueSnackbar(`Attachment delete failed`, { variant: 'error' });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return { updateAttachmentDelete, isUpdatingAttachmentDelete, setIsUpdatingAttachmentDelete };
};

export default useAttachmentsDelete;
