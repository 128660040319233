import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Checkbox, Chip, Collapse, Container, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Paper, Select, Step, StepLabel, Stepper, styled, Switch, Tab, TextField, Theme, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AutoComplete, Box, Divider, Stack, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useTicket from '@/hooks/fetches/useTicket.service';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import WorkflowBuilder from '../NodeFlow/WorkflowBuilder';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

interface CustomStepIconProps {
    icon: React.ReactNode;
    active: boolean;
    completed: boolean;
}

export interface Condition {
    field: string;
    operator: 'equal' | 'not_equal';
    value: string;
    matchType: 'any' | 'all';
}

export interface ActionConfig {
    type: string;
    enabled: boolean;
    config: {
        assignee?: string;
        emailTo?: string[];
        emailSubject?: string;
        emailBody?: string;
        status?: string;
        taskTitle?: string;
        taskDescription?: string;
        notificationType?: string;
        notificationMessage?: string;
    };
}

export interface WorkflowRule {
    description: string;
    customer: string;
    matchType: 'any' | 'all';
    conditions: Condition[];
    actions: ActionConfig[];
}

const initialWorkflowRule: WorkflowRule = {
    description: '',
    customer: '',
    matchType: 'any',
    conditions: [],
    actions: [],
};

const BasicDrawer = () => {
    const navigate = useNavigate();
    const params = useParams({ from: '/_auth/workflow-rules/$workflowruleId' });
    const { variant, step } = useSearch({ from: '/_auth/workflow-rules/$workflowruleId' })
    const [activeStep, setActiveStep] = useState(0);
    const [workflowRule, setWorkflowRule] = useState<WorkflowRule>(initialWorkflowRule);
    const methods = useForm({
        mode: 'all'
    });

    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [value, setValue] = React.useState('1');

    const { customers } = useTicket()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    const step1Ref = useRef<any>(null);
    const step2Ref = useRef<any>(null);

    const theme = useTheme();

    const workflowruleId = params.workflowruleId

    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

    const [isOpen, setIsOpen] = useState(false)

    // Open drawer when component mounts
    useEffect(() => {
        setIsOpen(true)
    }, [])

    const handleClose = () => {
        setIsOpen(false)
        // Wait for animation to complete before navigating
        setTimeout(() => {
            navigate({ to: '/workflow-rules' })
        }, 300) // Match this with MUI's transition duration
    }

    const handleCloseDrawer = () => {
        handleClose()
        setActiveStep(0);
        setCompleted({});
        // methods.reset(editCustomerValues2);
        navigate({ to: '/workflow-rules' });
    };

    const handleSubmit: SubmitHandler<any> = (formValues: any) => {
        console.log('formValues : ', formValues);

        if (variant === 'edit') {
        } else {
        }
        return undefined
    }

    const steps = [
        {
            label: 'Step 1',
            children: <div>Hello Step 1</div>,
            completed: false,
        },
        {
            label: 'Step 2',
            children: <div>Hello Step 2</div>,
            completed: false,
        },
    ]

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        console.log(newActiveStep);
        setActiveStep(newActiveStep);
        if (activeStep === 1) {
            step1Ref.current?.submitForm();
            setCompleted({ ...completed, [activeStep]: true });
        }
        if (activeStep === 2) {
            step2Ref.current?.submitForm();
        }
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
        navigate({
            to: "/workflow-rules/$workflowruleId",
            params: { workflowruleId },

            search: (prev) => ({
                ...prev,
                variant: variant,
                step: step,
            })
        })
        if (activeStep === 1) {
            step1Ref.current?.submitForm();
        }
        if (activeStep === 2) {
            step2Ref.current?.submitForm();
        }
    };

    const handleComplete = () => {
        if (completedSteps() === totalSteps() - 1) {
            console.log('saved');
        } else {
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        navigate({ to: '/status-editor' });
    };

    const CustomStepIcon: React.FC<CustomStepIconProps> = ({ icon, active, completed }) => {
        return (
            <>
                {variant !== 'edit' ? (
                    completed ? (
                        <Box
                            id="completed button"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'green',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                // color: '#fff',
                            }}
                        >
                            <CheckCircleIcon sx={{ color: 'green' }} />
                        </Box>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                backgroundColor: active ? theme.palette.primary.light : completed ? '#4caf50' : '#e0e0e0',
                                color: '#fff',
                            }}
                        >
                            <Typography>{completed ? <CheckCircleIcon sx={{ color: 'white' }} /> : icon}</Typography>
                        </div>
                    )
                ) : undefined}
            </>
        );
    };

    const StepperNumberJSX = () => (
        <Box sx={{ pe: 8, display: 'flex', justifyContent: 'space-evenly' }}>
            <Stepper nonLinear activeStep={activeStep} connector={null} alternativeLabel sx={{ pe: 4, width: '50%', mb: 4 }}>
                {steps.map((step, index) => (
                    <Step key={step.label} completed={completed[index]} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <StepLabel StepIconComponent={CustomStepIcon} color="inherit" onClick={handleStep(index)}>
                            {step.label}
                            {activeStep === index ? (
                                <>
                                    <Divider
                                        sx={{
                                            width: '100%',
                                            height: '2px',
                                            mt: 2,
                                            // mb: 1,
                                            mx: 1,
                                            bgcolor: 'primary.main',
                                            color: 'primary.contrastText',
                                        }}
                                    />
                                </>
                            ) : null}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );

    const StepContent = steps[activeStep]?.children || 'Unknown Step';


    // Custom styled Tab component
    const CustomTab = styled(Tab)(({ theme }: any) => ({
        minHeight: '72px',
        padding: '12px',
        flexDirection: 'column',
        '& .MuiTab-labelIcon': {
            flexDirection: 'column',
        },
    }));

    // Custom number indicator component
    const TabNumber = styled(Typography)(({ theme }: any) => ({
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        fontSize: '0.875rem',
    }));

    const availableActions = [
        'Set Assignee',
        'Send Email',
        'Update Status',
        'Create Task',
        'Send Notification',
    ];

    const availableStatuses = [
        'New',
        'In Progress',
        'Pending',
        'Completed',
        'Cancelled',
    ];

    const availableNotificationTypes = [
        'Success',
        'Warning',
        'Error',
        'Info',
    ];

    const availableAssignees = [
        'John Smith',
        'Jane Doe',
        'Mike Johnson',
        'Sarah Williams',
    ];



    interface ActionConfigurationProps {
        action: ActionConfig;
        onChange: (updates: Partial<ActionConfig['config']>) => void;
    }

    const ActionConfiguration: React.FC<ActionConfigurationProps> = ({
        action,
        onChange,
    }) => {
        const [newEmail, setNewEmail] = React.useState('');

        const handleAddEmail = () => {
            if (newEmail && action.config.emailTo) {
                onChange({ emailTo: [...action.config.emailTo, newEmail] });
                setNewEmail('');
            }
        };

        const handleRemoveEmail = (email: string) => {
            if (action.config.emailTo) {
                onChange({ emailTo: action.config.emailTo.filter((e: any) => e !== email) });
            }
        };

        switch (action.type) {
            case 'Set Assignee':
                return (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Assignee</InputLabel>
                        <Select
                            value={action.config.assignee || ''}
                            label="Assignee"
                            onChange={(e: any) => onChange({ assignee: e.target.value })}
                        >
                            {availableAssignees.map((assignee) => (
                                <MenuItem key={assignee} value={assignee}>
                                    {assignee}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );

            case 'Send Email':
                return (
                    <Stack spacing={2}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Add Email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleAddEmail();
                                        }
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleAddEmail}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Stack>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {action.config.emailTo?.map((email: any) => (
                                    <Chip
                                        key={email}
                                        label={email}
                                        onDelete={() => handleRemoveEmail(email)}
                                        size="small"
                                    />
                                ))}
                            </Box>
                        </Box>
                        <TextField
                            fullWidth
                            label="Subject"
                            value={action.config.emailSubject || ''}
                            onChange={(e) => onChange({ emailSubject: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            label="Body"
                            multiline
                            rows={4}
                            value={action.config.emailBody || ''}
                            onChange={(e) => onChange({ emailBody: e.target.value })}
                        />
                    </Stack>
                );

            case 'Update Status':
                return (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={action.config.status || ''}
                            label="Status"
                            onChange={(e) => onChange({ status: e.target.value })}
                        >
                            {availableStatuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );

            case 'Create Task':
                return (
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            label="Task Title"
                            value={action.config.taskTitle || ''}
                            onChange={(e) => onChange({ taskTitle: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            label="Task Description"
                            multiline
                            rows={4}
                            value={action.config.taskDescription || ''}
                            onChange={(e) => onChange({ taskDescription: e.target.value })}
                        />
                    </Stack>
                );

            case 'Send Notification':
                return (
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel>Notification Type</InputLabel>
                            <Select
                                value={action.config.notificationType || ''}
                                label="Notification Type"
                                onChange={(e) => onChange({ notificationType: e.target.value })}
                            >
                                {availableNotificationTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Message"
                            multiline
                            rows={2}
                            value={action.config.notificationMessage || ''}
                            onChange={(e) => onChange({ notificationMessage: e.target.value })}
                        />
                    </Stack>
                );

            default:
                return null;
        }
    };

    const handleActionToggle = (actionType: string) => {
        setWorkflowRule((prev: any) => {
            const actionExists = prev.actions.find((a: any) => a.type === actionType);
            if (actionExists) {
                return {
                    ...prev,
                    actions: prev.actions.filter((a: any) => a.type !== actionType),
                };
            } else {
                return {
                    ...prev,
                    actions: [
                        ...prev.actions,
                        {
                            type: actionType,
                            enabled: true,
                            config: undefined,
                            // config: getInitialConfig(actionType),
                        },
                    ],
                };
            }
        });
    };

    return (
        <DrawerLayout
            open={isOpen}
            onClose={handleCloseDrawer}
            anchor="right"
            keepMounted
            width={'50%'}
            Header={
                <DrawerHeader
                    close={handleCloseDrawer}
                    title={variant === 'edit' ? 'Edit Workflow Rule' : 'New Workflow Rule'}
                />
            }
        >
            <Grid
                container
                style={{
                    height: '100%',
                    width: '100%',
                }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent={'space-between'}
                flexWrap="nowrap"
                padding={4}
                overflow="auto"
            >
                {/* <Container component='form'
                    sx={{ width: '100%' }}
                >
                    <Stack direction="column" sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <StepperNumberJSX />
                            <Grid container direction="row" spacing={8}>
                                <Container>
                                    {StepContent}
                                </Container>
                            </Grid> */}
                {/* <CustomerDrawerFooter
                                isEdit={variant === 'edit'}
                                activeStep={activeStep}
                                lastStep={1}
                                saveCallback={() => handleComplete()}
                                cancelCallback={handleReset}
                                entityType={'Customer'}
                                deleteCallback={handleDeleteCustomer}
                                isNextDisabled={false}
                            /> */}
                {/* </Box> */}
                {/* </Stack> */}
                {/* </Container> */}
                <Grid size={{ xs: 12 }} marginTop={!isDesktop ? 2 : 0} height={'100%'} sx={{ overflow: 'auto', paddingBottom: '90px' }}>
                    {/* <Grid size={12}>
                        <Container maxWidth="md" sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                            {(invoiceId) && <InvoiceSummary invoiceId={invoiceId} />}
                        </Container>
                    </Grid> */}
                    <TabContext value={value} >
                        <FormProvider {...methods}>
                            <Container maxWidth='md' component='form' onSubmit={methods.handleSubmit(handleSubmit)} >
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered sx={{ mt: 4, overflow: 'auto' }}>
                                        <Tab label="Select Customer" value="1" />
                                        <Tab label="Select Conditions" value="2" />
                                        <Tab label="Choose Actions" value="3" />
                                        <Tab label="Review" value="4" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 8, mb: 4 }}>
                                        <Typography variant="button" gutterBottom sx={{ mb: 4 }}>
                                            Select a Customer and Description
                                        </Typography>
                                        <Divider sx={{ mb: 6 }} />
                                        <TextBox
                                            id='description'
                                            name='description'
                                            label='Description'
                                            fullWidth
                                            sx={{ mb: 4 }}
                                        />
                                        <AutoComplete
                                            id="customer_filter"
                                            options={
                                                customers.isPending
                                                    ? []
                                                    : customers?.data?.map((customer: any) => ({
                                                        id: customer.CustomerEntId,
                                                        label: customer.EntName,
                                                    })) || []
                                            }
                                            label="Customer"
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box sx={{
                                        maxWidth: '100%', mx: 'auto', mt: 8, mb: 4
                                    }}>
                                        <Typography variant="h6" gutterBottom sx={{ mb: 4 }
                                        } >
                                            Select Conditions
                                        </Typography>
                                        <Divider sx={{ mb: 6 }} />
                                        <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Stack direction={'column'} sx={{ width: '45%' }} >
                                                <TextBox
                                                    id='description'
                                                    name='description'
                                                    label='Description'
                                                    fullWidth
                                                    sx={{ mb: 4 }}
                                                />
                                                <AutoComplete
                                                    id="customer_filter"
                                                    options={
                                                        customers.isPending
                                                            ? []
                                                            : customers?.data?.map((customer: any) => ({
                                                                id: customer.CustomerEntId,
                                                                label: customer.EntName,
                                                            })) || []
                                                    }
                                                    label="Customer"
                                                />
                                            </Stack>
                                            <Stack direction={'column'} sx={{ width: '45%' }}>
                                                <TextBox
                                                    id='description'
                                                    name='description'
                                                    label='Description'
                                                    fullWidth
                                                    sx={{ mb: 4 }}
                                                />
                                                <AutoComplete
                                                    id="customer_filter"
                                                    options={
                                                        customers.isPending
                                                            ? []
                                                            : customers?.data?.map((customer: any) => ({
                                                                id: customer.CustomerEntId,
                                                                label: customer.EntName,
                                                            })) || []
                                                    }
                                                    label="Customer"
                                                />
                                            </Stack>
                                        </Stack>

                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Typography variant="h6" gutterBottom>
                                        Choose Actions
                                    </Typography>
                                    <Divider sx={{ mb: 6 }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" color="text.primary" >
                                                Set Assignee:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <AutoComplete
                                                id="action_assignee"
                                                label={"Select Assignee"}
                                                placeholder='Select Assignee'
                                                required
                                                options={[
                                                    { label: 'Test 1', id: "test1" },
                                                    { label: 'Test 2', id: "test2" },
                                                    { label: 'Test 3', id: "test3" },
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Switch id='assignee_switch' color="primary" />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="4">
                                    <WorkflowBuilder />
                                </TabPanel>
                            </Container>
                        </FormProvider>
                    </TabContext>
                </Grid>
            </Grid >
        </DrawerLayout >
    );
};

export default BasicDrawer;