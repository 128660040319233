import React from 'react';
import { motion } from 'framer-motion';
import { Button, Container, Typography, Box, useTheme } from '@mui/material';
import { Home } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import DashboardLayout from '@/layouts/DashboardLayout';

export const NotFoundIllustration: React.FC = () => {
  const theme = useTheme()
  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{ width: '300px', height: '300px', marginBottom: '2rem' }}
    >
      <svg
        viewBox="0 0 500 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.circle
          cx="250"
          cy="250"
          r="200"
          stroke={theme.palette.primary.main}
          strokeWidth="20"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2, ease: "easeInOut" }}
        />
        <motion.path
          d="M150 150L350 350M350 150L150 350"
          stroke={theme.palette.primary.main}
          strokeWidth="20"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1.5, ease: "easeInOut", delay: 0.5 }}
        />
      </svg>
    </motion.div>
  );
};

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme()

  return (
    <DashboardLayout>

      <Container maxWidth="lg">
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            py: 8,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <NotFoundIllustration />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: { xs: '3rem', md: '4rem' },
                fontWeight: 700,
                mb: 2,
                background: `${theme.palette.primary.main}`,
                // background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
                backgroundClip: 'text',
                textFillColor: 'transparent',
              }}
            >
              404
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: 500,
              }}
            >
              Oops! Page not found
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: 4, maxWidth: '600px' }}
            >
              The page you're looking for doesn't exist or has been moved.
              Let's get you back on track!
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate({
                to: '/',
                search: { drawer: undefined }
              })}
              startIcon={<Home />}
              sx={{
                mt: 8
                // borderRadius: '28px',
                // padding: '12px 32px',
                // textTransform: 'none',
                // fontSize: '1.1rem',
                // background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
                // boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                // '&:hover': {
                //   background: `linear-gradient(45deg, #1976D2 30%, ${theme.palette.primary.dark} 90%)`,
                // },
              }}
            >
              Return Home
            </Button>
          </motion.div>
        </Box>
      </Container>
    </DashboardLayout>

  );
};