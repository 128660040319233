import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, LocalizationProviderProps, MuiPickersAdapter } from '@mui/x-date-pickers';
import React from 'react';

export type DayjsProviderProps<TDate> = Omit<LocalizationProviderProps<TDate, any>, 'dateAdapter'> & {
  dateAdapter?: new (...args: any) => MuiPickersAdapter<TDate>;
};

export default function DayjsProvider({ children, ...props }: DayjsProviderProps<Date>) {
  const { dateAdapter, ...localizationProps } = props;
  return (
    <LocalizationProvider dateAdapter={(dateAdapter || AdapterDayjs) as any} {...localizationProps}>
      {children}
    </LocalizationProvider>
  );
}

DayjsProvider.displayName = 'DayjsProvider';
