import { BASE_API_URL } from '@/constants/commonStrings.constant';
import { apiService } from './api.service';
import { AxiosRequestConfig } from 'axios';
import { env } from '@/config/env';
import createQueryParams from '@/util/queryParams';
import { z } from 'zod';

const getWorkflowRuleParams = z.object({
  srid: z.number().int().positive(),
})
export type GetWorkflowRuleParams = {
  /**
   * @type integer, int32
   */
  srid: number;
};

const getAgentsParams = z.object({
  customerid: z.number().int().positive(),
})
export type GetAgentsParams = {
  /**
   * @type integer, int32
   */
  customerid: number;
}

const getBaseFieldParams = z.object({
  ticket: z.number().min(-1).int(),
})
export type GetBaseFieldParams = {
  /**
   * @type integer, int32
   */
  ticket: number;
}

const getCustomFieldsParams = z.object({
  customerid: z.number().min(-1).int(),
  full: z.boolean(),
})
export type GetCustomFieldsParams = {
  /**
   * @type integer, int32
   */
  customerid: number;
  full: boolean,
}

const WorkflowRulesService = () => {
  const updateData = (url: string, data: any, onSuccess?: Function, onError?: Function) => {
    console.log('updateData', url, data);
    return apiService({
      method: 'post',
      url: url + '/smartrule',
      onSuccess,
      onError,
      data,
    });
  };

  const getAgents = async (
    params: GetAgentsParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const { customerid } = params;
    const validateRequestParams = getAgentsParams.parse(customerid);
    const search = createQueryParams(validateRequestParams);
    const requestId = `getAgents_${Date.now()}`;

    const response = await apiService({
      method: 'post',
      url: `${BASE_API_URL}${env.API_PREFIX}/api-smartrules-cfields.proc.json${search}`,
      ...config,
      id: requestId,
    });

    // Validate the response using the Zod schema
    // const validatedResponse = getApiSourceFileQueryResponseSchema.parse(response.data);
    return response.data;
  };

  const getWorkflowRule = async (
    params: GetWorkflowRuleParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const { srid } = params;
    const validateRequestParams = getWorkflowRuleParams.parse(srid);
    const search = createQueryParams(validateRequestParams);
    const requestId = `getWorkflowRule_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: `http://localhost:8000/www/api-smartrule.proc.json${search}`,
      ...config,
      id: requestId,
    });

    // Validate the response using the Zod schema
    // const validatedResponse = getApiSourceFileQueryResponseSchema.parse(response.data);
    return response.data;
  };


  const getAllWorkflowRules = async (
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    const requestId = `getAllWorkflowRules_${Date.now()}`;  

    const response = await apiService({
      method: 'get',
      url: `${BASE_API_URL}/www/uapi/smartrule`,
      ...config,
      id: requestId,
    });
    // Validate the response using the Zod schema
    // const validatedResponse = getApiSourceFileQueryResponseSchema.parse(response.data);
    return response.data;
  };

  const getBaseFields = async (
    params: GetBaseFieldParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const { ticket } = params;
    const validateRequestParams = getBaseFieldParams.parse(ticket);
    const search = createQueryParams(validateRequestParams);
    const requestId = `getBaseFieldParams_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: `${BASE_API_URL}/www/uapi/basefields`,
      ...config,
      id: requestId,
    });

    // Validate the response using the Zod schema
    // const validatedResponse = getApiSourceFileQueryResponseSchema.parse(response.data);
    return response.data;
  };

  const getCustomFields = async (
    params: GetCustomFieldsParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const validateRequestParams = getCustomFieldsParams.parse(params);
    const search = createQueryParams(validateRequestParams);
    const requestId = `getCustomFields_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: `${BASE_API_URL}/www/uapi/customfields${search}`,
      ...config,
      id: requestId,
    });

    // Validate the response using the Zod schema
    // const validatedResponse = getApiSourceFileQueryResponseSchema.parse(response.data);
    return response.data;
  };


  return {
    updateData,
    getAgents,
    getWorkflowRule,
    getBaseFields,
    getCustomFields,
    getAllWorkflowRules,
  };
};

export default WorkflowRulesService;
