import { queryOptions, useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import TemplateService from '@/services/template.service';
import { AxiosResponse } from 'axios';
import useTicketPrint from './useTicketPrint.service';

interface IUseTemplate {
  customFieldsByTemplateId: UseQueryResult<any>;
  templatesByCustomer: UseQueryResult<any>;
  mergeTemplatePermission: UseQueryResult<any>;
  downloadOrPrintTemplate: any;
  isDownloadingOrPrintingTicketTemplate: any;
  setIsDownloadingOrPrintingTicketTemplate: any;
}

const useTemplate = (templateId?: number, customerId?: number): IUseTemplate => {
  const { downloadOrPrintTemplate, isDownloadingOrPrintingTicketTemplate, setIsDownloadingOrPrintingTicketTemplate } = useTicketPrint();


  const templateService = TemplateService();

  const customFieldsByTemplateId = useQuery<any>({
    queryKey: ['custom-fields-by-template-id', templateId],
    queryFn: async () => {
      if (!templateId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await templateService
          .getCustomFieldsByTemplateId({
            templateId: templateId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!templateId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const templatesByCustomer = useQuery<any>({
    queryKey: ['templates-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await templateService
          .getTemplatesByCustomer({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  const mergeTemplatePermission = useQuery<any>({
    queryKey: ['merge-template-permission'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await templateService
          .getMergeTemplatePermissions({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  return {
    customFieldsByTemplateId,
    templatesByCustomer,
    mergeTemplatePermission,
    downloadOrPrintTemplate,
    isDownloadingOrPrintingTicketTemplate,
    setIsDownloadingOrPrintingTicketTemplate,
  };
};

export const createMergeTemplatePermissionsOptions = () => {
  const templateService = TemplateService()

  return queryOptions({
    queryKey: ['merge-template-permission'],
    queryFn: async () => {
      const response = await templateService.getMergeTemplatePermissions({})
      return response.data
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    retry: 1,
  })
}


export default useTemplate;
