import * as React from 'react'
import BasicDrawer from '@/components/Organisms/BasicDrawer/BasicDrawer.component'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import WorkflowRuleDrawer from '@/app/pages/WorkflowRulesDrawer/WorkflowRuleDrawer'

export const Route = createFileRoute('/_auth/workflow-rules/$workflowruleId')({
  component: () => <WorkflowRuleDrawer />,
  params: {
    parse: (params) => ({
      workflowruleId: z.number().int().parse(Number(params.workflowruleId)),
    }),
    stringify: ({ workflowruleId }) => ({ workflowruleId: `${workflowruleId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        step: z.number().min(0).max(3).default(0), // Based on your steps array length
        completed: z.record(z.string(), z.boolean()).default({}),
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
})