import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Box, Button, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import type { ErrorInfo } from 'react';
import React from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary as Boundary } from 'react-error-boundary';

interface EnhancedErrorBoundaryProps {
  children: React.ReactNode;
  fallbackRender?: (props: FallbackProps) => React.ReactNode;
  onReset?: () => void;
  onError?: (error: Error, info: ErrorInfo) => void;
  resetKeys?: Array<any>;
}

export const DefaultFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <Grid
    container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}
    // spacing={8}
  >
    <Container
      maxWidth="sm"
      style={{
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop: '100px',
      }}
    >
      <Box>
        <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 4 }}>
          Oops, Something Went Wrong
        </Typography>
        <Typography variant="body1" gutterBottom>
          We're sorry, but something went wrong. Please try again later.
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mb: 4 }}>
          Error: {error.message}
        </Typography>
        <Button id={'tryAgain'} variant="contained" color="primary" onClick={resetErrorBoundary}>
          Try Again
        </Button>
      </Box>
    </Container>
  </Grid>
);

const ErrorBoundary: React.FC<EnhancedErrorBoundaryProps> = ({
  children,
  fallbackRender,
  onReset,
  onError,
  resetKeys,
}) => {
  const [errorCount, setErrorCount] = React.useState(0);

  const handleError = React.useCallback(
    (error: Error, info: ErrorInfo) => {
      setErrorCount((prevCount) => prevCount + 1);
      if (onError) {
        onError(error, info);
      }
      // You could add additional error logging here
      console.error('Error caught by ErrorBoundary:', error, info);
    },
    [onError],
  );

  const handleReset = React.useCallback(() => {
    setErrorCount(0);
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const customFallbackRender = React.useCallback(
    (props: FallbackProps) => {
      if (fallbackRender) {
        return fallbackRender(props);
      }
      return <DefaultFallback {...props} />;
    },
    [fallbackRender],
  );

  return (
    <Boundary fallbackRender={customFallbackRender} onError={handleError} onReset={handleReset} resetKeys={resetKeys}>
      {errorCount > 3 ? (
        <div>
          <h2>Too many errors occurred</h2>
          <p>Please refresh the page or contact support.</p>
        </div>
      ) : (
        children
      )}
    </Boundary>
  );
};

export default ErrorBoundary;
