import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import DashboardLayout from '@/layouts/DashboardLayout';
import { Button, Card, Stack, Typography, useMediaQuery } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import type { Theme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from '@tanstack/react-router';
// import { navigationBarHeight } from '@/constants/commonSizes.constant';

const NotFound = () => {
  return (
    <DashboardLayout>
      <Grid
        container
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        rowGap={4}
        mt={8}
        sx={{ height: '100%', width: '100%' }}
      >
        <Grid xs={12} md={12} width={'100%'} marginTop={0} height={'100%'}>

          <Card sx={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack spacing={2} alignItems="center" sx={{ p: 3 }}>
              <Typography component="h1" variant="h2" align="center" gutterBottom sx={{ mt: 4 }}>
                404
              </Typography>
              <Typography component="h2" variant="h5" align="center" gutterBottom>
                Page not found
              </Typography>
              <Typography component="h2" variant="body1" align="center" gutterBottom>
                The page you are looking for might have been removed
              </Typography>

              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button id="return" variant="contained" color="primary" sx={{ marginTop: '1rem', mb: 4 }}>
                  Return to website
                </Button>
              </Link>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default NotFound;
